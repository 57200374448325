import { Divider as ChakraDivider, useTheme } from '@chakra-ui/react';
import { UnnamedWidget } from 'lib-node-amb-renderer';
import PropTypes from 'prop-types';

export class DividerClass extends UnnamedWidget {
  isExtraction;
  constructor(props) {
    super(props);
    this.isExtraction = props.isExtraction;
  }
}

export default function Divider({ sx, isExtraction }) {
  const { colors } = useTheme();

  const defaultStyles = {
    border: '2px solid',
    borderColor: sx?.borderColor ? sx?.borderColor : `${colors?.blue[600]}`,
    ...sx,
  };
  const extractionStyles = {
    borderTop: `1px solid ${colors?.gray[100]}`,
    padding: 0,
    borderBottomWidth: 'none',
    opacity: 1,
    ...sx,
  };

  return (
    <ChakraDivider
      orientation="horizontal"
      sx={isExtraction ? extractionStyles : defaultStyles}
    />
  );
}

Divider.propTypes = {
  sx: PropTypes.object,
  isExtraction: PropTypes.bool,
};
