import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { transformGetResponsePayload } from './utils';

export const QUERY_KEYS = {
  getAssetsInformationAssetSummary:
    apiEndPoints.GET_ASSETS_INFORMATION_FOR_ASSET_SUMMARY,
};

export function useGetAssetsSummaryInformationQuery(obfuscatedLoanIdentifier) {
  const toast = useToast();

  return useQuery({
    queryKey: [
      QUERY_KEYS.getAssetsInformationAssetSummary,
      obfuscatedLoanIdentifier,
    ],
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndPoints.GET_ASSETS_INFORMATION_FOR_ASSET_SUMMARY,
        obfuscatedLoanIdentifier,
      );

      return data;
    },
    select: (data) => transformGetResponsePayload(data),
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message || 'Could not get data',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
  });
}

export function useDeleteAssetInformationMutation(
  obfuscatedLoanIdentifier,
  { deleteMultipleAssets },
) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (list) => {
      const isSingleDelete = !Array.isArray(list);

      if (isSingleDelete) {
        list = [list];
      }

      const assetRecords = list.map((item) => ({
        assetDatabaseId: item.asset.assetLdId,
        assetRecordId: item.asset.assetId,
      }));

      await apiClient.post(apiEndPoints.BATCH_DELETE_ASSET, {
        obfuscatedLoanIdentifier,
        assetRecords,
      });

      if (!isSingleDelete) {
        deleteMultipleAssets();
      }

      queryClient.invalidateQueries(
        QUERY_KEYS.getAssetsInformationAssetSummary,
      );

      dispatch(setLoanSummaryUpsertOrDelete(true));

      toast({
        title: 'Success',
        description: 'Asset Deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: 'Delete Failed',
        description: 'Could not delete asset',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });
}

export function useUpdateAdditionalReservesMutation(
  obfuscatedLoanIdentifier,
  { onSuccess, onError },
) {
  return useMutation({
    mutationFn: async ({ additionalReserves, productType }) => {
      return apiClient.post(apiEndPoints.UPDATE_ADDITIONAL_RESERVES, {
        obfuscatedLoanIdentifier,
        additionalReserves,
        productType,
      });
    },
    onError,
    onSuccess,
  });
}

export function useSaveAssetSummaryMutation({ onSuccess, onError }) {
  return useMutation({
    mutationFn: async (payload) => {
      return apiClient.post(apiEndPoints.SAVE_ASSET_SUMMARY, payload);
    },
    onError,
    onSuccess,
  });
}
