import Select from 'common/components/Selects/Select';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import theme from 'theme/';

export const FormBuilderSelect = ({
  name,
  label,
  span,
  placeholder,
  isRequired = false,
  isInvalid,
  getValues,
  onChangeHandler,
  options,
  optionsAttributes,
  isOptionDisabled,
  disabled,
  tabIndex,
  dataTestId,
}) => (
  <FormControl
    style={{ columnSpan: `span ${span}` }}
    isInvalid={isInvalid}
    isRequired={isRequired}
  >
    <FormLabel>{label}</FormLabel>
    <Select
      style={{
        backgroundColor: theme.colors?.white,
      }}
      value={getValues(name)}
      placeholder={placeholder}
      onChange={(e) => {
        onChangeHandler(e.target.value);
      }}
      tabIndex={tabIndex}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {options.map((option, i) => (
        <option
          key={i}
          value={option[optionsAttributes.optionsValueName] ?? option.value}
          disabled={isOptionDisabled ? isOptionDisabled(option) : false}
        >
          {option.label ?? option[optionsAttributes.optionsLabelName]}
        </option>
      ))}
    </Select>
  </FormControl>
);

FormBuilderSelect.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  span: PropTypes.number,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
  optionsAttributes: PropTypes.shape({
    optionsLabelName: PropTypes.string,
    optionsValueName: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
};
