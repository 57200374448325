import Link from 'common/components/Link';
import Box from 'common/components/Box';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledClassifyFormContentContainer = styled(Box)`
  max-width: 386px;
  min-width: 344px;
  padding: 24px 24px 24px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledRemoveDocsLink = styled(Link)`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors?.red[600]};
  font-size: ${(props) => props.theme.functions?.pixelsToRem(14)};
  line-height: ${(props) => props.theme.functions?.pixelsToRem(24)};
  width: fit-content;
`;

export const StyledTrashIcon = styled(FontAwesomeIcon)`
  margin-left: 4px;
  color: ${(props) => props.theme.colors?.red[600]};
`;
