import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { USER_ROLE } from 'common/constants';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { formatCurrency } from 'common/util/format';
import { isReadOnlyUrl } from 'common/util/appState';
import CrudGrid from 'common/components/CrudGrid';
import ScrollIntoViewContainer from '../common/components/ScrollIntoViewContainer';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import {
  renderBoolean,
  concatenateObjectAttributes,
} from 'common/components/CrudGrid/crudGridUtils';
import LiabilitiesSummariesForm from './Form/LiabilitiesSummariesForm';
import { OPERATORS } from 'common/components/CrudGrid/Filters/filterGridData';
import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';
import {
  useDeleteLiabilitiesMutation,
  useGetLiabilitiesSummaryInformationQuery,
} from './hooks';

export class NewLiability {
  debtId = 0;
  debtLenderDatabaseId = 0;
  creditorName = '';
  customerIdentifiers = [];
  liabilitySummaryType = '';
  liabilityType = '';
  accountNumber = '';
  payment = '';
  excludePayment = false;
  exclusionReason = '';
  associatedRealEstate = '';
  payoffRequired = false;
  balance = '';
  lienPosition = '';
  constructor(id) {
    this.debtId = id;
  }
}

export default function Liabilities1003Layout() {
  // TODO refactor to useReducer
  const dispatch = useDispatch();
  const toast = useToast();
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);

  const { data, isLoading, refetch } =
    useGetLiabilitiesSummaryInformationQuery();

  const { mutate } = useDeleteLiabilitiesMutation(() => {
    setRowToEdit({});
    setShowForm(false);
    toast({
      title: 'Success',
      description: 'Liabilities Deleted',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    refetch();
    dispatch(setLoanSummaryUpsertOrDelete(true));
  });

  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !data.hasWritePermission) ||
      isReadOnlyUrl()
    );
  }, [isUnderwriter, boltStatus, role, data]);

  const totalBalanceAndPayment = useMemo(() => {
    return {
      payments: data.liabilities.reduce((sum, obj) => sum + obj.payment, 0),
      balance: data.liabilities.reduce((sum, obj) => sum + obj.balance, 0),
    };
  }, [data]);
  const columns = [
    {
      fieldName: 'creditorName',
      headerName: 'Creditor',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'liabilitySummaryType',
      headerName: 'Type',
      isNumeric: false,
      display: true,
      useOptions: true,
      renderCell: (val, options) => {
        if (val === null || val === '') return '-';
        return (
          options.availableLiabilityTypes?.find((value) => value.key === val)
            .value ?? '-'
        );
      },
    },
    {
      fieldName: 'accountNumber',
      headerName: 'Account Number',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'payment',
      headerName: 'Payment',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
    {
      fieldName: 'excludePayment',
      headerName: 'Exclude',
      isNumeric: false,
      display: true,
      renderCell: (val) => renderBoolean(val),
    },
    {
      fieldName: 'exclusionReason',
      headerName: 'Exclusion Reason',
      isNumeric: false,
      display: true,
      useOptions: true,
      renderCell: (val, options) => {
        if (val === null || val === '') return '-';
        return (
          options.availableExclusionReasons?.find(
            (reason) => reason.key === +val,
          ).value ?? '-'
        );
      },
    },
    {
      fieldName: 'payoffRequired',
      headerName: 'Payoff',
      isNumeric: false,
      display: true,
      renderCell: (val) => renderBoolean(val),
    },
    {
      fieldName: 'balance',
      headerName: 'Balance',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleEditMode = (row, isEditBool) => {
    setIsEdit(isEditBool);
    setRowToEdit(row);
    setShowForm(true);
  };

  const handleClose = () => {
    setRowToEdit({});
    setShowForm(false);
  };

  const handleSave = () => {
    refetch();
    setShowForm(false);
    setRowToEdit({});
  };

  const gridDef = {
    gridType: 'Liability',
    defaultGridMode: 'singleAction',
    defaultSort: { field: 'balance', direction: 'desc' },
    uniqueIdAttributeFields: ['liabilityIdentifier.debtId'],
    manageColumns: false,
    allowFilters: true,
    gridLanguage: {
      singularItem: 'Liability',
      pluralItem: 'Liabilities',
    },
    getDialogDeleteMessage: (rows) => {
      if (rows.length === 0) return '';
      if (rows.length > 1) {
        return (
          <div>
            <Text textAlign="start">
              Are you sure you want to remove these liabilities?
            </Text>
            <ul>
              {rows.map((row) => (
                <li key={row.accountNumber}>{`${row.creditorName}${
                  row.accountNumber ? ` - ${row.accountNumber}` : ''
                }`}</li>
              ))}
            </ul>
          </div>
        );
      } else {
        return `Are you sure you want to remove this liability: ${
          rows[0].creditorName
        }${rows[0].accountNumber ? ` - ${rows[0].accountNumber}` : ''}?`;
      }
    },
    getAriaLabel: (row) => row.creditorName,
    filters: [
      {
        options: data.availableBorrowers.map((borrower) => {
          return {
            value: borrower.customerRecordId,
            label: concatenateObjectAttributes(borrower, [
              'firstName',
              'lastName',
            ]),
          };
        }),
        label: 'Borrower(s)',
        inputType: 'multiSelect',
        fieldName: 'customerRecordId',
      },
    ],
  };

  const multiDelete = {
    buttonLabel: 'Delete Multiple Liabilities',
    subActions: [
      {
        label: 'Select All / Deselect All',
        id: 'selectAll',
        action: (allData, currentSelected) => {
          if (currentSelected.length === allData.length) {
            return [];
          }
          return allData;
        },
      },
      {
        label: 'Select All Zero Balance',
        id: 'selectAllZeroBalance',
        action: (allData) => {
          return allData.filter((data) => !data.balance);
        },
      },
    ],
  };

  const buttonDefs = [
    {
      label: 'Add Liability',
      action: () => {
        handleEditMode(new NewLiability());
      },
      visible: true,
      isDisable: false,
      testId: 'addLiabilityButton',
    },
  ];

  const filters = [
    {
      options: data.availableBorrowers.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      label: 'Borrower(s)',
      inputType: 'multiSelect',
      fieldName: 'customerIdentifiers',
      operator: OPERATORS.EXCLUDE,
      evaluateFilter: (row, value) =>
        row.customerIdentifiers.some((borrower) =>
          value.includes(borrower.borrowerRecordId),
        ),
    },
  ];

  return (
    <StyledLoan1003LayoutContainer>
      <CrudGrid
        gridDef={gridDef}
        columns={columns}
        data={data.liabilities}
        disableRowClick={isWriteAccessDisabled}
        dropdownOptions={data.dropdownOptions}
        onRowEditClick={(row) => {
          handleEditMode(row, true);
        }}
        filters={filters}
        readOnly={isWriteAccessDisabled}
        selectedRow={rowToEdit}
        isLoading={isLoading}
        onDelete={(row) => mutate(row)}
        showDeleteMultiple={showDeleteMultiple}
        setShowDeleteMultiple={() => setShowDeleteMultiple()}
        buttonDefs={buttonDefs}
        multiSelectActionDef={multiDelete}
        onGridModeChange={() => setShowForm(false)}
      >
        <Flex>
          <Text fontWeight={'bold'} padding={'0rem 1rem'}>
            Total Payment: {formatCurrency(totalBalanceAndPayment.payments)}
          </Text>
          <Text fontWeight={'bold'}>
            Total Balance: {formatCurrency(totalBalanceAndPayment.balance)}
          </Text>
        </Flex>
      </CrudGrid>

      {showForm && (
        <ScrollIntoViewContainer
          valueToWatch={rowToEdit}
          style={{ width: '100%' }}
        >
          <LiabilitiesSummariesForm
            rowToEdit={rowToEdit}
            isEdit={isEdit}
            onClose={handleClose}
            onSave={handleSave}
            isWriteAccessDisabled={isWriteAccessDisabled}
            handleToastMessage={(toastMessage) => toast(toastMessage)}
            allBorrowerOptions={data.availableBorrowers}
            dropdownOptions={data.dropdownOptions}
            obfuscatedLoanIdentifier={obfuscatedLoanIdentifier}
          />
        </ScrollIntoViewContainer>
      )}
    </StyledLoan1003LayoutContainer>
  );
}
