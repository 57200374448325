﻿import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'common/components/Grids/Grid';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { formatCurrency } from '../../../common/util/format';
import { AuditHistoryButton } from 'common/components/AuditHistoryButton';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';
import propTypes from 'common/propTypes';

const IncomeCategory = ({ employmentType, isPrimary, isCurrent }) => {
  let categoryText;

  if (!isCurrent) {
    categoryText = 'Employment - Previous';
  } else if (isPrimary) {
    categoryText = `${employmentType} - Primary`;
  } else if (!employmentType) {
    return null;
  } else {
    categoryText = employmentType;
  }

  return (
    <Text data-testid="wage-earner-text">
      Income Source: &nbsp;
      {categoryText}
    </Text>
  );
};

const IncomeSourceHeader = ({
  auditHistoryData,
  monthlyIncome,
  fullName,
  incomeCategory,
  isPrimary,
  startDate,
  endDate,
  isCurrent,
  isWriteAccessDisabled,
  showForm1003ButtonInIncomeHeader = true,
  ...rest
}) => {
  const displayEndDate = endDate !== '12/31/1969' ? endDate : 'N/A';

  return (
    <Grid
      templateColumns="1fr 1fr"
      fontSize="sm"
      fontWeight={500}
      opacity={isWriteAccessDisabled ? 0.6 : 1}
      {...rest}
    >
      <Box>
        <Box>
          <Text d="inline">
            Current Monthly Income in System: &nbsp;
            {formatCurrency(monthlyIncome)}
          </Text>
        </Box>
        <IncomeCategory
          employmentType={incomeCategory}
          isPrimary={isPrimary}
          isCurrent={isCurrent}
        />
      </Box>
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Box textAlign="right">
          <Text data-testid="fullname-text">{fullName}</Text>
          {isCurrent && startDate !== null && startDate !== '12/31/1969' && (
            <Text data-testid="start-date-text">
              Start Date:&nbsp; {startDate}
            </Text>
          )}
          {!isCurrent && (
            <Text data-testid="start-date-text">
              Dates Employed:&nbsp; {startDate} - {displayEndDate}
            </Text>
          )}
        </Box>
        {showForm1003ButtonInIncomeHeader && (
          <Form1003PopUpButton
            style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}
          />
        )}
        {auditHistoryData && (
          <Box ml="8px">
            <AuditHistoryButton {...auditHistoryData} />
          </Box>
        )}
      </Flex>
    </Grid>
  );
};
IncomeCategory.propTypes = {
  employmentType: PropTypes.string,
  isCurrent: PropTypes.bool.isRequired,
  isPrimary: PropTypes.bool.isRequired,
};
IncomeSourceHeader.propTypes = {
  auditHistoryData: PropTypes.oneOfType([
    propTypes.auditHistoryData,
    PropTypes.oneOf([false]),
  ]),
  monthlyIncome: PropTypes.number.isRequired,
  totalIncome: PropTypes.number,
  dti: PropTypes.number,
  fullName: PropTypes.string.isRequired,
  incomeCategory: PropTypes.string,
  isPrimary: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isCurrent: PropTypes.bool,
  isWriteAccessDisabled: PropTypes.bool,
  showForm1003ButtonInIncomeHeader: PropTypes.bool,
};

export default IncomeSourceHeader;
