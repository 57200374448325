import { useQuery, useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';
import { useToast } from '@chakra-ui/react';

export const useGetLiabilitiesSummaryInformationQuery = () => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const toast = useToast();
  return useQuery({
    queryKey: ['liabilities1003', { obfuscatedLoanIdentifier }],
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndPoints.underwritingSummariesLiability
          .GET_LIABILITIES_SUMMARY_DATA,
        {
          obfuscatedLoanIdentifier,
          dropdownOptionsNeeded: true,
        },
      );
      return data;
    },
    initialData: {
      liabilities: [],
      dropdownOptions: {},
      availableBorrowers: [],
      hasWritePermission: false,
    },
    onError: () => {
      toast({
        title: 'Error',
        description: 'Could not get data',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteLiabilitiesMutation = (onSuccess) => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const toast = useToast();

  return useMutation({
    mutationKey: ['deleteLiabilities', { obfuscatedLoanIdentifier }],
    mutationFn: async (rows) => {
      const liabilitiesToDelete = rows.map(({ liabilityIdentifier }) => ({
        ...liabilityIdentifier,
      }));

      await apiClient.post(
        apiEndPoints.underwritingSummariesLiability
          .DELETE_LIABILITIES_SUMMARY_DATA,
        {
          obfuscatedLoanIdentifier,
          liabilitiesToDelete,
        },
      );
    },
    onSuccess,
    onError: () => {
      toast({
        title: 'Delete Failed',
        description: 'Records were not able to be deleted.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
  });
};

export const useUpsertLiabilitiesMutation = (onSuccess) => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const toast = useToast();

  return useMutation({
    mutationKey: ['saveLiabilitiesSummary', { obfuscatedLoanIdentifier }],
    mutationFn: async (data) => {
      await apiClient.post(
        apiEndPoints.underwritingSummariesLiability
          .UPSERT_LIABILITIES_SUMMARY_DATA,
        data,
      );
    },
    onSuccess,
    onError: () => {
      toast({
        title: 'Save Failed',
        description: 'Record was not able to be saved.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
  });
};
