import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th } from 'common/components/Tables';
import Box from 'common/components/Box';
import styled from '@emotion/styled';

import AuditHistoryRow from './AuditHistoryRow';
import { orderHistoryByDate, createHistoryData } from './util';

const QuestionHeading = styled(Box)`
  background: ${(props) => props.theme.colors?.gray[50]};
  font-size: ${(props) => props.theme.functions?.pixelsToRem('16')};
  padding: 10px 15px;
  font-weight: 700;
`;

const QuestionWrapper = styled(Box)`
  max-width: 450px;
  overflow-x: hidden;
  margin: 0 auto 35px;
  box-shadow: ${(props) =>
    `0px 2px 4px 0px ${props.theme.colors?.bgGray[800]}`};
`;

const StyledTh = styled(Th)`
  font-weight: 700;
  font-size: ${(props) => props.theme.functions?.pixelsToRem(14)};
  width: fit-content;
  padding: 10px;
  text-align: center;
`;

let TABLE_HEADINGS = [];
const defaultTableHeadings = ['Response', 'Date', 'User'];
const creditInquiriesTableHeadings = [
  'Documentation Response',
  'Liability Response',
  'Date',
  'User',
];

const QuestionCard = ({ question, history }) => {
  const {
    selectedAlert: { displayTitle },
  } = useSelector(({ home }) => home);
  const isCreditInquiries = displayTitle === 'Credit Inquiries';
  const historyByDate = orderHistoryByDate(history);
  const historyData = createHistoryData(historyByDate);
  if (isCreditInquiries) {
    TABLE_HEADINGS = [...creditInquiriesTableHeadings];
  } else {
    TABLE_HEADINGS = [...defaultTableHeadings];
  }

  return (
    <QuestionWrapper>
      <QuestionHeading>{question}</QuestionHeading>
      <Table variant={'striped'} colorScheme={'gray'}>
        <Thead>
          <Tr>
            {TABLE_HEADINGS.map((heading, index) => (
              <StyledTh key={`table-heading-${index}`} scope="col">
                {heading}
              </StyledTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {historyData.map((historyRow, index) => (
            <AuditHistoryRow
              key={`${question}-history-${index}`}
              {...historyRow}
            />
          ))}
        </Tbody>
      </Table>
    </QuestionWrapper>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.string.isRequired,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      modifiedDateTime: PropTypes.string,
      userName: PropTypes.string,
      questionText: PropTypes.string,
    }),
  ).isRequired,
};

export default QuestionCard;
