export const AuditHistoryTypes = {
  BorrowerCreditInquiry: 1,
  BorrowerDriverLicense: 2,
  LiabilityLiabilities: 3,
  LiabilityMortgageHistory: 4,
  LiabilityRealEstateOwned: 5,
  BorrowerResidencyStatus: 6,
  BorrowerFraudAlerts: 7,
  BorrowerDerogatoryCreditEvents: 8,
  BorrowerActiveDuty: 9,
  BorrowerFirstTimeHomebuyer: 10,
  EmploymentReview: 11,
  IncomeSocialSecurity: 12,
  EsignCreditInquiry: 13,
};
Object.freeze(AuditHistoryTypes);

export const OCR_CREATED_DATETIME_QUESTION_ID = 11;
const PERMANENT_RESIDENT_QUESTION_ID = 24;
const NON_PERMANENT_RESIENT_QUESTION_ID = 25;

export const RESIDENCY_STATUS = {
  NAME: 'ResidencyStatus',
  QUESTION_IDS: [
    PERMANENT_RESIDENT_QUESTION_ID,
    NON_PERMANENT_RESIENT_QUESTION_ID,
  ],
};
Object.freeze(RESIDENCY_STATUS);

export const DRIVERS_LICENSE = {
  QUESTION_IDS: [OCR_CREATED_DATETIME_QUESTION_ID],
};

export const RULE_REFERENCE_IDENTIFIER_PROP_NAMES = {
  RULE_REFERENCE_IDENTIFIERS: 'ruleReferenceIdentifiers',
  RULE_REFERENCE_VALUE: 'ruleReferenceValue',
  QUESTIONS: 'questions',
  QUESTION_ID: 'questionId',
};

export const EMPLOYMENT_REVIEW = 'Employment Review';

// to be used with the default AuditHistory component
export const AuditHistoryPageEnum = Object.freeze({
  UNKNOWN: 0,
  PROPERTY_TITLE_COMMITMENT: 1,
  PROPERTY_CLOSING_PROTECTION_LETTER: 2,
  PROPERTY_WIRE_INSTRUCTIONS: 3,
  PROPERTY_CLOSING_PROTECTION_LETTER_E_AND_O: 4,
  ASSET_EMD: 5,
  NET_TANGIBLE_BENEFITS: 7,
  HOMEOWNERS_INSURANCE: 8,
});

export const AuditHistoryEnityEnum = Object.freeze({
  UNKNOWN: 0,
  ASSET_EMD: 5,
});

export const PageTitleMap = Object.freeze({
  [AuditHistoryPageEnum.UNKNOWN]: 'Unknown',
  [AuditHistoryPageEnum.PROPERTY_TITLE_COMMITMENT]: 'Title Commitment',
  [AuditHistoryPageEnum.PROPERTY_CLOSING_PROTECTION_LETTER]:
    'Closing Protection Letter',
  [AuditHistoryPageEnum.PROPERTY_WIRE_INSTRUCTIONS]: 'Wire Instructions',
  [AuditHistoryPageEnum.PROPERTY_CLOSING_PROTECTION_LETTER_E_AND_O]:
    'Closing Protection Letter (E & O)',
  [AuditHistoryPageEnum.ASSET_EMD]: 'EMD',
  ASSETS: 'Assets',
  [AuditHistoryPageEnum.NET_TANGIBLE_BENEFITS]: 'Net Tangible Benefits',
  [AuditHistoryPageEnum.HOMEOWNERS_INSURANCE]: 'Homeowners Insurance',
});

// to be used for 1003 summary pages AuditHistory component to match the backend pageEnum
export const CategoryEnum = Object.freeze({
  ASSETS: 6,
  // These will change to the correct values once the backend is updated
  REO: 7,
  INCOME: 8,
  LIABILITIES: 9,
  BORROWERS: 10,
});

// Get the category number from the category name for the 1003 summary pages
export function getCategoryNumber(category) {
  return CategoryEnum[category?.toUpperCase()] || null;
}
