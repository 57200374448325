import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import NumberInput from 'common/components/Inputs/NumberInput';
import NumberInputField from 'common/components/Inputs/NumberInputField';
import InputGroup from 'common/components/Inputs/InputGroup';
import InputLeftElement from 'common/components/Inputs/InputLeftElement';
import FormControl from 'common/components/Forms/FormControl';
import FormErrorMessage from 'common/components/Forms/FormErrorMessage';

// TODO: switch over to IMask
const formatFloatingPointNumber = (value, allowNegative) => {
  const regex = allowNegative ? /[\d.-]+/g : /[\d.]+/g;
  const parseNumber = (string) => (string?.match(regex) || []).join('');
  const valueAsNumber = parseNumber(value?.toString());
  if (allowNegative && valueAsNumber === '-') return '-';

  const [dollars, cents] = valueAsNumber.split('.');
  // Avoid rounding at toLocaleString
  const scaledTail = cents && cents.slice(0, 2);
  const number = Number.parseFloat(`${dollars}.${scaledTail}`);

  if (Number.isNaN(number)) return '';

  const valueAsCurrency = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  if (valueAsNumber.includes('.')) {
    const [formattedHead] = valueAsCurrency.split('.');
    return `${formattedHead}.${scaledTail}`;
  }
  return valueAsCurrency;
};

const CurrencyInput = ({
  id,
  name,
  value = '',
  placeholder,
  width,
  isReadOnly = false,
  isInvalid = false,
  isDisabled = false,
  allowNegative = true,
  withFormControl = true,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  errors,
  onBlur,
  onClick,
  onFocus,
  handleCurrencyChange,
  ...props
}) => {
  const [valueHook, setValueHook] = useState('');

  useEffect(() => {
    const formattedValue = formatFloatingPointNumber(value, allowNegative);
    setValueHook(formattedValue);
  }, [value, allowNegative]);

  const inputGroup = (
    <NumberInput
      id={id}
      aria-label={id}
      name={name}
      value={valueHook}
      width={width ? width : '100%'}
      inputMode="decimal"
      isReadOnly={isReadOnly}
      backgroundColor={isDisabled ? 'gray.100' : 'white'}
      isDisabled={isDisabled}
      min={min}
      max={max}
      precision={2}
      allowMouseWheel={false}
      isInvalid={isInvalid}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={(value) => {
        value = formatFloatingPointNumber(value, allowNegative);
        setValueHook(value);
        handleCurrencyChange?.(name, value);
      }}
    >
      <NumberInputField
        type="text"
        pl="48px"
        placeholder={placeholder}
        {...props}
      />
    </NumberInput>
  );

  return (
    <>
      {withFormControl ? (
        <FormControl isInvalid={errors} width={width ? width : '100%'}>
          <InputGroup>
            <InputLeftElement
              isInvalid={errors}
              pointerEvents="none"
              backgroundColor="gray.100"
              borderLeftRadius="0.375rem"
              fontWeight="400"
              top="1px"
              left="1px"
              height="95%"
              fontSize="16px"
              // eslint-disable-next-line react/no-children-prop
              children="$"
            />
            {inputGroup}
          </InputGroup>
          {typeof errors === 'object' &&
            Object.keys(errors).map((prop, index) => {
              return (
                <FormErrorMessage key={index}>{errors[prop]}</FormErrorMessage>
              );
            })}
        </FormControl>
      ) : (
        inputGroup
      )}
    </>
  );
};

CurrencyInput.propTypes = {
  /** The input id. */
  id: PropTypes.string,
  /** The input name. */
  name: PropTypes.string,
  /** The input value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder value of the input. */
  placeholder: PropTypes.string,
  /** Input group width. */
  width: PropTypes.string,
  /** If true, the input will be in read-only mode. */
  isReadOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** If true, the input will be disabled. */
  isDisabled: PropTypes.bool,
  /** If true, the input will be invalid. */
  isInvalid: PropTypes.bool,
  /** If true, the input will allow negative values. */
  allowNegative: PropTypes.bool,
  /** If true, the input will be come with form control and left element. */
  withFormControl: PropTypes.bool,
  /** The minimum value for the input. */
  min: PropTypes.number,
  /** The maximum value for the input. */
  max: PropTypes.number,
  /** The error to display. */
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** On blur event handler. */
  onBlur: PropTypes.func,
  /** On click event handler. */
  onClick: PropTypes.func,
  /** On focus event handler. */
  onFocus: PropTypes.func,
  /** On change handler, receives input name and value. */
  handleCurrencyChange: PropTypes.func,
};
export default CurrencyInput;
