import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormControl from 'common/components/Forms/FormControl';
import { Controller } from 'react-hook-form';
import { sortBy } from 'lodash';
import MultiSelect from 'common/components/Selects/MultiSelect/MultiSelect';

// Local
import { CLASSIFICATION, DROPDOWN_FIELD } from './constants';
import { FORM_MESSAGES } from 'common/constants/index';

const ClassifyDynamicFormContent = ({ rhfProps }) => {
  const { clearErrors, control, errors, setValue, watch } = rhfProps;

  /**
   * -------------------- *
   * Local & global state *
   * -------------------- *
   */

  const [currentDocumentTypeAndCategory, setCurrentDocumentTypeAndCategory] =
    useState(null);

  // redux
  const {
    selectedDocument,
    selectedDocumentAssociations,
    documentTypesAndCategories: { data: documentTypesAndCategories },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  // helpers
  const getDropDownOptions = (options) => {
    return options?.map((option) => ({
      label: option.associationDetails,
      value: option,
    }));
  };

  /**
   * ------- *
   * Effects *
   * ------- *
   */

  // watch for documentType change
  const currentDocumentType = watch(CLASSIFICATION.docType);
  // get the correct documentTypesAndCategories based on documentType
  useEffect(() => {
    if (currentDocumentType?.value && selectedDocument) {
      const current = documentTypesAndCategories?.find((documentType) => {
        return documentType.documentTypeId === +currentDocumentType.value;
      });
      setCurrentDocumentTypeAndCategory(current);
      /**
       * DO NOT remove this: clearErrors every time the
       * dynamic dropdown changes based on documentType
       */
      clearErrors();
    } else {
      setCurrentDocumentTypeAndCategory([]);
    }
  }, [
    clearErrors,
    documentTypesAndCategories,
    selectedDocument,
    /**
     * DO NOT remove this: currentDocumentType decides the currentDocumentTypeAndCategory
     * which populates dynamic dropdowns and options
     */
    currentDocumentType,
  ]);

  const getDefaultValues = useCallback(
    (dropDownName, previouslySelectedOptions, dropDownFields) => {
      const sortedPreviouslySelectedOptions = sortBy(
        previouslySelectedOptions,
        DROPDOWN_FIELD.associationDetails,
      );
      const allCurrentDropDownOptions = sortBy(
        dropDownFields?.find((field) => field.dropDownName === dropDownName)
          ?.options,
        DROPDOWN_FIELD.associationDetails,
      );
      let selectedOptions = [];
      if (allCurrentDropDownOptions?.length) {
        let selectedOptionsIndex = 0;
        let allCurrentDropDownOptionsIndex = 0;
        while (
          selectedOptionsIndex < sortedPreviouslySelectedOptions?.length &&
          allCurrentDropDownOptionsIndex < allCurrentDropDownOptions.length
        ) {
          if (
            allCurrentDropDownOptions[allCurrentDropDownOptionsIndex]
              .associationId ===
              sortedPreviouslySelectedOptions[selectedOptionsIndex]
                .associationId &&
            allCurrentDropDownOptions[allCurrentDropDownOptionsIndex]
              .associationDetails ===
              sortedPreviouslySelectedOptions[selectedOptionsIndex]
                .associationDetails
          ) {
            selectedOptions = [
              ...selectedOptions,
              allCurrentDropDownOptions[allCurrentDropDownOptionsIndex],
            ];
            selectedOptionsIndex += 1;
            allCurrentDropDownOptionsIndex += 1;
          } else {
            allCurrentDropDownOptionsIndex += 1;
          }
        }
      }
      return getDropDownOptions(selectedOptions);
    },
    [],
  );

  // when getDocumentAssociations is resolved, set already saved values if any
  useEffect(() => {
    if (selectedDocumentAssociations) {
      for (const [dropDownName, previouslySelectedOptions] of Object.entries(
        selectedDocumentAssociations,
      )) {
        const values = getDefaultValues(
          dropDownName,
          previouslySelectedOptions,
          currentDocumentTypeAndCategory?.dropDownFields,
        );
        setValue(dropDownName, values?.length ? values : null);
      }
    }
  }, [
    setValue,
    selectedDocumentAssociations,
    currentDocumentTypeAndCategory?.dropDownFields,
    getDefaultValues,
  ]);

  // When documentType is changed clear doc associations
  // When isDefaultNA populate NA
  useEffect(() => {
    if (
      currentDocumentType &&
      currentDocumentTypeAndCategory?.dropDownFields &&
      selectedDocument?.documentTypeName !== currentDocumentType?.label
    ) {
      for (let dropDown of currentDocumentTypeAndCategory.dropDownFields) {
        if (dropDown.isDefaultNa) {
          const options = getDropDownOptions(
            dropDown.options.filter((option) => option.associationId === 0),
          );
          setValue(dropDown.dropDownName, options?.length ? options : null);
        } else {
          setValue(dropDown.dropDownName, null);
        }
      }
    }
  }, [
    selectedDocument,
    currentDocumentType,
    currentDocumentTypeAndCategory,
    setValue,
  ]);

  /**
   * -------------- *
   * Event handlers *
   * -------------- *
   */

  const handleChange = (
    isMulti,
    dropDownName,
    options,
    currentSelection = null,
  ) => {
    if (!Array.isArray(options)) {
      options = [];
    }
    if (!isMulti) {
      currentSelection = options[0];
      return setValue(dropDownName, [currentSelection]);
    }
    // if NA selected, clear remaining selections
    if (currentSelection?.value?.associationId === 0) {
      setValue(dropDownName, [currentSelection]);
      // if any option other than NA is selected, clear NA from list
    } else {
      const remaining = options?.filter(
        (option) => option.value.associationId !== 0,
      );
      setValue(dropDownName, remaining.length ? remaining : null);
    }
    if (options.length) {
      clearErrors([dropDownName]);
    }
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */

  return (
    <>
      {currentDocumentTypeAndCategory?.dropDownFields?.map(
        ({ dropDownName, options, isMulti }, index) => {
          return (
            <FormControl
              isInvalid={errors[dropDownName]}
              key={`${index}-${dropDownName}`}
              sx={{ mb: 4 }}
              id={`${dropDownName}-${index}`}
            >
              <Controller
                name={dropDownName}
                control={control}
                rules={{ required: FORM_MESSAGES.requiredField }}
                defaultValue={isMulti ? [] : null}
                render={({ value }) => {
                  // If isMulti is false, ensure value is a single object, not an array
                  const processedValue = isMulti
                    ? value || []
                    : (Array.isArray(value) ? value[0] : value) || null;

                  return (
                    <MultiSelect
                      isMultiple={isMulti}
                      id={dropDownName}
                      label={dropDownName}
                      isLabelMultiple={true}
                      errors={errors}
                      clearErrors={clearErrors}
                      options={getDropDownOptions(options)}
                      getOptionLabel={(option) => option.label || ''}
                      data-tracking={`documentAssociation-${
                        index + 1
                      }--${dropDownName}`}
                      data-testid={`documentAssociation-${
                        index + 1
                      }--${dropDownName}`}
                      disableClearable
                      isOptionEqualToValue={(option, value) => {
                        if (isMulti) {
                          return (
                            option?.value?.associationDetails ===
                            value?.value?.associationDetails
                          );
                        } else {
                          return (
                            option?.value?.associationDetails ===
                            (Array.isArray(value)
                              ? value[0]?.value?.associationDetails
                              : value?.value?.associationDetails)
                          );
                        }
                      }}
                      onChange={(event, value) => {
                        if (isMulti) {
                          if (value.length === 0) {
                            handleChange(isMulti, dropDownName, [], null);
                            return;
                          }

                          const currentSelection = value[value?.length - 1];

                          if (!currentSelection?.label) {
                            return;
                          }

                          const newObject = {
                            label: currentSelection?.label,
                            value: currentSelection.value,
                          };

                          const updatedArray = [
                            ...value.slice(0, -1),
                            newObject,
                          ];

                          handleChange(
                            isMulti,
                            dropDownName,
                            updatedArray,
                            currentSelection,
                          );
                        } else {
                          if (!value || !value.label) {
                            handleChange(isMulti, dropDownName, null);
                            return;
                          }

                          const newArrayObject = [
                            {
                              label: value.label,
                              value: value.value,
                            },
                          ];
                          handleChange(isMulti, dropDownName, newArrayObject);
                        }
                      }}
                      value={processedValue}
                      filterSelectedOptions
                    />
                  );
                }}
              />
            </FormControl>
          );
        },
      )}
    </>
  );
};

ClassifyDynamicFormContent.propTypes = {
  rhfProps: PropTypes.object.isRequired,
};

export default ClassifyDynamicFormContent;
