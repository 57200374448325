import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import { RULE_RESULT_STATUS_ID, ALERT_TYPES } from 'common/constants';

const StyledCheckIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'size',
})((props) => ({
  color: props.theme.colors?.green[600],
  fontSize:
    props.size === 'small'
      ? props.theme.functions?.pixelsToRem(16)
      : props.theme.functions?.pixelsToRem(20),
}));

const StyledCircleIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'size',
})((props) => ({
  color: props.required
    ? props.theme.colors?.red[600]
    : props.theme.colors?.orange[300],
  fontSize:
    props.size === 'small'
      ? props.theme.functions?.pixelsToRem(16)
      : props.theme.functions?.pixelsToRem(20),
}));

const StyledNotification = styled('span', {
  shouldForwardProp: (prop) => prop !== 'size',
})((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  borderRadius: '50%',
  lineHeight: '150%',
  minHeight: props.size === 'small' ? '16px' : '20px',
  minWidth: props.size === 'small' ? '16px' : '20px',
  color: props.required ? props.theme.colors?.white : props.theme.colors?.black,
  backgroundColor: props.required
    ? props.theme.colors?.red[600]
    : props.theme.colors?.orange[300],
  fontSize:
    props.size === 'small'
      ? props.theme.functions?.pixelsToRem(10)
      : props.theme.functions?.pixelsToRem(12),
  border: `1px solid
      ${
        props.required
          ? props.theme.colors?.red[600]
          : props.theme.colors?.orange[300]
      }`,
}));

const StatusIcon = ({ type, status, numberOfChildren, required, size }) => {
  switch (true) {
    case status === RULE_RESULT_STATUS_ID.VERIFIED:
      return <StyledCheckIcon size={size} icon={faShieldCheck} />;
    case status === RULE_RESULT_STATUS_ID.CLEARED:
      return <StyledCheckIcon size={size} icon={faCheckCircle} />;
    case type === ALERT_TYPES.PARENT:
      return (
        <StyledNotification size={size} required={required}>
          {numberOfChildren}
        </StyledNotification>
      );
    case type === ALERT_TYPES.CHILD:
      return (
        <StyledCircleIcon icon={faCircle} required={required} size={size} />
      );
    default:
      return null;
  }
};

StatusIcon.propTypes = {
  type: PropTypes.oneOf([
    ALERT_TYPES.PARENT,
    ALERT_TYPES.CHILD,
    ALERT_TYPES.COMPLETED,
    ALERT_TYPES.VERIFIED,
  ]),
  status: PropTypes.oneOf([
    RULE_RESULT_STATUS_ID.FIRED,
    RULE_RESULT_STATUS_ID.CLEARED,
    RULE_RESULT_STATUS_ID.DID_NOT_FIRE,
    RULE_RESULT_STATUS_ID.VERIFIED,
  ]),
  numberOfChildren: PropTypes.number,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small']),
};

StatusIcon.defaultProps = {
  required: false,
  size: 'small',
};

export default StatusIcon;
