export function transformGetResponsePayload(data) {
  const {
    assets,
    excludedAssets,
    borrowers,
    assetsSummaryInformation,
    loanProductType,
    displayAuditHistory,
    hasWritePermission,
  } = data;

  const filteredBorrowers = transformBorrowers(borrowers.borrowers);

  return {
    excludedAssets: mapExcludedAssets(excludedAssets, filteredBorrowers),
    transformedAssets: transformAssets(assets),
    filteredBorrowers,
    assetsSummaryInformation,
    loanProductType,
    displayAuditHistory,
    hasWritePermission: hasWritePermission ?? false,
  };
}

function transformBorrowers(borrowers) {
  const uniqueBorrowers = new Map();

  borrowers.forEach((borrower) => {
    uniqueBorrowers.set(borrower.customerRecordId, borrower);
  });

  return Array.from(uniqueBorrowers.values());
}

function transformAssets(assets) {
  return assets?.map((asset) => ({ ...asset, ...asset.asset }));
}

function mapExcludedAssets(excludedAssetsArray, borrowersArray) {
  const borrowersLookup = borrowersArray.reduce((lookup, borrower) => {
    lookup[borrower.customerRecordId] = borrower;
    return lookup;
  }, {});

  return excludedAssetsArray?.map((excludedAsset) => ({
    ...excludedAsset,
    borrowers: borrowersLookup[excludedAsset.customerRecordId]
      ? [borrowersLookup[excludedAsset.customerRecordId]]
      : [],
  }));
}
