import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { concatenateObjectAttributes } from 'common/components/CrudGrid/crudGridUtils';
import { reverseFormatCurrency } from 'common/util/format';
import Loan1003FormContainer from 'pages/Loan1003DataGrids/common/components/Loan1003FormContainer';
import Loan1003FormSection from 'pages/Loan1003DataGrids/common/components/Loan1003FormSection';
import FormBuilder from 'pages/Loan1003DataGrids/common/components/FormBuilder';
import {
  LIABILITY_TYPE_CODES,
  EXCLUSION_REASON_CODES,
} from 'pages/Liabilities/constants';
import language from './../language.json';
import Loan1003FormBuilderWrapper from 'pages/Loan1003DataGrids/common/components/Loan1003FormBuilderWrapper';
import { useUpsertLiabilitiesMutation } from './../hooks';

const sanitizeInterestRate = (value) => {
  let sanitizedValue = value.replace(/[^0-9.]/g, '');
  let parts = sanitizedValue.split('.');

  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 3);
    sanitizedValue = parts.join('.');
  }

  return sanitizedValue;
};

const getDefaultValues = (rowToEdit) => {
  return {
    customerIdentifiers: rowToEdit.customerIdentifiers?.map(
      (identifiers) => identifiers.borrowerRecordId,
    ),
    liabilitySummaryType: rowToEdit.liabilitySummaryType,
    creditorName: rowToEdit.creditorName,
    accountNumber: rowToEdit.accountNumber,
    remainingTerm: rowToEdit.remainingTerm,
    payment: rowToEdit.payment ?? +rowToEdit.payment,
    balance: rowToEdit.balance ?? +rowToEdit.balance,
    payoffRequired: rowToEdit.payoffRequired,
    payoffAmount: rowToEdit.payoffAmount,
    excludePayment: rowToEdit.excludePayment,
    associatedRealEstate: rowToEdit.addressIdentifier
      ? rowToEdit.addressIdentifier.addressId
      : '',
    exclusionReason: rowToEdit.exclusionReason ?? '',
    lienPosition: rowToEdit.lienPosition,
    ownerOfMortgage: rowToEdit.ownerOfMortgage,
    isCurrentlyServicedByInstitution:
      rowToEdit.isCurrentlyServicedByInstitution,
    isCurrentlyFHAInsured: rowToEdit.isCurrentlyFHAInsured,
    creditReportOpenedDate: rowToEdit.creditReportOpenedDate,
    originalMortgageType: rowToEdit.originalMortgageType,
    originalMortgageDesc: rowToEdit.originalMortgageDesc,
    originalMortgageLoanPurpose: rowToEdit.originalMortgageLoanPurpose,
    originalSalesPrice: rowToEdit.originalSalesPrice,
    mostRecentAppraisedValue: rowToEdit.mostRecentAppraisedValue,
    highestBalance: rowToEdit.highestBalance,
    originalTerm: rowToEdit.originalTerm,
    originalTermRemaining: rowToEdit.originalTermRemaining,
    originalFirstPaymentDate: rowToEdit.originalFirstPaymentDate,
    originalInterestRate: rowToEdit.originalInterestRate,
    originalApr: rowToEdit.originalApr,
    originalMonthlyMiAmount: rowToEdit.originalMonthlyMiAmount,
    originalSectionAct: rowToEdit.originalSectionAct,
    originalNextAdjustment: rowToEdit.originalNextAdjustment,
    numberOfPaymentsMade: rowToEdit.numberOfPaymentsMade,
  };
};

export default function LiabilitiesSummariesForm({
  rowToEdit,
  onClose,
  onSave,
  allBorrowerOptions,
  isWriteAccessDisabled,
  dropdownOptions,
  handleToastMessage,
  obfuscatedLoanIdentifier,
}) {
  const [showErrors, setShowErrors] = useState(false);
  const {
    register,
    trigger,
    reset,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: getDefaultValues(rowToEdit),
    mode: 'onBlur',
  });

  const { mutate, isPending } = useUpsertLiabilitiesMutation(() => {
    handleToastMessage({
      title: 'Success',
      description: 'Saved Successfully',
      status: 'success',
      duration: 5000,
      isClosable: false,
    });
    onSave();
  });

  const hideOriginalAccountInfoSection = (typeKey = null) => {
    typeKey = typeKey ?? getValues('liabilitySummaryType');
    return ![
      LIABILITY_TYPE_CODES.REAL_ESTATE,
      LIABILITY_TYPE_CODES.HELOC,
    ].includes(+typeKey);
  };

  const autoExcludePayment = (
    nextLiabilityType = null,
    remainingTerm = null,
  ) => {
    const currentLiabilityType = +getValues('liabilitySummaryType');
    const exclusionReason = +getValues('exclusionReason');
    remainingTerm = remainingTerm ?? getValues('remainingTerm');

    const isInstallment = (type) => type === LIABILITY_TYPE_CODES.INSTALLMENT;
    const isLessThan10Months = (reason) =>
      reason === EXCLUSION_REASON_CODES.LESS_THAN_10_MONTHS_REMAIN;

    if (
      (isInstallment(currentLiabilityType) &&
        !isInstallment(+nextLiabilityType) &&
        isLessThan10Months(exclusionReason)) ||
      (!isInstallment(currentLiabilityType) &&
        isInstallment(+nextLiabilityType) &&
        isLessThan10Months(exclusionReason) &&
        remainingTerm > 10)
    ) {
      setValue('excludePayment', false);
      setValue('exclusionReason', '');
      return;
    }

    if (
      !isInstallment(currentLiabilityType) &&
      isInstallment(+nextLiabilityType) &&
      !isLessThan10Months(exclusionReason) &&
      remainingTerm > 10
    ) {
      return;
    }

    if (
      remainingTerm !== '' &&
      remainingTerm >= 0 &&
      remainingTerm <= 10 &&
      isInstallment(+nextLiabilityType)
    ) {
      setValue('excludePayment', true);
      setValue(
        'exclusionReason',
        EXCLUSION_REASON_CODES.LESS_THAN_10_MONTHS_REMAIN,
      );
      return;
    }

    if (isLessThan10Months(exclusionReason) && remainingTerm > 10) {
      setValue('excludePayment', false);
      setValue('exclusionReason', '');
      return;
    }
  };

  // Form register for validation
  register('customerIdentifiers', {
    validate: (value) =>
      value.length > 0 ? true : language.LIABILITY_ASSOCIATED_ERROR,
  });
  register('liabilitySummaryType', { required: true });
  register('creditorName');
  register('accountNumber');
  register('payment', { required: true });
  register('balance', { required: true });
  register('remainingTerm');
  register('payoffRequired');
  register('excludePayment');
  register('payoffAmount');
  register('exclusionReason', {
    validate: (optionKey) => {
      if (!getValues('excludePayment')) return true;
      if (optionKey === null || optionKey === '') return false;
      return +optionKey > -1;
    },
  });

  register('associatedRealEstate', {
    required: !hideOriginalAccountInfoSection(),
  });
  register('lienPosition', {
    required: !hideOriginalAccountInfoSection(),
  });
  register('ownerOfMortgage', {
    required: !hideOriginalAccountInfoSection(),
  });
  register('isCurrentlyServicedByInstitution');
  register('isCurrentlyFHAInsured');
  register('creditReportOpenedDate');
  register('originalMortgageType');
  register('originalMortgageDesc');
  register('originalMortgageLoanPurpose');
  register('originalSalesPrice');
  register('mostRecentAppraisedValue');
  register('highestBalance');
  register('originalTerm');
  register('originalTermRemaining');
  register('originalFirstPaymentDate');
  register('originalInterestRate');
  register('originalApr');
  register('remainingTerm');
  register('originalMonthlyMiAmount');
  register('originalSectionAct');
  register('originalNextAdjustment');
  register('numberOfPaymentsMade');

  useEffect(() => {
    reset(getDefaultValues(rowToEdit));
  }, [rowToEdit, reset]);

  const resetOriginalAccountInfoSection = (value) => {
    reset({
      ...getValues(),
      liabilitySummaryType: value,
      associatedRealEstate: '',
      lienPosition: '',
      ownerOfMortgage: '',
      isCurrentlyServicedByInstitution: false,
      isCurrentlyFHAInsured: false,
    });
  };

  const handleChange = (field, value) => {
    switch (field) {
      case 'liabilitySummaryType':
        autoExcludePayment(value);
        if (hideOriginalAccountInfoSection(value)) {
          resetOriginalAccountInfoSection(value);
        }
        setValue(field, value);
        break;
      case 'excludePayment':
        if (!value) {
          setValue('exclusionReason', '');
        }
        setValue(field, value);
        break;
      case 'payoffRequired':
        setValue('payoffAmount', value ? getValues('balance') : 0);
        setValue(field, value);
        break;
      case 'originalApr':
        setValue(field, sanitizeInterestRate(value));
        break;
      case 'originalInterestRate':
        setValue(field, sanitizeInterestRate(value));
        break;
      default:
        setValue(field, value);
    }
    trigger();
  };

  const onSubmit = (data) => {
    const saveObject = {
      ...data,
      excludeBalance: data.excludePayment,
      exclusionReason: data.excludePayment ? +data.exclusionReason : null,
      associatedRealEstate: null,
      obfuscatedLoanIdentifier,
      liabilityIdentifier: {
        ...obfuscatedLoanIdentifier,
        debtId: rowToEdit.liabilityIdentifier
          ? rowToEdit.liabilityIdentifier.debtId
          : 0,
        debtLenderDatabaseId: 1,
      },
      addressIdentifier: hideOriginalAccountInfoSection()
        ? null
        : {
            addressLdId: 1,
            addressId: data.associatedRealEstate,
          },
      payoffAmount: data.payoffRequired ? data.payoffAmount : 0,
      customerIdentifiers: data.customerIdentifiers.map((id) => ({
        obfuscatedLenderDatabaseId:
          obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
        customerRecordId: id,
      })),
    };
    mutate(saveObject);
  };
  const handleBorrowerChange = (value) => {
    const customerIdentifiers = getValues('customerIdentifiers');
    const index = customerIdentifiers.indexOf(value);

    if (index !== -1) {
      // Value is already in the array, remove it
      customerIdentifiers.splice(index, 1);
    } else {
      // Value is not in the array, add it
      customerIdentifiers.push(value);
    }
    handleChange('customerIdentifiers', customerIdentifiers);
  };

  const handleBalanceAndPaymentChange = (field, value) => {
    const unFormattedValue = value ? reverseFormatCurrency(value) : '';
    let payment = getValues('payment');
    let balance = getValues('balance');
    let payoffRequired = getValues('payoffRequired');
    let remainingTerm = '';
    if (unFormattedValue) {
      if (field === 'balance' && payment) {
        remainingTerm = Math.ceil(unFormattedValue / payment);
      } else if (field === 'payment' && balance) {
        remainingTerm = Math.ceil(balance / unFormattedValue);
      }
    }
    setValue('remainingTerm', remainingTerm);
    autoExcludePayment(getValues('liabilitySummaryType'), remainingTerm);
    if (field === 'balance') {
      handleChange('payoffAmount', payoffRequired ? unFormattedValue : 0);
    }
    handleChange(field, unFormattedValue);
  };

  const liabilityInfoSection = [
    {
      type: 'checkboxGroup',
      name: 'customerIdentifiers',
      label: 'Associated Borrower(s)',
      value: 'customerIdentifiers',
      options: allBorrowerOptions,
      isRequired: true,
      span: 4,
      isChecked: (option) =>
        getValues('customerIdentifiers')?.includes(option.customerRecordId),
      displayValue: (option) =>
        concatenateObjectAttributes(option, ['firstName', 'lastName']),
      onChangeHandler: (option) =>
        handleBorrowerChange(option.customerRecordId),
    },
    {
      type: 'select',
      name: 'liabilitySummaryType',
      label: 'Liability Type',
      value: 'liabilitySummaryType',
      placeholder: 'Select Liability Type',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions.availableLiabilityTypes ?? [],
      onChangeHandler: (e) => handleChange('liabilitySummaryType', e),
      isRequired: true,
    },
    {
      type: 'text',
      name: 'creditorName',
      label: 'Creditor Name',
      value: 'creditorName',
      placeholder: 'Creditor Name',
      onChangeHandler: (e) => handleChange('creditorName', e.target.value),
    },
    {
      type: 'text',
      name: 'accountNumber',
      label: 'Account Number',
      value: 'accountNumber',
      isRequired: false,
      errors: errors,
      placeholder: 'Account Number',
      onChangeHandler: (e) => handleChange('accountNumber', e.target.value),
    },
    {
      type: 'currency',
      name: 'payment',
      label: 'Monthly Payment',
      value: 'payment',
      placeholder: 'Monthly Payment',
      colStart: 1,
      onBlur: (value) => handleBalanceAndPaymentChange('payment', value),
      isRequired: true,
    },
    {
      type: 'currency',
      name: 'balance',
      label: 'Balance',
      value: 'balance',
      placeholder: 'Balance',
      onBlur: (value) => handleBalanceAndPaymentChange('balance', value),
      isRequired: true,
    },
    {
      type: 'text',
      name: 'remainingTerm',
      label: 'Remaining Term',
      value: 'remainingTerm',
      placeholder: 'Remaining Term',
      isRequired: false,
      disabled: true,
      readOnly: true,
      errors: errors,
    },
    {
      type: 'currency',
      name: 'payoffAmount',
      label: 'Payoff Amount',
      value: 'payoffAmount',
      placeholder: '0',
      disabled: true,
      readOnly: true,
      onBlur: (e) => handleChange('payment', e),
    },
    {
      type: 'checkbox',
      name: 'excludePayment',
      label: 'Exclude Payment/Balance',
      value: 'excludePayment',
      position: 'flex-start',
      onChangeHandler: () =>
        handleChange('excludePayment', !getValues('excludePayment')),
    },
    {
      type: 'checkbox',
      name: 'payoffRequired',
      label: 'Payoff Required',
      value: 'payoffRequired',
      colStart: 4,
      onChangeHandler: () =>
        handleChange('payoffRequired', !getValues('payoffRequired')),
    },
    {
      type: 'select',
      name: 'exclusionReason',
      label: 'Exclusion Reason',
      value: 'exclusionReason',
      placeholder: 'Select Exclusion Reason',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableExclusionReasons ?? [],
      disabled: !getValues('excludePayment'),
      colStart: 1,
      onChangeHandler: (e) => handleChange('exclusionReason', e),
      isRequired: true,
    },
  ];

  const originalAccountInfoSection = [
    {
      type: 'select',
      name: 'associatedRealEstate',
      label: 'Associated Real Estate',
      value: 'associatedRealEstate',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableRealEstateProperties ?? [],
      colStart: 1,
      span: 2,
      onChangeHandler: (e) => handleChange('associatedRealEstate', e),
      isRequired: true,
    },
    {
      type: 'select',
      name: 'lienPosition',
      label: 'Lien Position',
      value: 'lienPosition',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableLienPositions ?? [],
      span: 1,
      onChangeHandler: (e) => handleChange('lienPosition', e),
      isRequired: true,
    },
    {
      type: 'select',
      name: 'ownerOfMortgage',
      label: 'Owner of Mortgage',
      value: 'ownerOfMortgage',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableOwnersOfMortgage ?? [],
      span: 1,
      onChangeHandler: (e) => handleChange('ownerOfMortgage', e),
      isRequired: true,
    },
    {
      type: 'checkbox',
      name: 'isCurrentlyServicedByInstitution',
      label: 'Loan currently serviced by your institution',
      value: 'isCurrentlyServicedByInstitution',
      span: 1,
      onChangeHandler: () =>
        handleChange(
          'isCurrentlyServicedByInstitution',
          !getValues('isCurrentlyServicedByInstitution'),
        ),
    },
    {
      type: 'checkbox',
      name: 'isCurrentlyFHAInsured',
      label: 'Original loan is currently FHA insured',
      value: 'isCurrentlyFHAInsured',
      span: 1,
      onChangeHandler: () =>
        handleChange(
          'isCurrentlyFHAInsured',
          !getValues('isCurrentlyFHAInsured'),
        ),
    },
    {
      type: 'datepicker',
      name: 'creditReportOpenedDate',
      label: 'Credit Report Opened Date',
      placeholder: 'Credit Report Opened Date',
      value: 'creditReportOpenedDate',
      colStart: 1,
      onChangeHandler: (value) => handleChange('creditReportOpenedDate', value),
    },
    {
      type: 'select',
      name: 'originalMortgageType',
      label: 'Original Mortgage Type',
      value: 'originalMortgageType',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableOriginalMortgageType ?? [],
      onChangeHandler: (e) => handleChange('originalMortgageType', e),
    },
    {
      type: 'select',
      name: 'originalMortgageDesc',
      label: 'Original Mortgage Desc',
      value: 'originalMortgageDesc',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableOriginalMortgageDesc ?? [],
      onChangeHandler: (e) => handleChange('originalMortgageDesc', e),
    },
    {
      type: 'select',
      name: 'originalMortgageLoanPurpose',
      label: 'Original Mortgage Purpose',
      value: 'originalMortgageLoanPurpose',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableOriginalMortgageLoanPurpose ?? [],
      onChangeHandler: (e) => handleChange('originalMortgageLoanPurpose', e),
    },
    {
      type: 'currency',
      name: 'originalSalesPrice',
      label: 'Original Sales Price',
      value: 'originalSalesPrice',
      placeholder: 'Original Sales Price',
      colStart: 1,
      onBlur: (value) => handleChange('originalSalesPrice', value),
    },
    {
      type: 'currency',
      name: 'mostRecentAppraisedValue',
      label: 'Most Recent Appraised Value',
      value: 'mostRecentAppraisedValue',
      placeholder: 'Most Recent Appraised Value',
      onBlur: (value) => handleChange('mostRecentAppraisedValue', value),
    },
    {
      type: 'currency',
      name: 'highestBalance',
      label: 'Highest Balance',
      value: 'highestBalance',
      placeholder: 'Highest Balance',
      onBlur: (value) => handleChange('highestBalance', value),
    },
    {
      type: 'number',
      name: 'originalTerm',
      label: 'Original Term (Months)',
      placeholder: 'Original Term (Months)',
      value: 'originalTerm',
      colStart: 1,
      onChangeHandler: (e) => handleChange('originalTerm', e.target.value),
    },
    {
      type: 'datepicker',
      name: 'originalFirstPaymentDate',
      label: 'Original First Payment Date',
      placeholder: 'Original First Payment Date',
      value: 'originalFirstPaymentDate',
      onChangeHandler: (value) =>
        handleChange('originalFirstPaymentDate', value),
    },
    {
      type: 'inputWithPercentage',
      name: 'originalInterestRate',
      label: 'Original Interest Rate',
      placeholder: 'Original Interest Rate',
      value: 'originalInterestRate',
      symbol: '%',
      onChangeHandler: (value) => handleChange('originalInterestRate', value),
    },
    {
      type: 'inputWithPercentage',
      name: 'originalApr',
      label: 'Original APR',
      placeholder: 'Original APR',
      value: 'originalApr',
      symbol: '%',
      onChangeHandler: (value) => handleChange('originalApr', value),
    },
    {
      name: 'originalTermRemaining',
      type: 'number',
      label: 'Original Term Remaining (Months)',
      placeholder: 'Original Term Remaining (Months)',
      value: 'originalTermRemaining',
      colStart: 1,
      onChangeHandler: (e) =>
        handleChange('originalTermRemaining', e.target.value),
    },
    {
      type: 'currency',
      name: 'originalMonthlyMiAmount',
      label: 'Original Monthly MI Amt',
      placeholder: 'Original Monthly MI Amt',
      value: 'originalMonthlyMiAmount',
      onBlur: (value) => handleChange('originalMonthlyMiAmount', value),
    },
    {
      type: 'select',
      name: 'originalSectionAct',
      label: 'Original Section of Act',
      value: 'originalSectionAct',
      placeholder: 'Select Option',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: dropdownOptions?.availableOriginalSectionAct ?? [],
      onChangeHandler: (e) => handleChange('originalSectionAct', e),
    },
    {
      type: 'datepicker',
      name: 'originalNextAdjustment',
      label: 'Original Next Adjust Date',
      value: 'originalNextAdjustment',
      colStart: 1,
      onChangeHandler: (value) => handleChange('originalNextAdjustment', value),
    },
    {
      type: 'number',
      name: 'numberOfPaymentsMade',
      label: 'Number of Payments Made',
      placeholder: 'Number of Payments Made',
      value: 'numberOfPaymentsMade',
      onChangeHandler: (e) =>
        handleChange('numberOfPaymentsMade', e.target.value),
    },
  ];

  return (
    <Loan1003FormContainer
      onCloseClicked={() => onClose()}
      onSaveClicked={handleSubmit(onSubmit)}
      toggleErrors={() => setShowErrors(true)}
      isLoading={isPending}
      readOnlyMode={isWriteAccessDisabled}
    >
      <Loan1003FormSection title="Liability Information">
        <Loan1003FormBuilderWrapper>
          <FormBuilder
            fields={liabilityInfoSection}
            columns={4}
            rowGap={'1.5rem'}
            errors={errors}
            showErrors={showErrors}
            readOnlyMode={isWriteAccessDisabled}
            getValues={getValues}
          />
        </Loan1003FormBuilderWrapper>
      </Loan1003FormSection>
      <Loan1003FormSection
        title="Original Account Information"
        isHidden={hideOriginalAccountInfoSection}
      >
        <Loan1003FormBuilderWrapper>
          <FormBuilder
            fields={originalAccountInfoSection}
            columns={4}
            rowGap={'1.5rem'}
            errors={errors}
            showErrors={showErrors}
            readOnlyMode={isWriteAccessDisabled}
            getValues={getValues}
          />
        </Loan1003FormBuilderWrapper>
      </Loan1003FormSection>
    </Loan1003FormContainer>
  );
}
LiabilitiesSummariesForm.propTypes = {
  rowToEdit: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  allBorrowerOptions: PropTypes.any,
  isWriteAccessDisabled: PropTypes.bool,
  obfuscatedLoanIdentifier: PropTypes.any,
  handleToastMessage: PropTypes.func,
  dropdownOptions: PropTypes.any,
};
