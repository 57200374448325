import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormControl from 'common/components/Forms/FormControl';
import { Controller } from 'react-hook-form';
import MultiSelect from 'common/components/Selects/MultiSelect/MultiSelect';

// Local
import useDocumentCategoryOptions from './hooks/useDocumentCategoryOptions';
import useDocumentTypeOptions from './hooks/useDocumentTypeOptions';
import { CLASSIFICATION } from './constants';
import { FORM_MESSAGES } from 'common/constants';

const ClassifyStaticFormContent = ({ rhfProps }) => {
  // rhf props
  const { clearErrors, control, errors, setValue, watch } = rhfProps;
  // use documentCategory from rhf to populate documentTypeOptions
  const selectedDocumentCategory = watch(CLASSIFICATION.docCategory);
  // Options for 'Select Document Category' dropdown
  const documentCategoryOptions = useDocumentCategoryOptions();
  // Options for 'Select Document Type' dropdown
  const documentTypeOptions = useDocumentTypeOptions(selectedDocumentCategory);
  // redux
  const { selectedDocument } = useSelector(
    ({ classifyDocumentsV2 }) => classifyDocumentsV2,
  );

  /**
   * ------- *
   * Effects *
   * ------- *
   */

  // set default values for 'Select Document Category' dropdown
  // & 'Select Document Type' dropdown on page load
  useEffect(() => {
    if (selectedDocument) {
      setValue(CLASSIFICATION.docCategory, {
        label: selectedDocument.categoryName,
        value: selectedDocument.categoryName,
      });
      setValue(CLASSIFICATION.docType, {
        label: selectedDocument.documentTypeName,
        value: +selectedDocument.documentTypeId,
      });
    }
  }, [selectedDocument, setValue]);

  const handleCategoryChange = (event, option) => {
    setValue(CLASSIFICATION.docCategory, option);
    option.value !== selectedDocument.categoryId &&
      setValue(CLASSIFICATION.docType, null);
    clearErrors();
  };

  const handleTypeChange = (event, option) => {
    setValue(CLASSIFICATION.docType, option);
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */

  return (
    <>
      <FormControl
        isInvalid={errors[CLASSIFICATION.docCategory]}
        sx={{ mb: 4 }}
      >
        <Controller
          name={CLASSIFICATION.docCategory}
          control={control}
          rules={{ required: FORM_MESSAGES.requiredField }}
          defaultValue={null}
          /**
           * Note: not using 'as' prop here as we need to have custom
           * logic on onChange. In most cases 'as' prop will do the job
           */
          render={({ value }) => (
            <MultiSelect
              label="Select Document Category"
              value={value}
              options={documentCategoryOptions}
              onChange={handleCategoryChange}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => {
                return option?.value === value?.value;
              }}
              errors={errors}
              clearErrors={clearErrors}
              id={CLASSIFICATION.docCategory}
              data-tracking={CLASSIFICATION.docCategory}
              data-testid={CLASSIFICATION.docCategory}
              disableClearable
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ mb: 4 }} isInvalid={errors[CLASSIFICATION.docType]}>
        <Controller
          name={CLASSIFICATION.docType}
          control={control}
          rules={{ required: FORM_MESSAGES.requiredField }}
          defaultValue={null}
          render={({ value }) => (
            <MultiSelect
              label="Select Document Type"
              value={value}
              options={documentTypeOptions}
              onChange={handleTypeChange}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => {
                return option?.value === value?.value;
              }}
              errors={errors}
              clearErrors={clearErrors}
              id={CLASSIFICATION.docType}
              data-tracking={CLASSIFICATION.docType}
              data-testid={CLASSIFICATION.docType}
              disableClearable
            />
          )}
        />
      </FormControl>
    </>
  );
};

// TODO: better propTypes
ClassifyStaticFormContent.propTypes = {
  rhfProps: PropTypes.object.isRequired,
};

export default ClassifyStaticFormContent;
