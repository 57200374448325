import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';
import {
  Table as TableComponent,
  Tbody,
  Thead,
  Tr,
  Th,
} from 'common/components/Tables';
import Box from 'common/components/Box';
import GenericTooltip from 'common/components/GenericTooltip';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

import styled from '@emotion/styled';

const StyledTh = styled(Th)`
  height: 56px;
  margin: 0;
  font-weight: bold;
  text-transform: initial;
  color: ${(props) => props.theme.colors?.black};
  background-color: ${(props) => props.theme.colors?.blue[50]};
  border-left: solid 1px ${(props) => props.theme.colors?.gray[200]};
  letter-spacing: 0;
`;
const StyledTableContainer = styled(Box)`
  border: solid 1px ${(props) => props.theme.colors?.gray[200]};
  border-radius: 4px;
  margin-bottom: 18px;
  max-height: ${(props) =>
    props.doesTBodyScroll ? props.tbodymaxheight : 'auto'};
  overflow-y: ${(props) => props.handleOverFlowScroll(props)};
  margin: ${(props) => props.margin ?? '0 0 0 0'};
`;

export default function Table({
  tableHeader,
  fields,
  doesTBodyScroll,
  tbodyMaxHeight,
  noOverflowScroll = false,
  margin,
}) {
  const { renderForm } = useFormApi();
  const totalCols = tableHeader.reduce((acc, cur) => acc + cur.colSpan, 0);
  const { colors } = useTheme();
  const handleOverFlowScroll = (props) => {
    if (noOverflowScroll) return 'none';

    return props?.doesTBodyScroll ? 'auto' : 'inherit';
  };
  return (
    <StyledTableContainer
      doestbodyscroll={doesTBodyScroll}
      tbodymaxheight={tbodyMaxHeight}
      className="styledThContainer"
      handleOverFlowScroll={handleOverFlowScroll}
      margin={margin}
    >
      <TableComponent>
        <colgroup>
          {Array.from({ length: totalCols }, (_, index) => (
            <col key={index} width={`${100 / totalCols}%`}></col>
          ))}
        </colgroup>
        <Thead sx={doesTBodyScroll && { position: 'sticky', top: 0 }}>
          <Tr sx={{ border: `solid 0.5px ${colors?.gray[200]}` }}>
            {tableHeader.map(
              ({ label, colSpan, helperText, isRequired, thStyle }, index) => (
                <StyledTh
                  key={label}
                  borderLeft={index !== 0 ? '0px ' : undefined}
                  colSpan={colSpan}
                  sx={{ pl: '1.875rem', fontSize: '0.875rem', ...thStyle }}
                >
                  {label} {isRequired && <font color="red.500"> *</font>}
                  {helperText && <GenericTooltip label={helperText} />}
                </StyledTh>
              ),
            )}
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((field, index) => {
            field.headerCols = tableHeader;
            field.position = index;
            return renderForm([field]);
          })}
        </Tbody>
      </TableComponent>
    </StyledTableContainer>
  );
}
Table.propTypes = {
  fields: PropTypes.array,
  index: PropTypes.number,
  doesTBodyScroll: PropTypes.bool,
  noOverflowScroll: PropTypes.bool,
  tbodyMaxHeight: PropTypes.string,
  margin: PropTypes.string,
  tableHeader: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colSpan: PropTypes.number,
      isRequired: PropTypes.bool,
    }),
  ),
};
