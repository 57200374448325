import Box from 'common/components/Box';
import styled from '@emotion/styled';

export const StyledContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.colors?.gray[200]};
`;

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
