import Box from 'common/components/Box';
import Switch from 'common/components/Switch';
import { StyledIconButton, StyledLink } from './GridStyledComponents';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { faLineColumns, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'common/components/Button';
import { useState, useMemo } from 'react';
import theme from 'theme/';
import FilterMenu from './Filters/FilterMenu';
import IconBadge from '../Badge/IconBadge';
import { StyledAuditHistoryButton } from '../AuditHistoryButton/StyledAuditHistoryButton';
import { useAuditHistoryWindow } from 'common/hooks/useAuditHistoryWindow';
import { getCategoryNumber } from 'pages/AuditHistory/constants';

const defaultActionStates = {
  filter: false,
  columns: false,
  action: false,
};

export const popOverBoxStyles = {
  position: 'absolute',
  top: '100%',
  marginTop: '0.5em',
  width: '15rem',
  borderRadius: '0.5rem',
  padding: '0.25rem',
  zIndex: 1,
  right: 0,
  background: 'white',
};

export default function CrudGridToolbar({
  columns,
  toggleColumn,
  filters,
  appliedFilters,
  onFilterChange,
  resetColumns,
  manageColumns,
  allowFilters,
  multiSelectAction,
  onActionClick,
  isReadOnly,
  isMultiSelectActionMode,
  onSubActionClick,
  isAuditHistoryEnabled,
}) {
  const [basePath, category] = window.location.pathname.split('summary/');
  const [actionState, setActionState] = useState(defaultActionStates);
  const auditHistoryPath = `${basePath}audit-history/summary/${
    category || ''
  }/`;
  const { handleChangePageId } = useAuditHistoryWindow(auditHistoryPath);

  const totalFilters = useMemo(() => {
    return appliedFilters.reduce((acc, filter) => {
      return acc + filter.value.length;
    }, 0);
  }, [appliedFilters]);

  const heightNeededMultipleDeleteOpen = '93px';
  const heightNeededFilterOpen = '58px';

  return (
    <Flex
      id="crudGridToolbar"
      justify={'flex-end'}
      direction={'column'}
      width={'100%'}
      height={
        isMultiSelectActionMode
          ? heightNeededMultipleDeleteOpen
          : heightNeededFilterOpen
      }
      marginBottom={'3px'}
    >
      <Flex id="crud-grid-main-toolbar" align={'flex-end'} justify={'flex-end'}>
        {actionState.filter && (
          <FilterMenu
            filters={filters}
            currentFilters={appliedFilters}
            onFilterChange={onFilterChange}
          />
        )}
        <Flex
          id="crudGridToolbarBelt"
          justify={'flex-end'}
          align={'flex-end'}
          mb={!multiSelectAction ? '0.5rem' : 'unset'}
        >
          {allowFilters && (
            <IconBadge
              badgeContent={totalFilters}
              color={theme.colors?.teal[50]}
            >
              <StyledIconButton
                variant="outline"
                aria-label="Filter"
                colorScheme={'blue'}
                marginLeft={'0.5em'}
                data-testid={'crud-grid-filter-button'}
                icon={<FontAwesomeIcon icon={faFilter} />}
                active={actionState.filter}
                onClick={() => {
                  setActionState((prev) => ({
                    ...defaultActionStates,
                    filter: !prev.filter,
                  }));
                }}
              />
            </IconBadge>
          )}

          {manageColumns && (
            <Box position={'relative'}>
              <StyledIconButton
                variant="outline"
                aria-label="Filter"
                colorScheme={'blue'}
                marginLeft={'.75em'}
                icon={<FontAwesomeIcon icon={faLineColumns} />}
                active={actionState.columns}
                onClick={() => {
                  setActionState((prev) => ({
                    ...defaultActionStates,
                    columns: !prev.columns,
                  }));
                }}
              />
              {actionState.columns && (
                <Box style={popOverBoxStyles}>
                  <Box
                    borderBottom={`1px solid ${theme.colors?.gray[200]}`}
                    padding={'0.5rem 1rem'}
                    marginBottom={'0.5rem'}
                  >
                    <Text>Manage Columns</Text>
                  </Box>

                  {columns.map((column, i) => (
                    <Flex
                      key={i}
                      justify={'space-between'}
                      padding={'0.05rem 0.25rem'}
                    >
                      <Text>{column.headerName}</Text>

                      <Switch
                        colorScheme="blue"
                        isChecked={
                          typeof column.display !== 'boolean' || column.display
                        }
                        onChange={() => {
                          toggleColumn(i);
                        }}
                      />
                    </Flex>
                  ))}

                  <Button onClick={resetColumns}>Reset</Button>
                </Box>
              )}
            </Box>
          )}
          {multiSelectAction && !isReadOnly && (
            <Button
              isPrimary={false}
              onClick={() => {
                onActionClick();
              }}
              marginLeft={'0.75rem'}
              data-testid={'delete-multiple'}
            >
              {multiSelectAction.buttonLabel}
            </Button>
          )}
          {isAuditHistoryEnabled && !isReadOnly && (
            <StyledAuditHistoryButton
              rightIcon={<FontAwesomeIcon icon={faHistory} />}
              marginLeft={'0.75rem'}
              data-testid="audit-history--button"
              aria-label={`${category} Audit History`}
              isPrimary={false}
              onClick={() => {
                handleChangePageId(getCategoryNumber(category));
              }}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        direction={'row-reverse'}
        justifyContent="flex-start"
        marginTop={'0.5rem'}
      >
        {multiSelectAction?.subActions?.map((action, i) => (
          <StyledLink
            key={i}
            id={action.id ?? 'selectDeselectAllButton'}
            data-testId={action.id ?? 'selectDeselectAllButton'}
            onClick={() => {
              onSubActionClick(action);
            }}
            mb={isMultiSelectActionMode ? '1.5rem' : '0.5rem'}
            ml={'1rem'}
            height={isMultiSelectActionMode ? 'auto' : 0}
            overflow={'hidden'}
            fontSize={'0.8125rem'}
            fontWeight={'500'}
            placeItems={'flex-end'}
            color={'blue.600'}
            minWidth={'unset'}
            transition={'unset'}
          >
            {action.label}
          </StyledLink>
        ))}
      </Flex>
    </Flex>
  );
}
CrudGridToolbar.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      headerName: PropTypes.string,
      sortable: PropTypes.bool,
      isNumeric: PropTypes.bool,
      renderCell: PropTypes.func,
    }),
  ).isRequired,
  gridType: PropTypes.string,
  filters: PropTypes.array,
  appliedFilters: PropTypes.array,
  onFilterChange: PropTypes.func,
  setSearchTerm: PropTypes.func,
  toggleColumn: PropTypes.func,
  resetColumns: PropTypes.func,
  manageColumns: PropTypes.bool,
  allowFilters: PropTypes.bool,
  multiSelectAction: PropTypes.shape({
    buttonLabel: PropTypes.string,
    subActions: PropTypes.array,
  }),
  onActionClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isMultiSelectActionMode: PropTypes.bool,
  onSubActionClick: PropTypes.func,
  isAuditHistoryEnabled: PropTypes.bool,
};
