// ***** RuleID / RuleName *****
export const PREVIOUS_EMPLOYMENT_REVIEW_BREAKDOWN_RULE = 10;
export const FRAUD_ALERT_EXISTS_RULE = 1;
export const TWO_OR_MORE_MISSING_SCORES_RULE = 2;
export const ACTIVE_DUTY_ALERT_EXISTS_RULES = 3;
export const CREDIT_INQUIRY_WITHIN_90_DAYS_RULE = 4;
export const DATE_OF_BIRTH_MISMATCH_RULE = 5;
export const LESS_THAN_18_RULE = 6;
export const MISSING_OR_INCORRECT_DOB_VALUE_RULE = 7;
export const EXPIRATION_DATE_BEFORE_UPLOAD_DATE_RULE = 8;
export const EXPIRATION_DATE_NOT_FOUND_RULE = 9;
export const PAID_BY_OTHERS_ALERT_NON_MORTGAGE_DEBT_ROLE = 10;
export const OPEN_MORTGAGE_PAYMENT_ALERT_RULE = 11;
export const DRIVERS_LICENSE_ADDRESS_MISMATCH = 14;
export const DRIVERS_LICENSE_NAME_MISMATCH = 16;
export const DRIVERS_LICENSE_NAME_CORRECTED = 17;
export const CALCULATION_REVIEW_BREAKDOWN = 21;
export const MORTGAGE_HISTORY_PARENT_RULE = 29;
export const FRAUD_ALERT_PARENT_RULE = 30;
export const ACTIVE_DUTY_PARENT_RULE = 31;
export const LIABILITY_ALL_ALERTS_RULE = 32;
export const LIABILITY_PAYMENT_MISMATCH_ALERT_RULE = 33;
export const LIABILITY_BALANCE_MISMATCH_ALERT_RULE = 34;
export const LIABILITY_STUDENT_LOAN_PAYMENT_0_OR_NOT_REPORTED_FHA_RULE = 14057;
export const LIABILITY_NO_PAYMENT_ALERT_RULE = 35;
export const LIABILITY_MISSING_FROM_CREDIT_RULE = 37;
export const LIABILITY_STUDENT_LOAN_DU = 39;
export const LIABILITY_STUDENT_LOAN_LP = 40;
export const LIABILITY_STUDENT_LOAN_FHA = 14029;
export const LIABILITY_TYPE_MISMATCH_ALERT_RULE = 42;
export const LIABILITY_VERIFY_ACCOUNTS_RULE = 14050;
export const PAYSTUB_NAME_MISMATCH = 25;
export const PAYSTUB_ADDRESS_MISMATCH = 24;
export const W2_NAME_MISMATCH = 27;
export const W2_ADDRESS_MISMATCH = 26;
export const RESIDENCY_STATUS_ALL_ALERTS_RULE = 16001;
export const NON_PERMANENT_RESIDENT_RULE = 16003;
export const PERMANENT_RESIDENT_RULE = 28;
export const FOREIGN_NATIONAL_RULE = 16004;
export const NON_PERMANENT_RESIDENCY_STATUS_FHA_RULE = 16005;
export const FIRST_TIME_HOME_BUYER_DU_RULE = 38;
export const FIRST_TIME_HOME_BUYER_LPA_RULE = 16002;
export const COMMUNITY_PROPERTY_STATE_RULE = 16006;
export const COMMUNITY_PROPERTY_STATE_PER_BORROWER_RULE = 16007;
export const TITLE_PACKAGE_REVIEW = 2000;
export const TITLE_COMMITMENT_RULE = 2002;
export const CPL_REVIEW_RULE = 2003;
export const WIRE_INSTRUCTIONS_RULE = 2004;
export const SELLER_ACQUISITION_RULE = 2005;
export const LIABILITY_ASSOCIATE_ACCOUNTS_RULE = 22001;
export const INCOME_SOURCE_RULE = 52;
export const DOES_1040_SHOW_CONSISTENT_LOSS_RULE = 9058;
export const FINAL_CHECKS_RULE = 21000;
export const EMPLOYMENT_REVIEW_RULE = 17006;
export const W2_SSN_MISMATCH = 17000;
export const SCHEDULE_C_SSN_MISMATCH = 17001;
export const FHA_SELF_EMPLOYMENT_MISSING_ADDRESS = 17034;
export const WAGE_EARNER_MISSING_ADDRESS = 17035;
// TODO: update correct ids for Third party checks
export const THIRD_PARTY_CHECKS_RULE = -103;
export const MORTGAGE_LATE_PAYMENT_RULE = 14000;
export const EARLY_CLOSING_DISCLOSURE_ALERT_RULE = 22000;
export const REO_PAYMENT_ALERT_RULE = 14002;
export const HELOC_ALERT_RULE = 14003;
export const SSN_MISMATCH_ALL_ALERTS_RULE = 14004;
export const BANKRUPTCY_DEROGATORY_RULE = 14005;
export const FORECLOSURE_DEROGATORY_RULE = 14006;
export const DEROGATORY_EVENTS_PARENT_RULE = 14007;
export const DEROGATORY_EVENTS_KEYWORDS_CHILD_RULE = 14051;
export const JUDGEMENTS_DEROGATORY_RULE = 14008;
export const COLLECTIONS_DEROGATORY_RULE = 14009;
export const TAXLIEN_DEROGATORY_RULE = 14010;
export const RECENTLY_PAID_OFF_DEBT_PARENT_RULE = 14012;
export const REVOLVING_PAYMENT_ZERO_NOT_REPORTING_FHA_LP_RULE = 14100;
export const REVOLVING_PAYMENT_ZERO_NOT_REPORTING_DU_RULE = 14104;
export const NEW_DEBT_ON_CREDIT_INQUIRY_LETTER_RULE = 14054;
export const CREDIT_REPORT_MANUAL_REVIEW = 14107;
export const PENSION_SSN_RULE_ID = 9901;
export const FHA_REQUIREMENTS = 62;
export const INSURANCE_RULE_ID = 1000;
export const INCOME_RECONCILIATION_RULE_ID = 4020;
export const HOMEOWNERS_PARENT_RULE_ID = 10001;
export const SUBJECT_PROPERTY_OCCUPANCY_FHA_CASHOUT_RULE_ID = 22003;
export const RESIDENCYSTATUS = {
  FOREIGN_NATIONAL: 'ForeignNational',
  RESIDENT: 'Resident',
  NON_RESIDENT: 'Nonresident',
};
export const ASSET_RECONCILIATION_RULE_ID = 19998;
export const ASSET_TYPE_RULE_ID = 19000;
export const ASSET_ACCOUNT_SUMMARY_RULE_ID = 19120;
export const ASSET_BANK_STATEMENT_RULE_ID = 19110;
export const ASSET_ACCOUNT_REVIEW_RULE_ID = 19130;
export const PURCHASE_AGREEMENT_RULE_ID = 5301;
export const PURCHASE_AGREEMENT_NO_DOCS_RULE_ID = 5302;
export const NET_TANGIBLE_BENEFITS_RULE_ID = 13;
export const RULE_ID = {
  TITLE_PACKAGE_REVIEW,
  TITLE_COMMITMENT_RULE,
  CPL_REVIEW_RULE,
  WIRE_INSTRUCTIONS_RULE,
  SELLER_ACQUISITION_RULE,
  PREVIOUS_EMPLOYMENT_REVIEW_BREAKDOWN_RULE,
  FHA_REQUIREMENTS,
  FIRST_TIME_HOME_BUYER_LPA_RULE,
  FIRST_TIME_HOME_BUYER_DU_RULE,
  PERMANENT_RESIDENT_RULE,
  NON_PERMANENT_RESIDENT_RULE,
  FOREIGN_NATIONAL_RULE,
  NON_PERMANENT_RESIDENCY_STATUS_FHA_RULE,
  RESIDENCY_STATUS_ALL_ALERTS_RULE,
  COMMUNITY_PROPERTY_STATE_RULE,
  COMMUNITY_PROPERTY_STATE_PER_BORROWER_RULE,
  FRAUD_ALERT_EXISTS_RULE,
  TWO_OR_MORE_MISSING_SCORES_RULE,
  ACTIVE_DUTY_ALERT_EXISTS_RULES,
  CREDIT_INQUIRY_WITHIN_90_DAYS_RULE,
  DATE_OF_BIRTH_MISMATCH_RULE,
  LESS_THAN_18_RULE,
  MISSING_OR_INCORRECT_DOB_VALUE_RULE,
  EXPIRATION_DATE_BEFORE_UPLOAD_DATE_RULE,
  EXPIRATION_DATE_NOT_FOUND_RULE,
  PAID_BY_OTHERS_ALERT_NON_MORTGAGE_DEBT_ROLE,
  OPEN_MORTGAGE_PAYMENT_ALERT_RULE,
  LIABILITY_ALL_ALERTS_RULE,
  LIABILITY_PAYMENT_MISMATCH_ALERT_RULE,
  LIABILITY_BALANCE_MISMATCH_ALERT_RULE,
  LIABILITY_STUDENT_LOAN_PAYMENT_0_OR_NOT_REPORTED_FHA_RULE,
  LIABILITY_NO_PAYMENT_ALERT_RULE,
  LIABILITY_MISSING_FROM_CREDIT_RULE,
  LIABILITY_STUDENT_LOAN_DU,
  LIABILITY_STUDENT_LOAN_LP,
  LIABILITY_STUDENT_LOAN_FHA,
  LIABILITY_TYPE_MISMATCH_ALERT_RULE,
  LIABILITY_VERIFY_ACCOUNTS_RULE,
  LIABILITY_ASSOCIATE_ACCOUNTS_RULE,
  DRIVERS_LICENSE_ADDRESS_MISMATCH,
  DRIVERS_LICENSE_NAME_MISMATCH,
  DRIVERS_LICENSE_NAME_CORRECTED,
  CALCULATION_REVIEW_BREAKDOWN,
  MORTGAGE_HISTORY_PARENT_RULE,
  ACTIVE_DUTY_PARENT_RULE,
  FRAUD_ALERT_PARENT_RULE,
  PAYSTUB_NAME_MISMATCH,
  PAYSTUB_ADDRESS_MISMATCH,
  W2_NAME_MISMATCH,
  W2_ADDRESS_MISMATCH,
  INCOME_SOURCE_RULE,
  DOES_1040_SHOW_CONSISTENT_LOSS_RULE,
  FINAL_CHECKS_RULE,
  THIRD_PARTY_CHECKS_RULE,
  MORTGAGE_LATE_PAYMENT_RULE,
  EARLY_CLOSING_DISCLOSURE_ALERT_RULE,
  REO_PAYMENT_ALERT_RULE,
  HELOC_ALERT_RULE,
  SSN_MISMATCH_ALL_ALERTS_RULE,
  BANKRUPTCY_DEROGATORY_RULE,
  FORECLOSURE_DEROGATORY_RULE,
  DEROGATORY_EVENTS_PARENT_RULE,
  JUDGEMENTS_DEROGATORY_RULE,
  TAXLIEN_DEROGATORY_RULE,
  RECENTLY_PAID_OFF_DEBT_PARENT_RULE,
  REVOLVING_PAYMENT_ZERO_NOT_REPORTING_FHA_LP_RULE,
  REVOLVING_PAYMENT_ZERO_NOT_REPORTING_DU_RULE,
  COLLECTIONS_DEROGATORY_RULE,
  EMPLOYMENT_REVIEW_RULE,
  W2_SSN_MISMATCH,
  NEW_DEBT_ON_CREDIT_INQUIRY_LETTER_RULE,
  CREDIT_REPORT_MANUAL_REVIEW,
  SCHEDULE_C_SSN_MISMATCH,
  RESIDENCYSTATUS,
  PENSION_SSN_RULE_ID,
  INSURANCE_RULE_ID,
  ASSET_RECONCILIATION_RULE_ID,
  FHA_SELF_EMPLOYMENT_MISSING_ADDRESS,
  WAGE_EARNER_MISSING_ADDRESS,
  ASSET_TYPE_RULE_ID,
  INCOME_RECONCILIATION_RULE_ID,
  PURCHASE_AGREEMENT_RULE_ID,
  PURCHASE_AGREEMENT_NO_DOCS_RULE_ID,
  HOMEOWNERS_PARENT_RULE_ID,
  NET_TANGIBLE_BENEFITS_RULE_ID,
  ASSET_ACCOUNT_SUMMARY_RULE_ID,
  ASSET_BANK_STATEMENT_RULE_ID,
  ASSET_ACCOUNT_REVIEW_RULE_ID,
  SUBJECT_PROPERTY_OCCUPANCY_FHA_CASHOUT_RULE_ID,
};

// ***** RuleCategoryID / RuleCategoryName *****
export const CREDIT = 1;
export const DOCUMENT = 2;
export const DRIVERS_LICENSE = 3;
export const INCOME = 4;
export const LIABILITIES = 5;

export const RULE_CATEGORY_ID = {
  CREDIT,
  DOCUMENT,
  DRIVERS_LICENSE,
  INCOME,
  LIABILITIES,
};

export const CONDITION_CATEGORY = {
  INCOME: 15,
  DRIVERS_LICENSE: 16,
  CREDIT: 17,
  BORROWER: 18,
  PURCHASE_AGREEMENT: 19,
  EMPLOYMENT: 20,
  ASSETS: 21,
  HOMEOWNERS_INSURANCE: 22,
  LIABILITIES: 24,
};

// ***** RuleSubCategoryID / RuleSubCategoryName *****
// todo these don't match the db setup table?
export const BORROWER = 1;
export const DRIVERS_LICENSE_BORROWER = 2;
export const CREDIT_LIABILITIES = 3;
export const NET_TAGNIBLE_BENEFITS = 4;
export const INCOME_CALCULATOR = 6;
export const RENTAL_INCOME = 12;

export const RULE_SUB_CATEGORY_ID = {
  BORROWER,
  DRIVERS_LICENSE_BORROWER,
  CREDIT_LIABILITIES,
  NET_TAGNIBLE_BENEFITS,
  INCOME_CALCULATOR,
};

// ***** QuestionDisplayTypeID / QuestionDisplayTypeDesc *****
export const CHECK_BOX = 1;
export const RADIO_BUTTON = 2;
export const TEXT_BOX = 3;
export const DATE = 4;
export const CHECKBOX_GROUP = 5;

export const QUESTION_DISPLAY_TYPE_ID = {
  CHECK_BOX,
  RADIO_BUTTON,
  TEXT_BOX,
  DATE,
  CHECKBOX_GROUP,
};

// ***** RuleResultStatusID / RuleResultStatusName *****
export const FIRED = 1;
export const CLEARED = 2;
export const DID_NOT_FIRE = 3;
// TODO: Update verified status with correct id
export const VERIFIED = 4;

export const RULE_RESULT_STATUS_ID = {
  FIRED,
  CLEARED,
  DID_NOT_FIRE,
  VERIFIED,
};

// ** RuleReferenceType / RuleReferenceValue
export const CREDIT_ALERT_ID = 1;
export const BORROWER_POSITION = 2;
export const INQUIRY_ID = 3;
export const TRADELINE_ID = 4;
export const LOAN_RECORD_ID = 5;
export const CUSTOMER_RECORD_ID = 6;
export const LICENSE_ID = 7;
export const LENDER_DATABASE_ID = 8;
export const DEBT_ID = 9;
export const EMPLOYER_ID = 17;
export const PAYMENT_HISTORY_ID = 18;
export const DOCUMENT_ID = 21;
export const ASSET_ID = 22;
export const PUBLIC_RECORD_ID = 24;
export const ADDRESS_ID = 23;
export const CREDIT_REPORT_ALERT_ID = 26;
export const CREDIT_REPORT_ALERT_SEQUENCE_NUMBER = 27;

export const RULE_REFERENCE_TYPE_ID = {
  CREDIT_ALERT_ID,
  BORROWER_POSITION,
  INQUIRY_ID,
  TRADELINE_ID,
  LOAN_RECORD_ID,
  CUSTOMER_RECORD_ID,
  LICENSE_ID,
  LENDER_DATABASE_ID,
  DEBT_ID,
  EMPLOYER_ID,
  PAYMENT_HISTORY_ID,
  DOCUMENT_ID,
  ASSET_ID,
  ADDRESS_ID,
  PUBLIC_RECORD_ID,
  CREDIT_REPORT_ALERT_ID,
  CREDIT_REPORT_ALERT_SEQUENCE_NUMBER,
};

export const UWM_ACTION_REQUIRED = 1;
export const ERROR = 2;
export const NO_ACTION_REQUIRED = 3;
export const TIP = 4;
export const PLAIN_TEXT = 5;

export const ALERT_SEVERITY = {
  HINT: 0,
  WARNING: 1,
  ALERT: 2,
  SUCCESS: 3,
  ATTENTION: 4,
};

export const ALERT_STYLE = {
  UWM_ACTION_REQUIRED,
  ERROR,
  NO_ACTION_REQUIRED,
  TIP,
  PLAIN_TEXT,
};

export const ALERT_STYLE_DESCRIPTION = {
  [ALERT_STYLE.UWM_ACTION_REQUIRED]: 'warning',
  [ALERT_STYLE.ERROR]: 'error',
  [ALERT_STYLE.NO_ACTION_REQUIRED]: 'success',
  [ALERT_STYLE.TIP]: 'info',
};

export const RENTAL_INCOME_CALCULATION_RULE_ID = 6;
export const INCOME_REVIEW_BREAKDOWN_RULE_ID = 4;
export const REVIEW_BREAKDOWN_YAML_RULE_ID = 10;

export const INCOME_RULE_ID = {
  RENTAL_INCOME_CALCULATION_RULE_ID,
  INCOME_REVIEW_BREAKDOWN_RULE_ID,
  REVIEW_BREAKDOWN_YAML_RULE_ID,
};

export const QUESTION_ID = {
  CPS_SPOUSE: 16006,
  VOB_LPA: 17017,
  VOB_DU: 17021,
};
