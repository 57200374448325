import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'common/components/Drawers/Drawer';
import DrawerBody from 'common/components/Drawers/DrawerBody';
import DrawerHeader from 'common/components/Drawers/DrawerHeader';
import DrawerCloseButton from 'common/components/Drawers/DrawerCloseButton';
import DrawerOverlay from 'common/components/Drawers/DrawerOverlay';
import DrawerContent from 'common/components/Drawers/DrawerContent';
import Box from 'common/components/Box';
import styled from '@emotion/styled';

// local
import QuestionCard from './QuestionHistoryCard';
import { AuditHistoryActions } from 'pages/AuditHistory/redux';
import { assignQuestionText } from './util';

const StyledDrawerHeader = styled(DrawerHeader)`
  border-bottom: ${(props) => `1px solid ${props.theme.colors?.bgGray[300]}`};
  box-shadow: ${(props) =>
    `0px 0px 1px 0px ${props.theme.colors?.bgGray[800]}`};
`;

const AuditHistoryDrawer = () => {
  const dispatch = useDispatch();
  const {
    data: historyData,
    selectedIds,
    questionIds,
    showAuditHistory,
    questions,
  } = useSelector(({ auditHistory }) => auditHistory);

  const contentToShow =
    showAuditHistory &&
    Array.isArray(selectedIds) &&
    selectedIds.every((id) => Object.keys(historyData).includes(`${id}`));

  return (
    <Drawer
      blockScrollOnMount={false}
      placement="right"
      isOpen={showAuditHistory}
      size="md"
      onClose={() => {
        dispatch(AuditHistoryActions.toggleShowAuditHistory());
      }}
    >
      <DrawerOverlay />
      <DrawerContent width="300px">
        <DrawerCloseButton />
        <StyledDrawerHeader>History</StyledDrawerHeader>

        <DrawerBody pt={'20px'}>
          {contentToShow &&
            // iterating through each ruleID & then it's corresponding questions
            selectedIds.map((currentId) =>
              questionIds.map((id, index) => {
                const data = historyData[currentId].questions[id];
                if (!data) return null;

                const questionText = assignQuestionText(
                  questions,
                  data[0].questionText,
                  id,
                  currentId,
                );

                return (
                  <QuestionCard
                    key={`${id}-${index}`}
                    history={data}
                    question={questionText}
                  />
                );
              }),
            )}
          {!contentToShow && (
            <Box textAlign={'center'}>
              Sorry, there is no audit history to show at this time
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

AuditHistoryDrawer.defaultValues = { selectedIds: [], questionIds: [] };

export default AuditHistoryDrawer;
