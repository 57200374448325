import { useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/pro-solid-svg-icons';
import useLeftDrawerContext from '../../LeftDrawer/context/useLeftDrawerContext';
import { RailItem } from './Styled';
import RailItemComponentTypeWrapper from './RailItemComponentTypeWrapper';
import useRailRouteMatches from '../hooks/useRailRouteMatches';

const RailItems = ({ railItems = [] }) => {
  const theme = useTheme();
  const { isKnowYourLoanMatch, isLoanSummaryMatch } = useRailRouteMatches();

  const { isNavigationVisible, hideNavigationItems, isLeftDrawerCollapsed } =
    useLeftDrawerContext();

  const handleItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    }
  };

  const showLoanSummaryTile = Boolean(!isKnowYourLoanMatch);

  return (
    <>
      {railItems?.map(
        ({ to, label, icon, active, title, isLink, onClick }, i) => (
          <RailItem
            key={`${label}-${i}`}
            aria-label={`${label}-rail-item`}
            active={active}
            isNavigationVisible={isNavigationVisible}
            isLeftDrawerCollapsed={isLeftDrawerCollapsed}
          >
            <RailItemComponentTypeWrapper
              to={to}
              label={label}
              title={title}
              isLink={isLink}
              onClick={() =>
                handleItemClick({
                  to,
                  label,
                  icon,
                  active,
                  title,
                  isLink,
                  onClick,
                })
              }
            >
              <i>
                <FontAwesomeIcon
                  icon={icon}
                  width="2rem"
                  className="rail-item-icon"
                  color={
                    active ? theme.colors?.white : theme.colors?.uwmLogo.blue
                  }
                />
              </i>
              <span style={{ lineHeight: '.8rem' }}>{label}</span>
            </RailItemComponentTypeWrapper>
          </RailItem>
        ),
      )}
      {showLoanSummaryTile && (
        <RailItem
          key="loan-summary"
          aria-label="loan-summary-rail-item"
          active={isLoanSummaryMatch}
          isNavigationVisible={isNavigationVisible}
          isLeftDrawerCollapsed={isLeftDrawerCollapsed}
          data-testid="loanSummaryRailItem"
        >
          <RailItemComponentTypeWrapper
            to=""
            label="Loan Summary"
            title="Loan Summary"
            isLink={false}
            onClick={() =>
              handleItemClick({
                label: 'Loan Summary',
                onClick: hideNavigationItems,
              })
            }
          >
            <i>
              <FontAwesomeIcon
                icon={faListCheck}
                width="2rem"
                className="rail-item-icon"
                color={
                  isLoanSummaryMatch
                    ? theme.colors?.white
                    : theme.colors?.uwmLogo.blue
                }
              />
            </i>
            <span style={{ lineHeight: '.8rem' }}>Loan Summary</span>
          </RailItemComponentTypeWrapper>
        </RailItem>
      )}
    </>
  );
};

RailItems.propTypes = {
  railItems: PropTypes.array,
};
export default RailItems;
