import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from 'common/components';
import FormulaBreakdown from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/FormulaBreakdown';
import { formatCurrency } from 'common/util/format';
import RentalCalculationReviewBreakdown from 'pages/RentalIncome/RentalIncomeCalculation/model/RentalCalculationReviewBreakdown';

const Rental1007 = ({ formulas, data, pita, hideAccordion }) => {
  const theme = useTheme();

  if (!data) {
    return null;
  }

  return (
    <Box>
      {hideAccordion && (
        <Box pl="1rem">
          <FormulaBreakdown formulas={formulas} />
        </Box>
      )}
      {!hideAccordion && (
        <Accordion defaultIndex={[0]} mt={8}>
          <AccordionItem
            background={theme.colors?.gray[50]}
            border={`1px solid ${theme.colors?.gray[200]}`}
          >
            <AccordionButton>
              <Box
                flex="1"
                textAlign="left"
                as="h3"
                fontSize="1.125rem"
                fontWeight="500"
              >
                1007/1025 Review Breakdown
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={4} mt="2px" background={theme.colors?.white}>
              <FormulaBreakdown formulas={formulas} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      <Box mt={8} bg="#FAFAFA" padding={{ sm: '20px', md: '20px' }}>
        <Text fontSize="1rem" fontWeight="500">
          1007/1025:
        </Text>
        <HStack>
          <Text fontSize="0.875rem" fontWeight="500">
            Adjusted PITIA:
          </Text>
          <Text fontSize="0.875rem" fontWeight="400">
            {pita && `${formatCurrency(pita)} to `}
            {formatCurrency(data.getAdjustedPITIA())}
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="0.875rem" fontWeight="500">
            Positive Net Rental Income:
          </Text>
          <Text fontSize="0.875rem" fontWeight="400">
            {formatCurrency(data.getPositiveNetRentalIncome())}
          </Text>
        </HStack>
      </Box>
    </Box>
  );
};

export default Rental1007;

Rental1007.propTypes = {
  data: PropTypes.instanceOf(RentalCalculationReviewBreakdown),
  pita: PropTypes.number,
  formulas: PropTypes.object,
  hideAccordion: PropTypes.bool,
};
