import PropTypes from 'prop-types';
import { Tr, Td } from 'common/components/Tables';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import styled from '@emotion/styled';
import theme from 'theme/';

const StyledTd = styled(Td)`
  padding: 0;
  padding-left: 0.75rem;
  border-left: solid 1px ${(props) => props.theme.colors?.gray[200]};
`;

export default function TableRow({
  fields,
  sx = {},
  colSpan,
  headerCols,
  tableRowSx,
  ...rest
}) {
  const { renderForm } = useFormApi();
  return (
    <Tr
      align="center"
      {...rest}
      sx={{
        '&:nth-of-type(even)': {
          background: tableRowSx?.background ?? theme?.colors?.bgGray[100],
        },
        ...tableRowSx,
      }}
    >
      {fields?.map((field, index) => (
        <StyledTd
          colSpan={colSpan ? colSpan[index] : headerCols[index].colSpan}
          key={index}
          sx={{
            paddingLeft: !rest.hasNoLeftPadding
              ? index !== 0
                ? '1.75rem !important'
                : undefined
              : sx.paddingLeft,
            borderLeft: index !== 0 ? '0px' : undefined,
            ...sx,
          }}
        >
          {field && renderForm([field])}
        </StyledTd>
      ))}
    </Tr>
  );
}

TableRow.propTypes = {
  fields: PropTypes.array,
  colSpan: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
  headerCols: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.number,
    }),
  ),
  tableRowSx: PropTypes.object,
};
