import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Th, Tr } from 'common/components/Tables';
import Flex from 'common/components/Flex';
import Link from 'common/components/Link';
import IconButton from 'common/components/Icons/IconButton';

export const StyledTableHead = styled(Th)`
  color: ${(props) =>
    props.isReadOnlyUrl
      ? props.theme?.colors?.black
      : props.theme?.colors?.white};
  border-right: 1px solid ${(props) => props.theme?.colors?.gray[200]};
  height: 3.5rem;
  line-height: 1.6rem;
  text-transform: unset;
  font-size: ${(props) => props.theme?.fontSizes?.sm};
`;
export const StyledTable = styled(Table)`
  border-bottom: 1px solid ${(props) => props.theme?.colors?.gray[200]};
  border-left: 1px solid ${(props) => props.theme?.colors?.gray[200]};
  border-radius: 30px;
  position: relative;
`;

export const StyledTr = styled(Tr)`
  padding: 'none';
  height: 3.5rem;
  ${(props) =>
    !props.disableRowClick &&
    `
    &:hover {
      background-color: ${(props) => props.theme?.colors?.blue[50]};
      transform: scale(1.005);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
  `}
`;

export const StyledDeleteMultipleBtnGroup = styled(Flex)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  display: ${({ display }) => (display ? display : 'grid')};
  place-items: ${({ placeItems }) => (placeItems ? placeItems : 'center')};
  color: ${({ color, theme }) => (color ? color : theme.colors?.blue[300])};
  text-decoration: underline;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '15%')};
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  transition: all 0.2s ease;
  color: ${(props) => props.theme.colors?.blue[600]};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors?.blue[400]};
  }
`;

export const StyledIconButton = styled(IconButton)`
  background-color: ${(props) =>
    props.active ? props.theme.colors?.blue[600] : 'transparent'};
  color: ${(props) =>
    props.active ? props.theme.colors?.white : props.theme.colors?.blue[600]};
    
    &:focus {
      box-shadow: none;
    }
    &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.colors?.blue[400]
        : props.theme.colors?.blue[100]};
`;
