import PropTypes from 'prop-types';
import FormLabel from 'common/components/Forms/FormLabel';
import Text from 'common/components/Texts/Text';
import { StyledXMarkIcon as CloseIcon } from './StyledSelectElements';

import {
  StyledAutocomplete,
  StyledChip,
  StyledOption,
  StyledTextField,
} from './StyledSelectElements';
import { DROPDOWN_LABEL } from 'pages/ClassifyDocumentsV2/constants';

const defaultPlaceHolder = (isMultiple, placeholder) => {
  if (placeholder && placeholder !== '') {
    return placeholder;
  }
  return isMultiple ? 'Select one or more' : 'Select';
};

export default function MultiSelect({
  value,
  label,
  isLabelHidden = false,
  isLabelMultiple = false,
  isMultiple = false,
  id,
  options,
  errors,
  helperText,
  clearErrors,
  placeholder = '',
  width = '100%',
  ...props
}) {
  const isYAML = errors === true || errors === false;

  return (
    <>
      {!isLabelHidden && (
        <FormLabel htmlFor={id}>
          <Text fontSize="sm">
            {isLabelMultiple
              ? `Select ${DROPDOWN_LABEL[label]} Record${
                  isMultiple ? '(s)' : ''
                }`
              : label}
          </Text>
        </FormLabel>
      )}
      <StyledAutocomplete
        id={id}
        width={width}
        multiple={isMultiple}
        options={options}
        value={value || (isMultiple ? [] : null)} // Ensure value is an array or null for multiple select
        {...(isLabelHidden ? { 'aria-label': label } : {})}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            if (!option) return null;
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <StyledChip
                variant="outlined"
                label={option.label}
                deleteIcon={<CloseIcon />}
                key={key}
                {...tagProps}
              />
            );
          });
        }}
        renderOption={(props, option) => (
          <StyledOption {...props}>{option.label}</StyledOption>
        )}
        renderInput={(params) => {
          return (
            <StyledTextField
              {...params}
              error={
                typeof errors === 'object' && errors !== null
                  ? !!errors[id]
                  : !!errors
              }
              helperText={isYAML && errors ? helperText : errors[id]?.message}
              placeholder={
                value &&
                (typeof value === 'string' ||
                  (Array.isArray(value) && value.length > 0))
                  ? ''
                  : defaultPlaceHolder(isMultiple, placeholder)
              }
              onBlur={() => {
                if (value && typeof clearErrors === 'function') {
                  clearErrors([id]);
                }
              }}
            />
          );
        }}
        {...props}
      />
    </>
  );
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
  isLabelHidden: PropTypes.bool,
  isLabelMultiple: PropTypes.bool,
  id: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  helperText: PropTypes.string,
  clearErrors: PropTypes.func,
  placeholder: PropTypes.string,
};
