// 3rd party imports
import { useDispatch, useSelector } from 'react-redux';
import Link from 'common/components/Link';
import Box from 'common/components/Box';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

// local imports
import ActivityPanel from './ActivityPanel';
import AuditHistoryDrawer from 'common/components/AuditHistoryDrawer';
import { HomeActions } from './redux';
import { USER_ROLE } from 'common/constants';
import lang from './language.json';

const StyledHomeMain = styled.div`
  ${(props) =>
    !props.featureFlag &&
    css`
      @media (min-width: 1441px) {
        display: flex;
        justify-content: center;
      }
    `}
`;

const BailOutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 94%;
    margin: auto 3%;
  }
`;

const StyledBailOutLink = styled(Link)`
  margin-top: 32px;
  margin-bottom: 64px;
  color: ${(props) => props.theme.colors?.blue[600]};
  font-size: ${(props) => props.theme.functions?.pixelsToRem(14)};
  line-height: ${(props) => props.theme.functions?.pixelsToRem(24)};
  border-bottom: 1px solid ${(props) => props.theme.colors?.blue[600]};
  opacity: ${(props) => (props.disabled ? 0.5 : 'none')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    text-decoration: none;
    font-weight: 500;
  }
`;

const HomeContainer = () => {
  // redux
  const dispatch = useDispatch();
  const {
    boltStatus,
    selectedCategory,
    navigationItems,
    user: { isUnderwriter, role },
  } = useSelector(({ home }) => home);

  const isCategoryReadOnlyPermission = navigationItems?.categories?.find(
    (category) => category?.navigationCategoryId === selectedCategory,
  )?.isReadOnlyPermission;

  const isBailOutLinkDisabled =
    boltStatus?.value ||
    role === USER_ROLE.ACCOUNT_EXECUTIVE ||
    isCategoryReadOnlyPermission;

  // event handlers
  const handleBailOut = () =>
    !isBailOutLinkDisabled && dispatch(HomeActions.setIsBailOut(true));

  return (
    <>
      <StyledHomeMain>
        <AuditHistoryDrawer />
        <Box w={'100%'}>
          {/* When the selectedCategory is Classify render Classify Docs, else render Activity Panel for other categories. */}

          <ActivityPanel />

          <BailOutContainer>
            {!isUnderwriter && (
              <StyledBailOutLink
                role="button"
                onClick={() => handleBailOut()}
                disabled={isBailOutLinkDisabled}
                aria-disabled={isBailOutLinkDisabled}
              >
                {lang.BAIL_OUT_LINK}
              </StyledBailOutLink>
            )}
          </BailOutContainer>
        </Box>
      </StyledHomeMain>
    </>
  );
};

export default HomeContainer;
