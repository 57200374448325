// 3rd party imports
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  Box,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from 'common/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';

// local imports
import { ReactComponent as UWMLogo } from 'common/assets/bolt-power-by-UWM.svg';
import { EASE_NAV_LINKS } from './constants';
import LoanSummary from './LoanSummary';
import ImpersonateMenu from 'pages/Home/ImpersonateMenu';
import { HomeThunks } from './redux';
import { API_STATUS } from 'common/constants';

const StyledHeader = styled.header`
  width: 100%;
  @media (max-width: 1024px) {
    min-width: 1272px;
  }
  @media (min-width: 1441px) {
    min-width: 1272px;
  }
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors?.white};
  border-bottom: ${(props) => `1px solid ${props.theme.colors?.gray[200]}`};
`;

const StyledContent = styled.div`
  width: 1272px;
  padding-left: ${(props) => (props.featureFlag ? '1rem' : '0')};
  .uwm-logo {
    height: 82px;
    margin-left: -17px;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;

    .uwm-logo {
      margin-left: -20px;
    }
  }
  margin-top: 4px;
  padding-bottom: 4px;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors?.white};
`;

const TopNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 170px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 7px;
  margin-right: 7px;
`;

const HiddenWalkMeElement = styled.input``;

const Header = () => {
  const {
    home: {
      user: { email, isUnderwriter },
      loanSummary,
    },
    url: {
      obfuscatedLoanIdentifier: {
        obfuscatedLenderDatabaseId,
        obfuscatedLoanRecordId,
      },
    },
  } = useSelector((state) => state);

  const [isActive, setActive] = useState(false);
  const [showLoanSummary, setShowLoanSummary] = useState(false);
  const pipelineUrl = `${window.globalConfig.ease.baseUrl}/PipeLine`;
  const easeBaseUrl = `${window.globalConfig.ease.baseUrl}/Loan/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}`;
  const status = loanSummary?.status;
  const error = loanSummary?.error;

  const handleToggleMenu = () => {
    setActive(!isActive);
  };

  const toggleLoanSummary = () => {
    setShowLoanSummary(!showLoanSummary);
  };

  //get loan summary on page load
  const dispatch = useDispatch();
  useEffect(() => {
    if (obfuscatedLenderDatabaseId && obfuscatedLoanRecordId) {
      dispatch(HomeThunks.getLoanSummary());
    }
  }, [obfuscatedLenderDatabaseId, obfuscatedLoanRecordId, dispatch]);

  return (
    <>
      <StyledHeader id="boltHeader">
        <StyledContent>
          <TopNavContainer>
            <LogoContainer>
              <h1>
                <UWMLogo
                  role="img"
                  className="uwm-logo"
                  aria-label="BOLT powered by UWM"
                />
              </h1>

              {status !== API_STATUS.GETTING && !error && (
                <Box>
                  <Text fontWeight="500" color="gray.900" lineHeight="1.2rem">
                    {loanSummary?.value?.loanNumber}
                  </Text>
                  <Text fontWeight="500" color="gray.900" lineHeight="1.2rem">
                    {`${loanSummary?.value?.primaryBorrowerFirstName} 
                ${loanSummary?.value?.primaryBorrowerLastName}`}
                  </Text>
                </Box>
              )}
            </LogoContainer>

            <Box display="flex" alignItems="baseline" mt="2px">
              {!isUnderwriter && (
                <Button
                  display="inline"
                  as={Link}
                  href="https://uwm.az1.qualtrics.com/jfe/form/SV_5yFqAZwivqtDkLY"
                  mr="20px"
                  backgroundColor="white"
                  color="blue.600"
                  fontWeight="500"
                  lineHeight="24px"
                  isExternal
                  _hover={{
                    textDecoration: 'none',
                    background: 'white',
                    borderRadius: '7px',
                    border: 'none',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    textDecoration: 'none',
                  }}
                  height="unset"
                >
                  <StyledFontAwesomeIcon icon={faComment} />
                  Submit Feedback
                </Button>
              )}
              <Menu onClose={handleToggleMenu} display="inline">
                <MenuButton
                  color="blue.600"
                  fontWeight="500"
                  onClick={handleToggleMenu}
                  aria-label="back to ease"
                  mr="20px"
                  lineHeight="24px"
                  display="flex"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    Back to EASE
                    {!isActive ? (
                      <StyledFontAwesomeIcon
                        data-testid="fa-chevron-down-is-active"
                        icon={faChevronDown}
                      />
                    ) : (
                      <StyledFontAwesomeIcon
                        data-testid="fa-chevron-up-is-active"
                        icon={faChevronUp}
                      />
                    )}
                  </Box>
                </MenuButton>
                <MenuList paddingLeft="0.5rem" paddingRight="0.5rem">
                  {EASE_NAV_LINKS.map(({ linkName, link }) => (
                    <Link
                      px="12px"
                      py="6px"
                      display="flex"
                      href={easeBaseUrl + link}
                      key={link}
                      _hover={{
                        textDecoration: 'none',
                        background: 'gray.200',
                        borderRadius: '7px',
                        border: 'none',
                      }}
                      _focus={{
                        boxShadow: 'none',
                      }}
                    >
                      {linkName}
                    </Link>
                  ))}
                  <Link
                    px="12px"
                    py="6px"
                    display="flex"
                    as={Link}
                    href={pipelineUrl}
                    _hover={{
                      textDecoration: 'none',
                      background: 'gray.200',
                      borderRadius: '7px',
                      border: 'none',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    aria-label="your pipeline"
                  >
                    Your Pipeline
                  </Link>
                </MenuList>
              </Menu>
              <Button
                onClose={toggleLoanSummary}
                color="blue.600"
                fontWeight="500"
                onClick={toggleLoanSummary}
                backgroundColor="white"
                lineHeight="1.5rem"
                _hover={{
                  textDecoration: 'none',
                  border: 'none',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  background: 'white',
                }}
                height="24px"
                aria-controls="loanSummary"
                aria-expanded={showLoanSummary}
              >
                View Loan Summary
                {!showLoanSummary ? (
                  <StyledFontAwesomeIcon
                    data-testid="fa-chevron-down-show-loan-summary"
                    icon={faChevronDown}
                  />
                ) : (
                  <StyledFontAwesomeIcon
                    data-testid="fa-chevron-up-show-loan-summary"
                    icon={faChevronUp}
                  />
                )}
              </Button>
              <ImpersonateMenu />
            </Box>
            <HiddenWalkMeElement
              id="userEmail"
              value={email}
              type="hidden"
            ></HiddenWalkMeElement>
          </TopNavContainer>
        </StyledContent>
      </StyledHeader>
      {showLoanSummary && <LoanSummary toggleLoanSummary={toggleLoanSummary} />}
    </>
  );
};

export default Header;
