// 3rd party imports
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import Modal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalBody from 'common/components/Modals/ModalBody';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import Link from 'common/components/Link';
import Button from 'common/components/Buttons/Button/index';
import Center from 'common/components/Center';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { usePDF } from '@react-pdf/renderer';
import styled from '@emotion/styled';

// local imports
import { HomeActions, HomeThunks } from './redux';
import { API_STATUS, RULE_ID } from 'common/constants';
import BoltApproval from 'pages/BoltApproval';
import AlertOkModal from 'common/components/Modals/Modal/AlertOkModal';
import PdfCreator from '../BoltApproval/PdfCreator';
import { formatApprovalPdf } from '../../common/util/format';

const StyledCheckCircle = styled(FontAwesomeIcon)`
  font-size: 32px;
  color: ${(props) => props.theme.colors?.green[600]};
  margin-bottom: 8px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors?.blue[600]};
  text-decoration: underline;
  font-size: ${(props) => props.theme.functions?.pixelsToRem(14)};
  &:hover {
    color: ${(props) => props.theme.colors?.blue[500]};
  }
`;

const FinalModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  // redux
  const {
    isLastAlert,
    allRequiredRulesCompleted,
    selectedAlert,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    submitToSetup,
    boltApproval: { approvalData },
  } = useSelector(
    ({ home, boltApproval, url: { obfuscatedLoanIdentifier } }) => ({
      ...home,
      ...obfuscatedLoanIdentifier,
      boltApproval,
    }),
  );
  const easeUrl = `${window.globalConfig.ease.baseUrl}/Loan/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/LoanApplication`;
  const dispatch = useDispatch();
  const [pdfInstance, setPdfInstance] = usePDF({
    document: <PdfCreator data={[approvalData]} />,
  });

  useEffect(() => setPdfInstance, [setPdfInstance, approvalData]);

  // handlers
  const handleOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    dispatch(HomeActions.setIsLastAlert(false));
    onClose();
  }, [onClose, dispatch]);

  async function handleAlertOk(isError) {
    if (isError) {
      handleClose();
      return;
    }

    await dispatch(
      HomeThunks.submitLoanToSetup(await formatApprovalPdf(pdfInstance), true),
    );
  }

  useEffect(() => {
    isLastAlert && allRequiredRulesCompleted && handleOpen();
  }, [isLastAlert, handleOpen, allRequiredRulesCompleted]);

  if (submitToSetup.data) {
    return (
      <AlertOkModal
        isOpen={isOpen}
        handleOk={() => handleAlertOk(!!submitToSetup.error)}
        handleClose={handleClose}
        loading={submitToSetup.status === API_STATUS.UPDATING}
        data={submitToSetup.data}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent marginTop={350}>
        <ModalCloseButton />
        {/*for underwriters there is no BOLT approval tab*/}
        {selectedAlert?.ruleId !== RULE_ID.FINAL_CHECKS_RULE ? (
          <>
            <ModalBody mt="24px">
              <Text as="h3" textAlign="center" mb="16px">
                You have reached the last alert!
              </Text>
              <Text
                as="p"
                fontSize="14px"
                textAlign="center"
                lineHeight="1.5rem"
                fontWeight="200"
              >
                If all of your tabs and alerts have a green shield icon, the
                BOLT process is complete.
              </Text>
            </ModalBody>
            <ModalFooter display="flex" justifyContent="center" pb="2rem">
              <Button colorScheme="blue" onClick={handleClose}>
                OK
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody mt="24px">
              <Center>
                <StyledCheckCircle icon={faCheckCircle} />
              </Center>
              <Text as="h3" textAlign="center" mb="16px">
                Congratulations!
              </Text>
              <Text
                as="p"
                fontSize="14px"
                textAlign="center"
                lineHeight="1.5rem"
              >
                Your loan has been BOLT approved and has been sent to
                underwriting for validation.
              </Text>
            </ModalBody>
            <ModalFooter display="flex" pb="1.5rem" flexDirection="column">
              <BoltApproval />
              <StyledLink href={easeUrl} mt="1rem">
                Back to EASE
              </StyledLink>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default FinalModal;
