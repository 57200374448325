import { sortBy } from 'lodash';
import { createBlobUrl } from 'common/util/pdfUtils';

import actions from './actions';
import apiClient, { typedApiClient } from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';
import fpKeyBy from 'lodash/fp/keyBy';
import { GENERIC_ERROR_MESSAGE } from 'common/constants';

const getNavigationDetails = (getNextAlert) => async (dispatch, getState) => {
  dispatch(actions.getNavigationDetailsStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const { data } = await typedApiClient.v1.loanGetNavigationDetails(
      obfuscatedLoanIdentifier,
    );

    const allAlerts = sortBy(
      data?.topNavigationItems
        ?.flatMap((category) => category?.navigationGutterItems)
        ?.flatMap((gutterItem) =>
          gutterItem?.gutterItemChildren?.length > 0
            ? gutterItem?.gutterItemChildren
            : gutterItem,
        ),
      ['parentRuleSortOrder'],
    );

    dispatch(
      actions.getNavigationDetailsSuccess({
        categories: data?.topNavigationItems,
        allAlerts,
        allRequiredRulesCompleted: data?.allRequiredRulesCompleted,
      }),
    );

    const state = getState();
    if (getNextAlert && state?.home?.shouldGetNextAlert) {
      dispatch(actions.setShouldGetNextAlert(false));
      dispatch(actions.nextAlert());
    }
  } catch (error) {
    dispatch(actions.getNavigationDetailsError());
  }
};
const triggerAllRules = () => async (dispatch, getState) => {
  dispatch(actions.triggerAllRulesStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const response = await apiClient.post(apiEndpoints.core.TRIGGER_ALL_RULES, {
      obfuscatedLoanIdentifier,
    });
    dispatch(actions.triggerAllRulesSuccess(response.data));
  } catch {
    dispatch(actions.triggerAllRulesError());
  }
};

const submitLoanToSetup =
  (base64BoltApprovalPdf, confirmed = false) =>
  async (dispatch, getState) => {
    dispatch(actions.submitLoanToSetupStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    let response;
    try {
      response = await apiClient.post(apiEndpoints.core.SUBMIT_LOAN_TO_SETUP, {
        obfuscatedLoanIdentifier,
        confirmed,
      });
      dispatch(actions.submitLoanToSetupSuccess(response.data));
    } catch {
      dispatch(actions.submitLoanToSetupError());
      return;
    }

    // upload bolt approval letter to dochub after user submits to setup
    // But only in the loan was actually submitted
    if (response.status === 204) {
      try {
        await apiClient.post(
          apiEndpoints.classifyDocuments.UPLOAD_BOLT_APPROVAL_LETTER,
          {
            obfuscatedLoanIdentifier,
            base64: base64BoltApprovalPdf,
          },
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    return response.data;
  };

const getBoltStatus = () => async (dispatch, getState) => {
  dispatch(actions.getBoltStatusStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const response = await apiClient.post(apiEndpoints.core.GET_BOLT_STATUS, {
      obfuscatedLoanIdentifier,
    });
    dispatch(actions.getBoltStatusSuccess(response?.data));
  } catch (error) {
    dispatch(actions.getBoltStatusError());
  }
};

const getUnderwriterVerifications = () => async (dispatch, getState) => {
  dispatch(actions.getUnderwriterVerificationStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const { data } = await apiClient.post(
      apiEndpoints.GET_UNDERWRITER_VERIFICATION_STATUS,
      obfuscatedLoanIdentifier,
    );
    const keyedData = fpKeyBy('ruleResultID', data);
    for (const key in keyedData)
      keyedData[key].FEVerified = keyedData[key].isUnderwriterVerified;
    dispatch(actions.getUnderwriterVerificationSuccess(keyedData));
  } catch (error) {
    dispatch(actions.getUnderwriterVerificationError(error));
  }
};

const saveUnderwriterVerification =
  (isVerified, selectedAlert) => async (dispatch, getState) => {
    dispatch(actions.saveUnderwriterVerificationStarted());
    const {
      url: { obfuscatedLoanIdentifier },
      home: {
        selectedAlert: { ruleResultId: selectedRuleId },
        selectedAlertParent,
      },
    } = getState();
    const parentRuleId = selectedAlertParent?.ruleResultId;
    try {
      const ruleId =
        selectedAlert?.ruleResultId || selectedRuleId || parentRuleId;

      await typedApiClient.v1.underwriterVerifiedSaveUnderwriterVerified({
        obfuscatedLoanIdentifier,
        ruleResultId: ruleId,
        isUnderwriterVerified: isVerified,
      });

      dispatch(
        actions.saveUnderwriterVerificationSuccess({
          ruleResultID: ruleId,
          isUnderwriterVerified: isVerified,
        }),
      );
    } catch (error) {
      dispatch(actions.saveUnderwriterVerificationError(error));
    }
  };

const getLoanSummary = () => async (dispatch, getState) => {
  dispatch(actions.getLoanSummaryStarted());

  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();

  let retries = 0;

  while (retries < 3) {
    try {
      const response = await apiClient.post(
        apiEndpoints.core.GET_LOAN_SUMMARY,
        obfuscatedLoanIdentifier,
      );
      dispatch(actions.getLoanSummarySuccess(response.data));
      return true;
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      retries++;
    }
  }
  dispatch(actions.getLoanSummaryError());
  return false;
};

/**
 * Bail out and submit the loan to the queue
 * @param confirmed Whether or not this is a second pass (i.e. agreeing to a confirmation modal that appears based on loan state)
 */
const bailOutAndSubmitToSetup =
  (confirmed = false) =>
  async (dispatch, getState) => {
    dispatch(actions.bailOutAndSubmitToSetupStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    try {
      const response = await apiClient.post(
        apiEndpoints.core.BAIL_OUT_AND_SUBMIT_TO_SETUP,
        { obfuscatedLoanIdentifier, confirmed },
      );
      dispatch(actions.bailOutAndSubmitToSetupSuccess(response.data));
      return response.data || true;
    } catch (error) {
      dispatch(actions.bailOutAndSubmitToSetupError(error.response?.data));
      return false;
    }
  };

const retrievePdf = (documentId) => async (dispatch, getState) => {
  const {
    url: {
      obfuscatedLoanIdentifier: {
        obfuscatedLenderDatabaseId,
        obfuscatedLoanRecordId,
      },
    },
  } = getState();
  try {
    dispatch(actions.retrievePdfStarted());
    const pdfEndpoint = `${apiEndpoints.classifyDocuments.GET_PDF}/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/${documentId}`;
    const { data: pdfResponse } = await apiClient.get(pdfEndpoint, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    });
    const blobUrl = await createBlobUrl(pdfResponse);
    dispatch(actions.retrievePdfSuccess(blobUrl));
  } catch (error) {
    dispatch(actions.retrievePdfError(GENERIC_ERROR_MESSAGE));
  }
};

const clearPdf = () => async (dispatch) => {
  dispatch(actions.retrievePdfClear());
};

export default {
  getNavigationDetails,
  triggerAllRules,
  submitLoanToSetup,
  getBoltStatus,
  getUnderwriterVerifications,
  saveUnderwriterVerification,
  getLoanSummary,
  bailOutAndSubmitToSetup,
  retrievePdf,
  clearPdf,
};
