import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import { concatenateObjectAttributes } from 'common/components/CrudGrid/crudGridUtils';

import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import Loan1003FormContainer from 'pages/Loan1003DataGrids/common/components/Loan1003FormContainer';
import Loan1003FormSection from 'pages/Loan1003DataGrids/common/components/Loan1003FormSection';
import FormBuilder from 'pages/Loan1003DataGrids/common/components/FormBuilder';
import { US_STATES_OPTIONS } from 'common/constants';
import { COUNTRIES } from 'common/constants/countries';
import {
  REO_PROPERTY_TYPE,
  REO_PROPERTY_STATUS,
  REO_INTENDED_OCCUPANCY_TYPE,
  NON_SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY,
  NON_SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY,
  SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY,
  SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY,
  SUBJECT_PROPERTY_PROPERTY_STATUS_OPTIONS,
  ESCROWED_ON_PROPERTY_OPTIONS,
  REO_GROSS_RENTAL_INCOME_PROPERTY_TYPE,
} from './constants';
import language from './../language.json';
import theme from 'theme';
const getDefaultValues = (rowToEdit) => {
  return {
    customerIdentifiers: rowToEdit.associatedBorrowers.map(
      (identifiers) => identifiers.customerRecordId,
    ),
    addressLine1: rowToEdit.addressLine1,
    unit: rowToEdit.unit,
    city: rowToEdit.city,
    state: rowToEdit.state,
    country: rowToEdit.country,
    zipCode: rowToEdit.zipCode,
    propertyStatus: rowToEdit.propertyStatus,
    propertyType: rowToEdit.propertyTypeId,
    subjectProperty: rowToEdit.isSubjectProperty,
    marketValue: rowToEdit.marketValue,
    occupancyType: rowToEdit.occupancyType,
    mortgagePayment: rowToEdit.mortgagePayment ?? +rowToEdit.mortgagePayment,
    netRent: rowToEdit.netRentalIncome ?? +rowToEdit.netRentalIncome,
    associatedLiabilities: rowToEdit.associatedLiabilities.map(
      (liabilities) =>
        liabilities.creditorName + ' ' + liabilities.accountNumber,
    ),
    mortgagePaymentIncludesTaxesAndInsurance: true,
    mortgageBalance: rowToEdit.mortgageBalance,
    escrowedOnProperty: rowToEdit.escrowedOnProperty,
    monthlyPropertyTaxes: rowToEdit.monthlyPropertyTaxes,
    monthlyHazardInsurance: rowToEdit.monthlyHazardInsurance,
    monthlyFloodInsurance: rowToEdit.monthlyFloodInsurance,

    monthlyAssociationDues: rowToEdit.monthlyAssociationDues,

    grossRentalIncome: rowToEdit.grossRentalIncome,
    hasGrossRentalIncomeChanged: false,
    leaseGroundRents: rowToEdit.leaseGroundRents,
    totalMonthlyExpenses: rowToEdit.totalMonthlyExpenses,
    netRentalIncome: rowToEdit.netRentalIncome,
  };
};

export default function RealEstateOwnedSummariesForm({
  rowToEdit,
  onClose,
  onSave,
  allBorrowerOptions,
  isWriteAccessDisabled,
  handleToastMessage,
  obfuscatedLoanIdentifier,
  dropdownOptions,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [currentPropertyTypeId, setCurrentPropertyTypeId] = useState(null);
  const [prevPropertyTypeId, setPrevPropertyTypeId] = useState(null);
  const [occupancyTypeOptions, setOccupancyTypeOptions] = useState([]);
  const [isUS, setIsUS] = useState(false);
  const {
    register,
    trigger,
    reset,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: getDefaultValues(rowToEdit),
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const isSubjectProperty = rowToEdit.isSubjectProperty;
  const isAssociatedLiabilitiesVisible =
    rowToEdit.associatedLiabilities.length > 0;

  const creditorNames = rowToEdit.associatedLiabilities.reduce(
    (acc, { creditorName, accountNumber }) => {
      if (creditorName || accountNumber) {
        acc +=
          (acc ? ', ' : '') +
          [creditorName, accountNumber].filter(Boolean).join(' ');
      }
      return acc;
    },
    '',
  );

  // Form register for validation
  register('customerIdentifiers', {
    validate: (value) =>
      value.length > 0 ? true : language.LIABILITY_ASSOCIATED_ERROR,
  });
  register('subjectProperty');
  register('mortgagePayment');
  register('netRentalIncome');
  register('addressId');
  register('addressLine1', { validate: (value) => value && value.length > 0 });
  register('unit');
  register('city');
  register('state');
  register('country');
  register('zipCode');
  register('propertyType', {
    validate: (value) => Object.values(REO_PROPERTY_TYPE).includes(value),
  });
  register('propertyStatus', {
    validate: (value) => Object.values(REO_PROPERTY_STATUS).includes(value),
  });

  register('occupancyType', {
    validate: (value) =>
      Object.values(REO_INTENDED_OCCUPANCY_TYPE).includes(value),
  });
  register('marketValue');
  register('mortgagePaymentIncludesTaxesAndInsurance');
  register('mortgageBalance');
  register('escrowedOnProperty'); // Not sure if this is correct**** need to check
  register('monthlyPropertyTaxes');
  register('monthlyHazardInsurance');
  register('monthlyFloodInsurance');
  register('monthlyAssociationDues');
  register('grossRentalIncome');
  register('hasGrossRentalIncomeChanged');
  register('leaseGroundRents');
  register('totalMonthlyExpenses');
  register('netRentalIncome');

  // *** Still need datapoint for this functionality using escrowedOnProperty for now ***
  const isEscrowedOnPropertyVisible = rowToEdit.escrowedOnProperty > 0;
  const escrowedOnPropertyText = ESCROWED_ON_PROPERTY_OPTIONS.find(
    (option) => option.value === rowToEdit.escrowedOnProperty,
  )?.text;

  const setOccupancyTypeOptionsBasedOnProperty = useCallback(
    (propertyType, propertyStatus) => {
      if (
        propertyType !== REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING &&
        !isSubjectProperty
      ) {
        setOccupancyTypeOptions(
          NON_SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY[propertyStatus],
        );
      }
      if (
        propertyType === REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING &&
        !isSubjectProperty
      ) {
        setOccupancyTypeOptions(
          NON_SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY[propertyStatus],
        );
      }
      if (
        propertyType !== REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING &&
        isSubjectProperty
      ) {
        setOccupancyTypeOptions(
          SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY[propertyStatus],
        );
      }
      if (
        propertyType === REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING &&
        isSubjectProperty
      ) {
        setOccupancyTypeOptions(
          SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY[propertyStatus],
        );
      }
    },
    [isSubjectProperty],
  );

  const handleChange = (field, value) => {
    setValue(field, value);
    if (field === 'propertyType') {
      setPrevPropertyTypeId(currentPropertyTypeId);
      setCurrentPropertyTypeId(value);
    }
    const countryChanged = value === 'US';
    const parseNumber = (value) => parseFloat((value || '').replace(/,/g, ''));

    function parseAndSetValue(fieldName) {
      const parsedValue = parseNumber(getValues(fieldName));
      setValue(fieldName, parsedValue);
    }
    switch (field) {
      case 'country':
        setIsUS(countryChanged);
        if (!countryChanged) {
          setValue('state', '');
          setValue('zipCode', '');
        }
        break;
      case 'grossRentalIncome': {
        const grossRentalIncomeNum = parseNumber(
          getValues('grossRentalIncome'),
        );
        setValue('grossRentalIncome', grossRentalIncomeNum);
        setValue(
          'hasGrossRentalIncomeChanged',
          rowToEdit.grossRentalIncome !== grossRentalIncomeNum,
        );
        break;
      }
      case 'leaseGroundRents':
      case 'marketValue':
      case 'monthlyAssociationDues':
      case 'monthlyFloodInsurance':
        parseAndSetValue(field);
        break;
      case 'propertyStatus':
        setValue('occupancyType', '');
        setValue('grossRentalIncome', '');
        setOccupancyTypeOptionsBasedOnProperty(
          getValues('propertyType'),
          value,
        );

        if (
          value === REO_PROPERTY_STATUS.RENTAL &&
          rowToEdit.grossRentalIncome !== ''
        ) {
          setValue('grossRentalIncome', rowToEdit.grossRentalIncome);
          setValue('hasGrossRentalIncomeChanged', false);
        } else {
          setValue('grossRentalIncome', 0);
          setValue('hasGrossRentalIncomeChanged', true);
        }
        break;
      case 'propertyType':
        if (value === REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING) {
          setValue('propertyStatus', '');
          setOccupancyTypeOptionsBasedOnProperty(null, null);
        }
        setValue(field, value);
        break;
      default:
        setValue(field, value);
        break;
    }
    trigger();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const saveObject = {
      obfuscatedLoanIdentifier,
      customerRecordIds: data.customerIdentifiers.map((customerIdentifier) => ({
        obfuscatedLenderDatabaseId:
          obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
        customerRecordId: customerIdentifier,
      })),
      addressIdentifier:
        rowToEdit.addressId === 0
          ? { addressId: 0, addressLdId: 0 }
          : rowToEdit.addressId,
      propertyAddress: {
        addressLine1: data.addressLine1,
        unit: data.unit,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        country: data.country,
      },
      propertyTypeId: data.propertyType,
      occupancyTypeId: data.occupancyType,
      propertyStatusId: data.propertyStatus,
      presentvalue: data.marketValue,
      monthlyFloodInsurance: data.monthlyFloodInsurance,
      monthlyAssociationDues: data.monthlyAssociationDues,
      grossRentalIncome: data.grossRentalIncome,
      hasGrossRentalIncomeChanged: data.hasGrossRentalIncomeChanged,
      monthlyGroundRent: data.leaseGroundRents,
    };
    apiClient
      .post(
        apiEndPoints.underwritingSummariesRealEstateOwned
          .UPSERT_REALESTATEOWNED_SUMMARY_DATA,
        saveObject,
      )
      .then((res) => {
        if (res) {
          handleToastMessage({
            title: 'Success',
            description: 'Saved Successfully',
            status: 'success',
            duration: 5000,
            isClosable: false,
          });
          setIsLoading(false);
          onSave();
          dispatch(setLoanSummaryUpsertOrDelete(true));
        }
      })
      .catch(() => {
        handleToastMessage({
          title: 'Save Failed',
          description: 'Record was not able to be saved.',
          status: 'error',
          duration: 5000,
          isClosable: false,
        });
        setIsLoading(false);
      });
  };

  const handleBorrowerChange = (value) => {
    const customerIdentifiers = getValues('customerIdentifiers');
    const index = customerIdentifiers.indexOf(value);

    if (index !== -1) {
      // Value is already in the array, remove it
      customerIdentifiers.splice(index, 1);
    } else {
      // Value is not in the array, add it
      customerIdentifiers.push(value);
    }
    handleChange('customerIdentifiers', customerIdentifiers);
  };

  const propertyTypeCheck = watch('propertyType');
  const propertyStatusCheck = watch('propertyStatus');

  // Determine if monthlyAssociationDues should be enabled
  const isMonthlyAssociationDuesEditable = Object.values(
    REO_GROSS_RENTAL_INCOME_PROPERTY_TYPE,
  ).includes(parseInt(propertyTypeCheck));

  // Determine if grossRentalIncome should be enabled
  const isGrossRentalIncomeEnabled = propertyStatusCheck === 5;

  // this use effect is for flipping between different reos at the top table
  useEffect(() => {
    reset(getDefaultValues(rowToEdit));

    // Logic to get country value or set country to US as default if country is empty
    setIsUS(rowToEdit.country === 'US');

    // Needed to allow non-disabled fields to be editable
    watch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit, reset]);

  // This useEffect is for resetting the occupancyType and propertyStatus when the propertyTypeId changes
  useEffect(() => {
    watch('propertyType');
    if (rowToEdit.propertyTypeId === '' && getValues('propertyType') === '') {
      setPrevPropertyTypeId(null);
      setCurrentPropertyTypeId(null);
      setOccupancyTypeOptionsBasedOnProperty(null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit, currentPropertyTypeId, prevPropertyTypeId]);

  // This useEffect is for resetting the occupancyType and propertyStatus when the propertyTypeId changes to/from 2-4 unit dwelling
  useEffect(() => {
    setCurrentPropertyTypeId(getValues('propertyType'));
    if (
      prevPropertyTypeId === REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING &&
      currentPropertyTypeId !== REO_PROPERTY_TYPE.TWO_TO_4_UNIT_DWELLING
    ) {
      setValue('propertyStatus', '');
      setOccupancyTypeOptionsBasedOnProperty(null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyTypeId, prevPropertyTypeId]);

  // This sets the occupancyType on initial load of each rowToEdit
  useEffect(() => {
    // These watch(s) are needed to allow the fields to be be editable otherwise setValue will not work (BOLT-19426)
    watch('occupancyType');
    watch('propertyStatus');
    watch('propertyType');
    setOccupancyTypeOptionsBasedOnProperty(
      rowToEdit.propertyTypeId,
      rowToEdit.propertyStatus,
    );
    if (rowToEdit.occupancyType === null) {
      setValue('occupancyType', '');
    } else {
      setValue('occupancyType', rowToEdit.occupancyType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit]);

  const reoInfoSection = [
    {
      type: 'checkboxGroup',
      name: 'customerIdentifiers',
      label: 'Associated Borrower(s)',
      value: 'customerIdentifiers',
      dataTestId: 'customerIdentifiers',
      options: allBorrowerOptions,
      isRequired: true,
      span: 4,
      isChecked: (option) =>
        getValues('customerIdentifiers').includes(option.customerRecordId),
      displayValue: (option) =>
        concatenateObjectAttributes(option, ['firstName', 'lastName']),
      onChangeHandler: (option) =>
        handleBorrowerChange(option.customerRecordId),
      disabled: isSubjectProperty,
      readOnly: isSubjectProperty,
    },
    {
      type: 'text',
      name: 'addressLine1',
      label: 'Address Line 1',
      value: 'addressLine1',
      placeholder: 'Address Line 1',
      dataTestId: 'addressLine1',
      isRequired: true,
      readOnly: isSubjectProperty,
      disabled: isSubjectProperty,
      span: 2,
      onChangeHandler: (e) => handleChange('addressLine1', e.target.value),
    },
    {
      type: 'text',
      name: 'unit',
      label: 'Unit #',
      value: 'unit',
      isRequired: false,
      dataTestId: 'unitNumber',
      readOnly: isSubjectProperty,
      disabled: isSubjectProperty,
      errors: errors,
      placeholder: 'Unit Number',
      span: 1,
      onChangeHandler: (e) => handleChange('unit', e.target.value),
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: 'city',
      isRequired: false,
      dataTestId: 'city',
      readOnly: isSubjectProperty,
      disabled: isSubjectProperty,
      errors: errors,
      placeholder: 'City',
      colStart: 1,
      onChangeHandler: (e) => handleChange('city', e.target.value),
    },
    {
      type: 'select',
      name: 'state',
      label: 'State',
      value: 'state',
      placeholder: 'Select Option',
      dataTestId: 'state',
      optionsAttributes: { optionsLabelName: 'key' },
      options: US_STATES_OPTIONS,
      disabled: !isUS || isSubjectProperty,
      readOnly: isSubjectProperty,
      onChangeHandler: (e) => handleChange('state', e),
    },
    {
      type: 'select',
      name: 'country',
      label: 'Country',
      value: 'country',
      defaultValue: 'US',
      dataTestId: 'country',
      readOnly: isSubjectProperty,
      disabled: isSubjectProperty,
      placeholder: 'Select Option',
      optionsAttributes: {
        optionsLabelName: 'name',
        optionsValueName: 'abbreviation',
      },
      options: COUNTRIES,
      onChangeHandler: (e) => handleChange('country', e),
    },
    {
      type: 'text',
      name: 'zipCode',
      label: 'Zip Code',
      value: 'zipCode',
      isRequired: false,
      errors: errors,
      dataTestId: 'zipCode',
      placeholder: 'Zip Code',
      disabled: !isUS || isSubjectProperty,
      readOnly: isSubjectProperty,
      pattern: '^[0-9]{0,5}$',
      onChangeHandler: (e) => handleChange('zipCode', e.target.value),
    },
    {
      type: 'select',
      name: 'propertyType',
      label: 'Property Type',
      value: 'propertyType',
      placeholder: 'Select Option',
      isRequired: true,
      dataTestId: 'propertyType',
      readOnly: isSubjectProperty,
      disabled: isSubjectProperty,
      optionsAttributes: {
        optionsLabelName: 'value',
        optionsValueName: 'key',
      },
      options: dropdownOptions?.availablePropertyTypes ?? [],
      onChangeHandler: (e) => handleChange('propertyType', +e),
    },
    {
      type: 'select',
      name: 'propertyStatus',
      label: 'Property Status',
      value: 'propertyStatus',
      placeholder: 'Select Option',
      isRequired: true,
      dataTestId: 'propertyStatus',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options:
        SUBJECT_PROPERTY_PROPERTY_STATUS_OPTIONS[isSubjectProperty] ?? [],
      onChangeHandler: (e) => handleChange('propertyStatus', +e),
    },
    {
      type: 'select',
      name: 'occupancyType',
      label: 'Intended Occupancy',
      value: 'occupancyType',
      placeholder: 'Select Option',
      isRequired: true,
      dataTestId: 'occupancyType',
      optionsAttributes: { optionsLabelName: 'value', optionsValueName: 'key' },
      options: occupancyTypeOptions ?? [],
      onChangeHandler: (e) => handleChange('occupancyType', +e),
    },
  ];

  const propertyInfoSection = [
    {
      type: 'infoText',
      text: `Associated Liabilities - ${creditorNames}`,
      dataTestId: 'associatedLiabilitiesText',
      span: 4,
      style: {
        fontSize: '1rem',
        color: theme.colors?.gray[900],
        fontWeight: '500',
        lineHeight: '1.5rem',
      },
      isHidden: () => !isAssociatedLiabilitiesVisible,
    },
    {
      type: 'currency',
      name: 'mortgagePayment',
      label: 'Mortgage Payment',
      value: 'mortgagePayment',
      dataTestId: 'mortgagePayment',
      placeholder: '0.00',
      readOnly: true,
      disabled: true,
    },
    {
      type: 'currency',
      name: 'mortgageBalance',
      label: 'Mortgage Balance',
      value: 'mortgageBalance',
      dataTestId: 'mortgageBalance',
      placeholder: '0.00',
      readOnly: true,
      disabled: true,
    },
    {
      type: 'currency',
      name: 'marketValue',
      label: 'Market Value',
      value: 'marketValue',
      dataTestId: 'marketValue',
      placeholder: '0.00',
      onChangeHandler: (value) => handleChange('marketValue', value),
    },
    {
      type: 'infoText',
      text: `${escrowedOnPropertyText}`,
      dataTestId: 'escrowedOnPropertyText',
      span: 4,
      style: {
        fontSize: '0.875rem',
        color: theme.colors?.gray[900],
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem',
      },
      isHidden: () => !isEscrowedOnPropertyVisible,
    },
    {
      type: 'currency',
      name: 'monthlyPropertyTaxes',
      label: 'Monthly Property Taxes',
      value: 'monthlyPropertyTaxes',
      dataTestId: 'monthlyPropertyTaxes',
      placeholder: '0.00',
      colStart: 1,
      readOnly: true,
      disabled: true,
    },
    {
      type: 'currency',
      name: 'monthlyHazardInsurance',
      label: 'Monthly Hazard Insurance',
      value: 'monthlyHazardInsurance',
      dataTestId: 'monthlyHazardInsurance',
      placeholder: '0.00',
      readOnly: true,
      disabled: true,
    },
    {
      type: 'currency',
      name: 'monthlyFloodInsurance',
      label: 'Monthly Flood Insurance',
      value: 'monthlyFloodInsurance',
      dataTestId: 'monthlyFloodInsurance',
      placeholder: '0.00',
      onChangeHandler: (value) => handleChange('monthlyFloodInsurance', value),
    },
    {
      type: 'currency',
      name: 'monthlyAssociationDues',
      label: 'Monthly Association Dues',
      value: 'monthlyAssociationDues',
      dataTestId: 'monthlyAssociationDues',
      placeholder: '0.00',
      readOnly: !isMonthlyAssociationDuesEditable,
      disabled: !isMonthlyAssociationDuesEditable,
      onChangeHandler: (value) => handleChange('monthlyAssociationDues', value),
    },
    {
      type: 'currency',
      name: 'grossRentalIncome',
      label: 'Gross Rental Income',
      value: 'grossRentalIncome',
      dataTestId: 'grossRentalIncome',
      placeholder: '0.00',
      readOnly: !isGrossRentalIncomeEnabled,
      disabled: !isGrossRentalIncomeEnabled,
      onChangeHandler: (value) => handleChange('grossRentalIncome', value),
    },
    {
      type: 'currency',
      name: 'leaseGroundRents',
      label: 'Lease Ground Rents',
      value: 'leaseGroundRents',
      dataTestId: 'leaseGroundRents',
      placeholder: '0.00',
      onChangeHandler: (value) => handleChange('leaseGroundRents', value),
    },
    {
      type: 'currency',
      name: 'totalMonthlyExpenses',
      label: 'Total Monthly Expenses',
      value: 'totalMonthlyExpenses',
      dataTestId: 'totalMonthlyExpenses',
      placeholder: '0.00',
      readOnly: true,
      disabled: true,
    },
    {
      type: 'currency',
      name: 'netRentalIncome',
      label: 'Net Rental Income',
      value: 'netRentalIncome',
      dataTestId: 'netRentalIncome',
      placeholder: '0.00',
      readOnly: true,
      disabled: true,
    },
  ];

  return (
    <Loan1003FormContainer
      onCloseClicked={() => onClose()}
      onSaveClicked={handleSubmit(onSubmit)}
      toggleErrors={() => setShowErrors(true)}
      isLoading={isLoading}
      readOnlyMode={isWriteAccessDisabled}
    >
      <Loan1003FormSection title="REO Information">
        <FormBuilder
          fields={reoInfoSection}
          columns={4}
          errors={errors}
          showErrors={showErrors}
          readOnlyMode={isWriteAccessDisabled}
          getValues={getValues}
        />
      </Loan1003FormSection>
      <Loan1003FormSection title="Property Information">
        <FormBuilder
          fields={propertyInfoSection}
          columns={4}
          errors={errors}
          showErrors={showErrors}
          getValues={getValues}
        />
      </Loan1003FormSection>
    </Loan1003FormContainer>
  );
}
RealEstateOwnedSummariesForm.propTypes = {
  rowToEdit: PropTypes.object,
  addNewIncomeTypeRow: PropTypes.func,
  deleteIncomeTypeRow: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isEdit: PropTypes.bool,
  allBorrowerOptions: PropTypes.any,
  isWriteAccessDisabled: PropTypes.bool,
  obfuscatedLoanIdentifier: PropTypes.any,
  handleToastMessage: PropTypes.func,
  dropdownOptions: PropTypes.any,
};
