import styled from '@emotion/styled';
import { Button } from 'common/components/Button';

export const StyledAuditHistoryButton = styled(Button)`
  color: ${(props) => props.theme.colors?.blue[600]};
  background: ${(props) => props.theme.colors?.gray[50]};
  border: ${(props) => `1px solid ${props.theme.colors?.blue[600]}`};

  // override styles
  padding-left: unset;
  padding-right: unset;
  & span {
    margin-inline-start: unset;
  }
  ${(props) => props.customstyles}
`;
