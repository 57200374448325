import { useTheme } from '@chakra-ui/react';
import InputGroup from 'common/components/Inputs/InputGroup';
import styled from '@emotion/styled';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const StyledPatternFormat = styled(PatternFormat)`
  padding-left: 1rem;
  width: 100%;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
    `}
  ${(props) =>
    props['data-is-error'] &&
    `
        background-color: unset;
        border-color: ${props.theme.colors.red[500]};
      `}
`;

export default function PatternFormatInput({
  onChange,
  disabled,
  pattern,
  mask,
  allowEmptyFormatting,
  tabIndex = 0,
  isError,
  ...props
}) {
  const { colors } = useTheme();
  return (
    <InputGroup
      height="2.5rem"
      border="1px solid"
      borderColor={isError ? colors?.red[500] : colors?.gray[200]}
      display="flex"
      flexDirection="row"
      backgroundColor={colors?.white}
    >
      {/* eslint-disable-next-line react/no-children-prop */}
      <StyledPatternFormat
        {...props}
        allowEmptyFormatting={allowEmptyFormatting}
        tabIndex={tabIndex}
        disabled={disabled}
        format={pattern}
        mask={mask}
        onChange={onChange}
        data-is-error={isError}
      />
    </InputGroup>
  );
}

PatternFormatInput.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  isError: PropTypes.bool,
  mask: PropTypes.string,
  allowEmptyFormatting: PropTypes.bool,
  tabIndex: PropTypes.number,
};
