import {
  getLoanInfoStarted,
  getLoanInfoSuccess,
  getLoanInfoError,
  saveQuestionAnswersStarted,
  saveQuestionAnswersSuccess,
  saveQuestionAnswersError,
  setFeatureFlags,
} from './actions';
import { typedApiClient } from 'common/util/api-client';
import { GENERIC_ERROR_MESSAGE } from 'common/constants';

export const getLoanInfo = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(getLoanInfoStarted());
  try {
    const { data } = await typedApiClient.v1.loanInfoGetLoanInfo({
      obfuscatedLoanIdentifier,
    });

    dispatch(getLoanInfoSuccess(data));
  } catch (error) {
    dispatch(getLoanInfoError, GENERIC_ERROR_MESSAGE);
  }
};

export const saveQuestionAnswers =
  (conditionCategory, responses) => async (dispatch, getState) => {
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    dispatch(saveQuestionAnswersStarted());
    try {
      await typedApiClient.v1.questionsAnswers({
        obfuscatedLoanIdentifier,
        conditionCategory,
        responses,
      });

      dispatch(saveQuestionAnswersSuccess());
      return true;
    } catch (error) {
      dispatch(saveQuestionAnswersError(error?.response?.data ?? error));
      return false;
    }
  };

export const getFeatureFlags =
  (isUnderwriter) => async (dispatch, getState) => {
    try {
      const {
        url: { obfuscatedLoanIdentifier },
      } = getState();
      const { data } = await typedApiClient.v1.loanGetBoltNavFeatureFlags(
        obfuscatedLoanIdentifier,
      );

      if (data.featureFlags) {
        if (!isUnderwriter) {
          data.featureFlags.IncludeKnowYourLoan = false;
          data.featureFlags.RentalNoDocsEnhancement = false;
        }

        dispatch(setFeatureFlags(data.featureFlags));
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn(
        'There was an error retrieving the feature flags at this time',
      );
    }
  };
