import Stack from 'common/components/Stacks/Stack';
import Text from 'common/components/Texts/Text';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

export default function Loan1003FormSection({
  title,
  children,
  isHidden = () => false,
  customMarginTop,
}) {
  const theme = useTheme();
  if (isHidden()) return null;
  return (
    <Stack
      width={'100%'}
      boxSizing="border-box"
      marginTop={
        customMarginTop ? `${customMarginTop} !important` : 'unset !important'
      }
    >
      <Text
        style={{
          width: '100%',
          padding: '.25rem',
          marginBottom: '1rem',
          color: theme.colors?.blue[600],
          borderBottom: `0.3rem solid ${theme.colors?.blue[600]}`,
        }}
        fontSize={'2xl'}
      >
        {title}
      </Text>
      {children}
    </Stack>
  );
}

Loan1003FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  customMarginTop: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHidden: PropTypes.func,
};
