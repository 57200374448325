import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'common/components/Link';
import Image from 'common/components/Image';
import Grid from 'common/components/Grids/Grid';
import Flex from 'common/components/Flex';
import {
  FOOTER_LINKS_TOP,
  FOOTER_LINKS_BOTTOM,
  SOCIAL_MEDIA_LINKS,
} from './constants';
import EHLogo from 'common/assets/Equalhousinglender.svg';

const StyledFooter = styled.footer`
  font-size: 0.825rem;
  text-transform: uppercase;
  @media (min-width: 1024px) and (max-width: 1440px) {
    min-width: 100%;
  }
  min-width: 1272px;
`;

const StyledTopContainer = styled.section`
  height: fit-content;
  background: ${(props) => props.theme.colors?.white};
  padding-top: 24px;
  @media (min-width: 1024px) and (max-width: 1440px) {
    min-width: 100%;
  }
`;

const StyledBottomContainer = styled.section`
  height: 55px;
  background: #006199;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${(props) => props.theme.colors?.white};
  padding: 0 30%;
  font-weight: bold;
`;

const StyledFooterItem = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
  display: block;
  font-size: 0.725rem;
`;

const StyledSocialMediaIconContainer = styled.section`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  margin-bottom: 24px;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <StyledTopContainer>
        <Flex alignItems="center" flexDirection="column">
          <Grid
            width="65%"
            maxWidth="1272px"
            templateColumns="repeat(4, 1fr)"
            gap={6}
          >
            {FOOTER_LINKS_TOP.map(({ title, link }) => (
              <Link
                href={link}
                key={title}
                isExternal
                fontWeight="bold"
                fontSize="0.725rem"
                mb="24px"
                _hover={{
                  textDecoration: 'none',
                  color: '#abcc68',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                {title}
              </Link>
            ))}
            <StyledFooterItem>
              Connect with UWM on Social Media
            </StyledFooterItem>
          </Grid>
          <Grid
            width="65%"
            maxWidth="1272px"
            templateColumns="repeat(4, 1fr)"
            gap={6}
          >
            {FOOTER_LINKS_BOTTOM.map(({ title, link }) => (
              <Link
                href={link}
                key={title}
                isExternal
                fontWeight="bold"
                fontSize="0.725rem"
                mb="24px"
                _hover={{
                  textDecoration: 'none',
                  color: '#abcc68',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                {title}
              </Link>
            ))}
            <StyledSocialMediaIconContainer>
              {SOCIAL_MEDIA_LINKS.map(({ icon, link, alt, ariaLabel }) => (
                <Link
                  href={link}
                  key={link}
                  isExternal
                  width="24px"
                  height="24px"
                  color="#555"
                  border="solid 1px"
                  borderRadius="75%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="5px"
                  mr="8px"
                  _hover={{
                    textDecoration: 'none',
                    color: '#abcc68',
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  aria-label={ariaLabel}
                >
                  <FontAwesomeIcon
                    className="icon"
                    icon={icon}
                    alt={alt}
                  ></FontAwesomeIcon>
                </Link>
              ))}
            </StyledSocialMediaIconContainer>
          </Grid>
        </Flex>
      </StyledTopContainer>
      <StyledBottomContainer>
        <Flex width="1272px" maxWidth="1272px" justifyContent="space-between">
          <span>Copyright © {new Date().getFullYear()}</span>
          <span>•</span>
          <span>United Wholesale Mortgage</span>
          <span>•</span>
          <span></span>
          <span>All Rights Reserved.</span>
          <Image
            src={EHLogo}
            alt="Equal Housing Lender Logo"
            width="1.5rem"
            height="1.5rem"
          />
        </Flex>
      </StyledBottomContainer>
    </StyledFooter>
  );
};

export default Footer;
