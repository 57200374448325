import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useTheme } from '@chakra-ui/react';
import BoxComponent from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/componentMapper/Text';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import styled from '@emotion/styled';
import FlexBox from './FlexBox';

const BOX_ENUM = {
  DEFAULT: 'box',
  CONTAINER: 'container',
  FLEX: 'flex',
  TITLE_BOX: 'title',
};

const InnerBox = styled(BoxComponent)`
  padding: 1rem 1rem 2rem;
`;

const WrapperBox = styled(BoxComponent)`
  border: 1px solid ${(props) => props.theme.colors?.gray[200]};
  border-radius: 4px;
  margin-bottom: 10px;
`;

const Container = ({ fields = [], title, sx }) => {
  const { renderForm } = useFormApi();
  return (
    <WrapperBox sx={sx}>
      {!!title && (
        <Text
          id="container-box-title"
          py="0.5rem"
          px="1rem"
          bg="gray.50"
          as="h4"
          textAlign="left"
        >
          {title}
        </Text>
      )}
      <Flex direction="column">
        <InnerBox>{renderForm(fields)}</InnerBox>
      </Flex>
    </WrapperBox>
  );
};
Container.propTypes = {
  fields: PropTypes.array,
  title: PropTypes.string,
  sx: PropTypes.object,
};

function TitleBox({ fields, title, sx }) {
  const { renderForm } = useFormApi();
  const { colors } = useTheme();

  return (
    <BoxComponent
      css={css`
        border: 1px solid ${colors?.gray['200']};
        border-radius: 4px;
        margin-bottom: 1rem;
      `}
      sx={sx}
    >
      <BoxComponent
        css={css`
          background-color: ${colors?.gray['50']};
          font-weight: 700;
          font-size: 1rem;
          padding: 0.5rem 1rem;
        `}
      >
        {title}
      </BoxComponent>
      <BoxComponent padding="1.5rem">{renderForm(fields)}</BoxComponent>
    </BoxComponent>
  );
}
TitleBox.propTypes = {
  fields: PropTypes.array,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function Box({
  fields = [],
  children,
  type = 'box',
  title,
  sx,
  ...rest
}) {
  const { renderForm } = useFormApi();

  switch (type) {
    case BOX_ENUM.CONTAINER:
      return <Container fields={fields} sx={sx} title={title} />;

    case BOX_ENUM.FLEX:
      return <FlexBox fields={fields} sx={sx} />;

    case BOX_ENUM.TITLE_BOX:
      return <TitleBox fields={fields} sx={sx} title={title} {...rest} />;

    case BOX_ENUM.DEFAULT:
    default:
      return (
        <BoxComponent sx={sx} id={rest?.id}>
          {children ?? renderForm(fields)}
        </BoxComponent>
      );
  }
}

Box.propTypes = {
  fields: PropTypes.array,
  type: PropTypes.oneOf(Object.values(BOX_ENUM)),
  title: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node,
};
