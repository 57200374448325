// 3rd party imports
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

// local imports
import Button from '../Button';
import {
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
} from './signalRNavigation';

const StyledFooter = styled.footer`
  box-sizing: border-box;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px 0 32px;
  border-top: 1px solid ${(props) => props.theme.colors?.gray[100]};
  @media print {
    display: none;
  }
`;

const Footer = ({
  primaryButton,
  secondaryButton,
  error,
  waitForSignalR,
  isInForm,
  shouldAutoCheck = false,
}) => {
  const {
    user: { isUnderwriter },
    selectedAlert,
    selectedCategory,
    shouldGetNextAlert,
  } = useSelector((state) => state.home);
  const { FEVerified } = useSelector((state) => state.feVerified);
  const dispatch = useDispatch();
  const sa = { ...selectedAlert };
  return (
    <StyledFooter role="group">
      {secondaryButton && (
        <Button
          mr="1rem"
          isPrimary={false}
          onClick={(event) =>
            handleSecondaryButtonClick(event, secondaryButton?.onSubmit)
          }
          isDisabled={secondaryButton?.isDisabled || error}
          isLoading={secondaryButton?.isSubmitting}
          dataTestid={`secondaryButton-${secondaryButton?.name}`}
        >
          {secondaryButton?.name}
        </Button>
      )}

      {/* Continue Button - Primary */}
      {primaryButton &&
        (isInForm ? (
          <Button
            type="submit"
            id={primaryButton?.id}
            isDisabled={primaryButton?.isDisabled || error}
            isLoading={primaryButton?.isSubmitting || shouldGetNextAlert}
          >
            {primaryButton?.name || 'Continue'}
          </Button>
        ) : (
          <Button
            id={primaryButton?.id}
            isDisabled={primaryButton?.isDisabled || error}
            isLoading={primaryButton?.isSubmitting || shouldGetNextAlert}
            onClick={(event) =>
              handlePrimaryButtonClick(
                event,
                primaryButton?.onSubmit,
                dispatch,
                isUnderwriter,
                sa,
                waitForSignalR,
                selectedCategory,
                shouldAutoCheck,
                FEVerified,
              )
            }
          >
            {primaryButton?.name || 'Continue'}
          </Button>
        ))}
    </StyledFooter>
  );
};

Footer.propTypes = {
  primaryButton: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    onSubmit: PropTypes.func,
    isDisabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    'data-testid': PropTypes.string,
  }).isRequired,
  secondaryButton: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      onSubmit: PropTypes.func,
      isDisabled: PropTypes.bool,
      isSubmitting: PropTypes.bool,
      'data-testid': PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  verifiedRule: PropTypes.shape({
    ruleResultId: PropTypes.number,
    isUnderwriterVerified: PropTypes.bool,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  waitForSignalR: PropTypes.bool,
  isInForm: PropTypes.bool,
  selectedCategory: PropTypes.number,
  shouldAutoCheck: PropTypes.bool,
};

Footer.defaultProps = {
  waitForSignalR: true,
};

export default Footer;
