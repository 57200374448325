import React from 'react';
import * as rPdf from '@react-pdf/renderer';
import UWMLogo from '../../common/components/UWMLogo';
import {
  TrophyIcon,
  BulbIcon,
  HomeIcon,
  StarIcon,
  HeartIcon,
  DarkLogo,
} from './icons';
import colors from 'theme/colors';
import lang from './language';

const { Page, Text, View, StyleSheet } = rPdf;

function CoverLetter() {
  const styles = StyleSheet.create({
    page: {
      padding: '30px 70px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '12px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignContent: 'center',
      justifyContent: 'space-evenly',
      color: colors?.bgGray[800],
    },
    row: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    horizontalRule: {
      borderBottom: `1.5px solid ${colors?.uwmLogo.limeGreen}`,
    },
    header: {
      color: colors?.uwmLogo.blue,
      fontFamily: 'Roboto',
      fontWeight: 'black',
    },
    cardsRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    mainCard: {
      padding: '15px 0px',
      display: 'flex',
      alignItems: 'center',
      margin: '2px',
      backgroundColor: colors?.uwmLogo.blue,
      color: 'white',
      width: '100%',
    },
    childCard: {
      padding: '15px 0px',
      display: 'flex',
      alignItems: 'center',
      margin: '2px',
      backgroundColor: colors?.uwmLogo.limeGreen,
      color: 'white',
      width: '50%',
    },
    textContainer: {
      width: '75%',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
      borderRadius: '100%',
    },
    iconBlueBackground: {
      backgroundColor: colors?.blue[500],
    },
    iconGreenBackground: {
      backgroundColor: '#b3db72',
    },
    housingLender: {
      color: colors?.bgGray[800],
      textAlign: 'right',
      fontSize: '9px',
    },
  });

  return (
    <Page style={styles.page} wrap>
      <View style={styles.row}>
        <UWMLogo />
      </View>
      <View style={styles.horizontalRule} />
      <View style={styles.row}>
        <Text style={styles.header}>{lang.CONGRATULATION}</Text>
        <Text>{lang.INTRODUCTION}</Text>
      </View>
      <View style={styles.row}>
        <View style={styles.mainCard}>
          <View
            style={{
              ...styles.iconContainer,
              ...styles.iconBlueBackground,
            }}
          >
            <TrophyIcon />
          </View>
          <Text style={styles.textContainer}>{lang.NUMBER_ONE_MORTGAGE}</Text>
        </View>
        <View style={styles.cardsRow}>
          <View style={styles.childCard}>
            <View
              style={{
                ...styles.iconContainer,
                ...styles.iconGreenBackground,
              }}
            >
              <HeartIcon />
            </View>
            <Text style={styles.textContainer}>{lang.TEAM_AS_FAMILY}</Text>
          </View>
          <View style={styles.childCard}>
            <View
              style={{
                ...styles.iconContainer,
                ...styles.iconGreenBackground,
              }}
            >
              <HomeIcon />
            </View>
            <Text style={styles.textContainer}>{lang.MORTGAGES}</Text>
          </View>
        </View>
        <View style={styles.cardsRow}>
          <View style={styles.childCard}>
            <View
              style={{
                ...styles.iconContainer,
                ...styles.iconGreenBackground,
              }}
            >
              <StarIcon />
            </View>
            <Text style={styles.textContainer}>{lang.RATING}</Text>
          </View>
          <View style={styles.childCard}>
            <View
              style={{
                ...styles.iconContainer,
                ...styles.iconGreenBackground,
              }}
            >
              <BulbIcon />
            </View>
            <Text style={styles.textContainer}>{lang.FRIENDLY_PORTAL}</Text>
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <Text>{lang.PAYMENTS}</Text>
      </View>

      <View style={styles.horizontalRule} />
      <View style={styles.row}>
        <Text>{lang.SERVICES}</Text>
      </View>
      <View style={styles.row}>
        <Text>{lang.NMLS} </Text>
        <DarkLogo style={styles.row} />
      </View>
      <View style={styles.housingLender}>
        <Text>{lang.STATS_SINCE}</Text>
      </View>
    </Page>
  );
}

export default CoverLetter;
