import { useEffect } from 'react';
import styled from '@emotion/styled';
import Text from 'common/components/Texts/Text';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { truncate } from 'lodash';

import StatusIcon from 'common/components/Icons/StatusIcon';
import { ALERT_TYPES } from 'common/constants';
import { HomeActions } from './redux';
import { IS_LEFT_DRAWER_COLLAPSED } from 'pages/ClassifyDocumentsV2/constants';
import useLeftDrawerContext from 'common/components/Nav/LeftDrawer/context/useLeftDrawerContext';

const StyledChildAlert = styled('button', {
  shouldForwardProp: (prop) => prop !== IS_LEFT_DRAWER_COLLAPSED,
})`
  cursor: pointer;
  display: flex;
  justify-content: ${(props) => (props.isLeftDrawerCollapsed ? 'left' : '')};
  transition: all 0.5s ease-in-out;
  align-items: center;
  padding-left: ${(props) => (props.isLeftDrawerCollapsed ? '27px' : '35px')};
  width: ${(props) => (props.isLeftDrawerCollapsed ? '72px' : '100%')};
  height: 48px;
  border-bottom: 1px solid ${(props) => props.theme.colors?.gray[200]};
  background-color: ${(props) =>
    props.selected ? props.theme.colors?.blue[50] : props.theme.colors?.white};

  &:hover {
    background-color: ${(props) => props.theme.colors?.blue[100]};
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.colors?.gray[100]};
    outline: 0;
  }
`;

const ChildAlert = ({ parentAlert, alert }) => {
  const dispatch = useDispatch();
  const { isLeftDrawerCollapsed, handleLeftDrawerCollapseForChildAlert } =
    useLeftDrawerContext();
  const { home } = useSelector((state) => state);
  const selectedAlert = home?.selectedAlert;
  const selectedAlertParent = home?.selectedAlertParent;

  useEffect(() => {
    if (
      alert?.gutterItemId === selectedAlert?.gutterItemId &&
      selectedAlertParent?.gutterItemId !== parentAlert?.gutterItemId
    ) {
      dispatch(HomeActions.setSelectedAlertParent(parentAlert));
    }
  }, [alert, dispatch, parentAlert, selectedAlert, selectedAlertParent]);

  return (
    <StyledChildAlert
      title={alert?.displayTitle}
      role="tab"
      selected={alert?.gutterItemId === selectedAlert?.gutterItemId}
      onClick={() =>
        handleLeftDrawerCollapseForChildAlert(alert, selectedAlert, dispatch)
      }
      data-testid="child-alert"
      aria-selected={alert?.gutterItemId === selectedAlert?.gutterItemId}
      data-tracking={`Child-${alert?.displayTitle}`}
      isLeftDrawerCollapsed={isLeftDrawerCollapsed}
    >
      <StatusIcon status={alert?.ruleResultStatusId} type={ALERT_TYPES.CHILD} />

      <Text
        as="h4"
        marginLeft="11px"
        opacity={isLeftDrawerCollapsed ? 0 : 1}
        whiteSpace="nowrap"
        transform={isLeftDrawerCollapsed ? 'translateX(-8px)' : 'translateX(0)'}
        transition="all 0.5s ease-in-out"
      >
        {truncate(alert?.displayTitle, {
          length: 34,
        })}
      </Text>
    </StyledChildAlert>
  );
};

ChildAlert.propTypes = {
  alert: PropTypes.object,
  selectedAlert: PropTypes.object,
  parentAlert: PropTypes.object,
  isLeftDrawerCollapsed: PropTypes.bool,
};

export default ChildAlert;
