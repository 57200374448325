import styled from '@emotion/styled';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export const StyledOption = styled('li')`
  margin-left: unset;
  font-size: ${(props) => props.theme.functions?.pixelsToRem(12)};
  &.MuiAutocomplete-option.Mui-focused {
    background-color: ${(props) => props.theme.colors?.blue[50]};
    color: ${(props) => props.theme.colors?.gray[900]};
  }
  &.MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${(props) => props.theme.colors?.blue[600]};
    color: ${(props) => props.theme.colors?.white};
    font-weight: bold;
  }
  &.MuiAutocomplete-option[aria-selected='true'].Mui-focused {
    background-color: ${(props) => props.theme.colors?.blue[600]};
    color: ${(props) => props.theme.colors?.white};
    font-weight: bold;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
  }
  &
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input {
    padding-top: unset;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors?.gray[200]};
  }
  & .MuiInputBase-formControl {
    padding: calc(-1px + 0.5rem) 2rem 0px 0.5rem;

    & .MuiChip-root {
      margin-top: unset;
      margin-bottom: 7px;
    }
  }
  & .MuiInputBase-root {
    border-radius: 4px;
  }
`;

export const StyledChip = styled(Chip)`
  height: 24px;
  font-size: ${(props) => props.theme.functions?.pixelsToRem(12)};
  border-color: ${(props) => props.theme.colors?.blue[600]};

  .MuiChip-label {
    color: ${(props) => props.theme.colors?.gray[900]};
    padding-right: 4px;
  }

  .delete-icon-root {
    display: flex;
    margin-right: 8px;
    height: 100%;
    padding: 0 4px;
    border-radius: 2px;
    --delete-icon-color: ${(props) => props.theme.colors?.gray[900]};
    --delete-icon-hover-bg: ${(props) => props.theme.colors?.blue[500]};
    --delete-icon-hover-color: ${(props) => props.theme.colors?.white};
    --delete-icon-font-size: ${(props) =>
      props.theme.functions?.pixelsToRem(14)};

    &:hover {
      background-color: var(--delete-icon-hover-bg);
      .MuiChip-deleteIcon {
        color: var(--delete-icon-hover-color);
      }
    }

    .MuiChip-deleteIcon {
      color: var(--delete-icon-color);
      font-size: var(--delete-icon-font-size);
      margin: unset;
      align-self: center;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input.MuiOutlinedInput-input {
    color: ${(props) => props.theme.colors?.black};
    font-size: ${(props) => props.theme.functions?.pixelsToRem(14)};
    min-height: 25px;
    padding-top: unset;

    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.colors?.bgGray[900]};
    }
  }
`;
const XMarkIcon = (props) => (
  <div className="delete-icon-root">
    <FontAwesomeIcon {...props} icon={faTimes} data-testid="CloseIcon" />
  </div>
);

export const StyledXMarkIcon = styled(XMarkIcon)`
  color: ${(props) => props.theme.colors?.gray[900]};
`;
