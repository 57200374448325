import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'common/components/Buttons/Button/index';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import {
  AuditHistoryActions,
  AuditHistoryThunks,
} from 'pages/AuditHistory/redux';

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.colors?.blue[600]};
  background: ${(props) => props.theme.colors?.gray[50]};
  border: ${(props) => `1px solid ${props.theme.colors?.blue[600]}`};

  // override styles
  ${(props) => props.customstyles}
`;

export default function AuditHistoryButton({
  selectedIds,
  questionIds,
  children,
  customstyles = '',
  questions = [],
  auditHistoryParams = {},
}) {
  const dispatch = useDispatch();

  const { permissionsForAuditHistory, data } = useSelector(
    ({ home, auditHistory: { data } }) => ({
      permissionsForAuditHistory: home.user.isUnderwriter,
      data,
    }),
  );

  const auditDataIsMissingRuleResultIds =
    selectedIds?.some((id) => !Object.keys(data).includes(`${id}`)) ?? true;

  return (
    permissionsForAuditHistory && (
      <StyledButton
        rightIcon={children && <FontAwesomeIcon icon={faHistory} />}
        onClick={() => {
          const {
            auditHistoryTypes = [],
            ruleReferenceIdentifiers,
            questionCategory,
          } = auditHistoryParams;

          if (auditDataIsMissingRuleResultIds && auditHistoryTypes.length) {
            dispatch(
              AuditHistoryThunks.getBoltAuditHistoryDataWithParams({
                auditHistoryTypes,
                ruleReferenceIdentifiers,
                questionCategory,
              }),
            );
          }
          dispatch(
            AuditHistoryActions.toggleAuditHistory({
              questions,
              questionIds,
              selectedIds,
            }),
          );
        }}
        customstyles={customstyles}
        title="History"
      >
        {children}
        {!children && <FontAwesomeIcon icon={faHistory} />}
      </StyledButton>
    )
  );
}

AuditHistoryButton.propTypes = {
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  questionIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  customstyles: PropTypes.string,
  children: PropTypes.node,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
  auditHistoryParams: PropTypes.shape({
    auditHistoryTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
    ruleReferenceIdentifiers: PropTypes.arrayOf(
      PropTypes.shape({
        ruleReferenceType: PropTypes.number,
        lenderDatabaseId: PropTypes.number,
        ruleReferenceValue: PropTypes.string,
      }),
    ).isRequired,
    questionCategory: PropTypes.string,
  }),
};
