import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import IncomeSourceHeader from './IncomeSourceHeader';
import IncomeRecordData from '../model/IncomeRecordDataModel';
import propTypes from 'common/propTypes';
import { useSelector } from 'react-redux';
import { USER_ROLE } from 'common/constants';

const IncomeHeader = ({
  selectedIncome,
  auditHistoryData,
  incomeHeaderPadding,
  showForm1003ButtonInIncomeHeader = true,
}) => {
  const {
    user: { isUnderwriter, role },
    boltStatus,
    navigationItems,
    selectedCategory,
  } = useSelector((state) => state.home);

  const isCategoryReadOnlyPermission = navigationItems?.categories?.find(
    (category) => category?.navigationCategoryId === selectedCategory,
  )?.isReadOnlyPermission;

  const isWriteAccessDisabled =
    (!isUnderwriter && boltStatus?.value) ||
    role === USER_ROLE.ACCOUNT_EXECUTIVE ||
    isCategoryReadOnlyPermission;

  const props = {
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'gray.100',
    padding: incomeHeaderPadding || '0 32px 0 32px',
  };
  if (!selectedIncome) {
    return (
      <Box height="72px" borderBottom="1px solid" borderColor="gray.100" />
    );
  }

  return (
    <IncomeSourceHeader
      auditHistoryData={auditHistoryData}
      monthlyIncome={selectedIncome.getMonthlyAmount()}
      fullName={selectedIncome.getBorrowerName().getFullName()}
      incomeCategory={selectedIncome.getIncomeCategoryType()}
      isPrimary={selectedIncome.getIsPrimarySource()}
      startDate={selectedIncome.getStartDateString()}
      endDate={selectedIncome.getEndDateString()}
      isCurrent={selectedIncome.getIsCurrent()}
      isWriteAccessDisabled={isWriteAccessDisabled}
      showForm1003ButtonInIncomeHeader={showForm1003ButtonInIncomeHeader}
      {...props}
    />
  );
};

IncomeHeader.propTypes = {
  auditHistoryData: PropTypes.oneOfType([
    propTypes.auditHistoryData,
    PropTypes.oneOf([false]),
  ]),
  selectedIncome: PropTypes.instanceOf(IncomeRecordData),
  incomeHeaderPadding: PropTypes.string,
  showForm1003ButtonInIncomeHeader: PropTypes.bool,
};

export default IncomeHeader;
