import axios from 'axios';
import oidcManager from './oidc-manager';
import jwtDecode from 'jwt-decode';
import API_ENDPOINTS from '../constants/api-endpoints.js';
import { Api as TypedApiClient } from '__generated__/api';

const axiosOptions = {
  baseURL: `${window.globalConfig?.api.endpoint_uri}/`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

/**
 * @deprecated Prefer using the `typedApiClient` for more safety when calling BOLTXX endpoints.
 * Read the README.md in this file's directory for more information
 */
const apiClient = axios.create(axiosOptions);

/**
 * Typed Api Client
 * @description This client has the same functionality as the `apiClient` defined above, but has an extra layer of methods to give type-safe access to our BOLTXX endpoints.
 * Read the README.md in this file's directory for more information
 * @example
 * // The first parameter is the payload to send as the body to the endpoint
 * // You can pass a second paramter which will be of type `AxiosRequestConfig` if you want to edit the behavior of the Axios client for that request 
 * const { data } = await typedApiClient.v1.propertyInformationGetQuestionAnswersDataAsync({
      context,
      obfuscatedLoanIdentifier,
    });
 */
export const typedApiClient = new TypedApiClient(axiosOptions);

apiClient.lastRequest = null;

// We need to set the typedApiClient's instance to the apiClient so
//  the ErrorBoundary component can correctly get the lastRequest no matter what client was used
typedApiClient.instance = apiClient;

/**
 * @param {import('oidc-client').User} user
 * @returns {boolean} true if the user token is expired or doesn't exist
 */
const userExpired = (user) => {
  return (
    !user ||
    user.expired ||
    !user.access_token ||
    new Date(jwtDecode(user.access_token).exp * 1000) <= Date.now()
  );
};

/**
 * Interceptor for adding bearer token to requests.
 * This isn't needed when using the mock API
 * @param {import('axios').InternalAxiosRequestConfig} config
 */
async function authInterceptor(config) {
  if (window.ADRUM) {
    const apiCallLogger = new window.ADRUM.events.Ajax();
    window.ADRUM.report(apiCallLogger);
  }
  let user = await oidcManager.getUser();
  if (!config.url.endsWith(API_ENDPOINTS.POST_ERROR_LOG)) {
    apiClient.lastRequest = config.url.replace(config.baseURL, '');
  }

  if (userExpired(user)) {
    await oidcManager.clearStaleState();
    if (!user?.refresh_token) {
      return oidcManager.signinRedirect({
        state: window.location.href,
      });
    } else {
      await oidcManager.signInSilentWithRedirectFallback();
      user = await oidcManager.getUser();
    }
  }

  config.headers.authorization = `Bearer ${user.access_token}`;
  return config;
}

const interceptorErrorCallback = async (error) =>
  Promise.reject(new Error(error));

/* c8 ignore next */
apiClient.interceptors.request.use(authInterceptor, interceptorErrorCallback);

export default apiClient;
