import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import AccordionItem from 'common/components/Accordions/AccordionItem';
import AccordionPanel from 'common/components/Accordions/AccordionPanel';
import Accordion from 'common/components/Accordions/Accordion';
import AccordionIcon from 'common/components/Accordions/AccordionIcon';
import AccordionButton from 'common/components/Accordions/AccordionButton';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { useTheme } from '@chakra-ui/react';

const LoanSummaryDrawerAccordion = ({
  header,
  secondaryHeader,
  children,
  accordionButtonProps,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Accordion
      allowMultiple
      defaultIndex={[0]}
      mt={1}
      mb={0}
      {...props}
      css={css`
        & .chakra-collapse {
          overflow: unset !important;
        }
      `}
    >
      <AccordionItem sx={{ border: 'none' }}>
        <AccordionButton
          sx={{ width: '100%', padding: 0 }}
          onClick={accordionButtonProps?.onClick || (() => {})}
        >
          <Text
            as="h4"
            d="inline-flex"
            flex="1"
            textAlign="left"
            borderBottom={`1px solid ${theme.colors?.uwmLogo.blue}`}
            color={theme.colors?.uwmLogo.blue}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {header}
            <AccordionIcon />
          </Text>
          <Text>{secondaryHeader}</Text>
        </AccordionButton>

        <AccordionPanel p="unset" m="unset" pb={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              padding: '1rem 0',
            }}
          >
            {children}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

LoanSummaryDrawerAccordion.propTypes = {
  /** The header text to display for the Validate Forms */
  header: PropTypes.string.isRequired,
  /** The header text to display for the Validate Forms */
  secondaryHeader: PropTypes.string,
  /** The header text to display for the Validate Forms */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  layoutType: PropTypes.string,
  accordionButtonProps: PropTypes.shape(),
};

export default LoanSummaryDrawerAccordion;
