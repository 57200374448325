import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import Stack from 'common/components/Stacks/Stack';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Checkbox from 'common/components/Checkboxes/Checkbox/Checkbox';
import { isVisible } from 'common/util/underwriter';
import styled from '@emotion/styled';
import { setUnderwriterVerifiedRule } from './redux/action';
import useFullPageWithPDFViewer from 'common/hooks/useFullPageWithPDFViewer';
import { CATEGORY_IDS } from 'pages/Home/constants';
import { RULE_ID } from 'common/constants';
import { IncomeRuleIds } from 'pages/IncomeCalculator/enums';

let boxStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'blue.500',
  borderRadius: '4px',
  bg: 'blue.50',
  mt: '24px',
  mr: '32px',
  width: '100%',
  height: '89px',
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isWriteAccessDisabled' || prop !== 'isFullPageWithPDFViewer',
})((props) => ({
  pointerEvents: props.isWriteAccessDisabled ? 'none' : 'auto',
  opacity: props.isWriteAccessDisabled ? 0.6 : 1,
  padding: props.isFullPageWithPDFViewer ? '1rem' : '0',
  borderTop: props.isFullPageWithPDFViewer
    ? `1px solid ${props.theme.colors?.gray[100]}`
    : 'none',
}));

const UnderwriterCheckbox = () => {
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState(null);
  const { isFullPageWithPDFViewer } = useFullPageWithPDFViewer();
  const selectedAlert = useSelector((state) => state.home.selectedAlert);
  const isUnderwriter = useSelector((state) => state.home?.user?.isUnderwriter);
  const boltStatus = useSelector((state) => state.home?.boltStatus);
  const isZappedLoan = useSelector((state) => state.home?.isZappedLoan);
  const selectedCategory = useSelector((state) => state.home.selectedCategory);
  const ruleId = selectedAlert?.ruleId;

  const handleOnChangeFeVerified = ({ target }) => {
    const checked = target.checked;
    setIsVerified(checked);
    dispatch(setUnderwriterVerifiedRule(checked));
  };

  useEffect(() => {
    if (
      selectedCategory === CATEGORY_IDS.CLASSIFY ||
      (selectedCategory === CATEGORY_IDS.INCOME &&
        ruleId === IncomeRuleIds.NoDocumentationProvidedIncomeSummary)
    )
      return; // skip if the category is classify or income

    let underwriterVerifiedRule = selectedAlert?.isUnderwriterVerified;

    if (
      ruleId === RULE_ID.ASSET_RECONCILIATION_RULE_ID ||
      (ruleId === RULE_ID.ASSET_TYPE_RULE_ID && isZappedLoan)
    ) {
      underwriterVerifiedRule = true; // override the value
    }
    setIsVerified(underwriterVerifiedRule);
    dispatch(setUnderwriterVerifiedRule(underwriterVerifiedRule));
  }, [selectedAlert, selectedCategory, ruleId, isZappedLoan, dispatch]);

  const showCheckbox = useMemo(() => {
    return isVisible(isUnderwriter, selectedAlert, boltStatus, isZappedLoan);
  }, [isUnderwriter, selectedAlert, boltStatus, isZappedLoan]);

  if (isFullPageWithPDFViewer) {
    boxStyle = {
      ...boxStyle,
      mt: 0,
    };
  }

  return (
    showCheckbox && (
      <StyledBox
        isWriteAccessDisabled={selectedAlert?.isWriteAccessDisabled}
        isFullPageWithPDFViewer={isFullPageWithPDFViewer}
        padding="1rem"
      >
        <Box {...boxStyle} data-testid="underwriter-checkbox">
          <Stack spacing="10px" ml="27px" mt="15px">
            <Text fontSize=".875rem" fontWeight="600">
              Verified by Underwriter
            </Text>
            <Checkbox
              fontSize=".75rem"
              fontWeight="400"
              onChange={handleOnChangeFeVerified}
              isChecked={isVerified}
              isDisabled={selectedAlert?.isUnderwriterCheckboxDisabled}
            >
              Approve
            </Checkbox>
          </Stack>
        </Box>
      </StyledBox>
    )
  );
};

export default UnderwriterCheckbox;
