import styled from '@emotion/styled';

export const RightContainer = styled.div`
  min-height: 656px;
  background: ${(props) => props.theme.colors?.bgGray[200]};
  border-left: 1px solid ${(props) => props.theme.colors?.gray[200]};
  width: 100%;
`;

export const PanelContainer = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 800px;
  width: 1272px;
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
  height: fit-content;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors?.gray[200]};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  // Global layout footer is absolutely positioned relative to this panel.
  position: relative;
`;
export const PanelContainerFullWidth = styled.section`
  display: grid;
  grid-template-columns: ${(props) =>
    props.templateColumns ? props.templateColumns : 'auto auto 1fr'};
  min-height: calc(100vh - 337px);
  overflow-y: ${(props) => (props.templateColumns ? 'hidden' : 'unset')};
  min-width: 100%;

  height: fit-content;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors?.gray[200]};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  // Global layout footer is absolutely positioned relative to this panel.
  position: relative;
`;

// eslint-disable-next-line react/prop-types
export const PanelContainerFacade = ({ children }) => {
  return <PanelContainerFullWidth>{children}</PanelContainerFullWidth>;
};
