import AccordionItem from 'common/components/Accordions/AccordionItem';
import AccordionButton from 'common/components/Accordions/AccordionButton';
import Box from 'common/components/Box';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledAccordionItem = styled(AccordionItem)`
  overflow: hidden auto !important;
  max-height: 600px !important;
  border: none;
`;

export const StyledCategoryGroupButton = styled(AccordionButton)`
  height: 72px;
  padding: 24px;
  border: none;
  margin-right: 2rem;
  background-color: ${(props) => props.theme.colors?.white};
  border-bottom: 1px solid gray;
  border-bottom: 1px solid ${(props) => props.theme.colors?.gray[200]};
  background-color: ${(props) =>
    props.selected ? props.theme.colors?.blue[50] : props.theme.colors?.white};
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors?.blue[100]};
  }
`;

export const StyledAccordionPanelItems = styled(Box)`
  padding: 0;
`;

export const StyledCategoryText = styled(Box)`
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 40px;
  background-color: ${(props) => props.theme.colors?.gray[100]};
  border-bottom: 1px solid ${(props) => props.theme.colors?.gray[200]};
`;

export const StyledDocumentButton = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  padding-left: 40px;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? props.theme.colors?.blue[50] : props.theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme.colors?.gray[200]};
  &:hover {
    background-color: ${(props) => props.theme.colors?.blue[100]};
  }
  &:focus {
    box-shadow: none;
  }
`;

export const StyledAccordionIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})((props) => ({
  transformOrigin: 'center',
  transition: 'transform 0.2s',
  transform: props.isExpanded ? 'rotate(0deg)' : 'rotate(-180deg)',
  marginRight: '1.5rem',
}));
