import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  HStack,
  Text,
} from 'common/components';
import { formatCurrency } from 'common/util/format';
import RentalCalculationReviewBreakdown from 'pages/RentalIncome/RentalIncomeCalculation/model/RentalCalculationReviewBreakdown';
import FormulaBreakdown from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/FormulaBreakdown';
import language from '../language.json';

const LeaseAgreement = ({
  formulas,
  data,
  pita,
  isInCombinedCard,
  hideAccordion = false,
}) => {
  const theme = useTheme();

  if (!data) {
    return null;
  }

  const twelveMonthHistoryTip =
    data.inputFields?.find(
      (b) => b?.fieldName === 'DisplayRentalTwelveMonthHistoryTip',
    )?.fieldValue === 'True';

  return (
    <Box>
      {hideAccordion && (
        <Box pl="1rem">
          <FormulaBreakdown formulas={formulas} />
        </Box>
      )}
      {!hideAccordion && (
        <Accordion defaultIndex={[0]} allowMultiple mt={8}>
          <AccordionItem
            background={theme.colors?.gray[50]}
            border={`1px solid ${theme.colors?.gray[200]}`}
          >
            <AccordionButton>
              <Box
                flex="1"
                textAlign="left"
                as="h3"
                fontSize="1.125rem"
                fontWeight="500"
              >
                Lease Agreement Review Breakdown
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6} mt="2px" background={theme.colors?.white}>
              <FormulaBreakdown formulas={formulas} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      {twelveMonthHistoryTip && (
        <Alert status="info" mt={4}>
          {language.FNMA_GUIDELINES}
        </Alert>
      )}
      {!isInCombinedCard && (
        <Box mt={8} bg="#FAFAFA" padding={{ sm: '20px', md: '20px' }}>
          <Text fontSize="1rem" fontWeight="500">
            Lease Agreement:
          </Text>
          <HStack>
            <Text fontSize="0.875rem" fontWeight="500">
              Adjusted PITIA:
            </Text>
            <Text fontSize="0.875rem" fontWeight="400">
              {pita && `${formatCurrency(pita)} to `}
              {formatCurrency(data.getAdjustedPITIA())}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize="0.875rem" fontWeight="500">
              Positive Net Rental Income:
            </Text>
            <Text fontSize="0.875rem" fontWeight="400">
              {formatCurrency(data.getPositiveNetRentalIncome())}
            </Text>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default LeaseAgreement;

LeaseAgreement.propTypes = {
  data: PropTypes.instanceOf(RentalCalculationReviewBreakdown),
  pita: PropTypes.number,
  formulas: PropTypes.object,
  isInCombinedCard: PropTypes.bool,
  canRunLease: PropTypes.bool,
  ausMethod: PropTypes.number,
  hideAccordion: PropTypes.bool,
};
