import { useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

import Box from 'common/components/Box';
import Button from 'common/components/Button';
import {
  Table,
  Tbody,
  Tfoot,
  Thead,
  Tr,
  Th,
  Td,
} from 'common/components/Tables';
import { formatDateWithTime } from 'common/util/format';

const StyledTbody = styled(Tbody)`
  & tr td:first-of-type {
    border-left: 1px solid ${(props) => props.theme.colors?.gray[200]};
  }
  & tr td:last-of-type {
    border-right: 1px solid ${(props) => props.theme.colors?.gray[200]};
  }
`;

const StyledTfoot = styled(Tfoot)`
  & tr td {
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: ${(props) => props.theme.colors?.gray[200]};
  }
  & tr td:first-of-type {
    border-left: 1px solid ${(props) => props.theme.colors?.gray[200]};
  }
  & tr td:last-of-type {
    border-right: 1px solid ${(props) => props.theme.colors?.gray[200]};
  }
  padding: 8px;
`;

const HeaderTr = styled(Tr)`
  background-color: ${(props) => props.theme.colors?.teal[100]};
  & th {
    color: ${(props) => props.theme.colors?.white};
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.colors?.gray[200]};
  }
  & th:first-of-type {
    border-top-left-radius: 8px;
  }
  & th:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const Badge = styled('span')`
  color: ${(props) => props.theme.colors?.white};
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
  line-height: 150%;
  background-color: ${(props) => props.theme.colors?.teal[100]};
`;

const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${(props) => props.theme.colors?.gray[600]};

  &:hover {
    color: ${(props) =>
      props.disabled
        ? props.theme.colors?.gray[300]
        : props.theme.colors?.teal[100]};
  }

  ${(props) => {
    if (props.disabled) {
      return `
        color: ${props.theme.colors?.gray[300]};
        cursor: not-allowed;
      `;
    }
  }}
`;

const RowTd = styled(Td)`
  word-break: break-word;
`;

export default function AuditTable({ data = {} }) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / pageSize);
  const paginatedEntries = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Table data-testid="audit-history--table">
      <Thead>
        <HeaderTr>
          <Th>Loan Action</Th>
          <Th>Response</Th>
          <Th>User</Th>
          <Th>Date/Time</Th>
          <Th>History</Th>
        </HeaderTr>
      </Thead>
      <StyledTbody>
        {paginatedEntries.map(({ key, value }) => {
          return <Row key={key} loanAction={key} auditData={value} />;
        })}
      </StyledTbody>
      {totalPages > 1 && (
        <StyledTfoot>
          <Tr>
            <Td colSpan="5">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  maxWidth: '350px',
                  margin: '0 auto',
                }}
              >
                <IconButton
                  icon={faChevronLeft}
                  disabled={currentPage === 1}
                  onClick={() =>
                    currentPage !== 1 && handlePageChange(currentPage - 1)
                  }
                />

                <Badge>{currentPage}</Badge>

                <IconButton
                  icon={faChevronRight}
                  disabled={currentPage === totalPages}
                  onClick={() =>
                    currentPage !== totalPages &&
                    handlePageChange(currentPage + 1)
                  }
                />
              </Box>
            </Td>
          </Tr>
        </StyledTfoot>
      )}
    </Table>
  );
}
AuditTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string,
          modifiedDateTime: PropTypes.string,
          source: PropTypes.string,
          userId: PropTypes.number,
        }),
      ),
    }),
  ),
};

const GhostButton = styled(Button)`
  border: none;
  display: block;
  &:hover {
    background: none;
  }
`;
export function Row({ auditData = [], loanAction = '' }) {
  const theme = useTheme();
  const [showHistory, setShowHistory] = useState(false);
  const handleClick = () => {
    setShowHistory(!showHistory);
  };
  const [lastEntry, ...restEntries] = auditData;
  const { fieldValue, userEmail, modifiedDateTime, userName } = lastEntry;
  const isShowHistoryActive = !!restEntries.length;

  return (
    <>
      <Tr
        data-testid={loanAction}
        sx={{
          borderLeft: '1px solid',
          _hover: { background: theme.colors?.bgGray[300] },
        }}
      >
        <RowTd>
          <strong>{loanAction}</strong>
        </RowTd>
        <RowTd>
          {Array.isArray(fieldValue)
            ? fieldValue.map((item) => <div key={item.key}>{item}</div>)
            : fieldValue}
        </RowTd>
        <RowTd>
          <b>{userName ?? 'Username Not Available'}</b>
          <br />
          {userEmail}
        </RowTd>
        <RowTd>{formatDateWithTime(modifiedDateTime)}</RowTd>
        <RowTd>
          {isShowHistoryActive && (
            <GhostButton isPrimary={false} onClick={handleClick}>
              {showHistory ? 'Hide' : 'Show'} History <br />
              <FontAwesomeIcon
                icon={showHistory ? faChevronUp : faChevronDown}
              />
            </GhostButton>
          )}
        </RowTd>
      </Tr>
      {showHistory &&
        !!restEntries.length &&
        restEntries.map((entry, i) => {
          return <HistoryRow key={loanAction + '-index-' + i} {...entry} />;
        })}
    </>
  );
}
Row.propTypes = {
  loanAction: PropTypes.string,
  auditData: PropTypes.array,
};

function HistoryRow({ fieldValue, userName, userEmail, modifiedDateTime }) {
  return (
    <Tr data-testid="HistoryRow">
      <Td>&nbsp;</Td>
      <Td>{fieldValue}</Td>
      <Td>
        <b>{userName ?? 'Username Not Available'}</b>
        <br />
        {userEmail}
      </Td>
      <Td>{formatDateWithTime(modifiedDateTime)}</Td>
      <Td>&nbsp;</Td>
    </Tr>
  );
}
HistoryRow.propTypes = {
  fieldValue: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  modifiedDateTime: PropTypes.string,
};
