import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'common/components/Icons/IconButton';
import Input from 'common/components/Inputs/Input';
import InputRightElement from 'common/components/Inputs/InputRightElement';
import InputGroup from 'common/components/Inputs/InputGroup';
import Text from 'common/componentMapper/Text';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Select from 'common/components/Selects/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import theme from 'theme';

function Toolbar({
  docName,
  zoom,
  scale,
  onScaleChange,
  minZoom,
  maxZoom,
  currentPageNumber,
  totalPages,
  setPageNumberChange,
  documentOptions,
}) {
  const ref = useRef();

  let zoomChangeTimer;
  const selectedDocumentId = useSelector(
    (state) => state?.pdfDocumentId?.documentId,
  );

  const topOption = { id: 0, label: 'View Document' };
  let documentOptionsList = [];
  if (documentOptions.length > 0) {
    documentOptionsList = [topOption, ...documentOptions];
  }

  const [selectedOption, setSelectedOption] = useState(
    documentOptionsList[0]?.id,
  );

  useEffect(() => {
    setSelectedOption(selectedDocumentId);
  }, [selectedDocumentId]);

  useEffect(() => {
    ref.current.value = zoom;
  }, [zoom]);

  function handleZoomChange(e) {
    let percent = e.target.value;
    clearTimeout(zoomChangeTimer);
    zoomChangeTimer = setTimeout(() => {
      onScaleChange(percent / 100);
    }, 500);
  }

  function handleZoomIn() {
    onScaleChange(scale + 0.1);
  }

  function handleZoomOut() {
    onScaleChange(scale - 0.1);
  }

  function handlePDFOnFocus(e) {
    const documentId = [
      {
        documentId: Number(e.target.value),
      },
    ];
    window.postMessage({ type: 'pdfCitation', payload: documentId });
  }
  return (
    <Box
      style={{
        width: '100%',
        background: theme.colors?.gray[700],
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      <Flex style={{ alignItems: 'center', width: '100%' }}>
        {documentOptionsList && documentOptionsList.length > 0 && (
          <Box
            sx={{
              pl: '10px',
              bg: theme.colors?.gray[700],
            }}
          >
            <Select
              value={selectedOption}
              color="white"
              iconColor="white"
              width={'100%'}
              minWidth={'180px'}
              style={{ backgroundColor: 'black', color: 'white' }}
              onChange={(e) => {
                handlePDFOnFocus(e);
              }}
            >
              {documentOptionsList.map(({ id, label }, index) => (
                <option
                  style={{ backgroundColor: 'black', color: 'white' }}
                  key={index}
                  value={id}
                >
                  {label}
                </option>
              ))}
            </Select>
          </Box>
        )}
        {docName && (
          <Text
            sx={{
              color: 'white',
              whiteSpace: 'nowrap',
              p: 1,
              ml: 1,
              width: '40%',
            }}
          >
            {docName}
          </Text>
        )}
      </Flex>
      <Flex justify={'center'} width={'100%'}>
        {/* Pagination Controls */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => setPageNumberChange(currentPageNumber - 1)}
            tabIndex={-1}
            colorScheme="transparent"
            disabled={currentPageNumber <= 1}
          >
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'white' }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text sx={{ color: 'white', p: 1 }}>
              {currentPageNumber}/{totalPages}
            </Text>
          </Box>
          <IconButton
            onClick={() => setPageNumberChange(currentPageNumber + 1)}
            tabIndex={-1}
            colorScheme="transparent"
            outlineColor={'none'}
            disabled={currentPageNumber === totalPages}
          >
            <FontAwesomeIcon icon={faChevronRight} style={{ color: 'white' }} />
          </IconButton>
        </Box>
        {/* Zoom controls */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={handleZoomOut}
            tabIndex={-1}
            colorScheme="transparent"
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlassMinus}
              style={{ color: 'white' }}
            />
          </IconButton>
          <Box sx={{ position: 'relative', margin: '1rem 0.5rem' }}>
            <InputGroup>
              <Flex align={'center'}>
                <Input
                  ref={ref}
                  min={minZoom}
                  max={maxZoom}
                  onChange={handleZoomChange}
                  type="number"
                  style={{
                    width: '5rem',
                    background: theme.colors?.gray[700],
                    color: 'white',
                  }}
                  tabIndex={-1}
                />
                <InputRightElement style={{ color: 'white' }}>
                  %
                </InputRightElement>
              </Flex>
            </InputGroup>
          </Box>
          <IconButton
            onClick={handleZoomIn}
            tabIndex={-1}
            colorScheme="transparent"
            outlineColor={'none'}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlassPlus}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Box>
      </Flex>
    </Box>
  );
}

export default Toolbar;

Toolbar.propTypes = {
  sx: PropTypes.any,
  docName: PropTypes.any,
  zoom: PropTypes.any,
  scale: PropTypes.any,
  onScaleChange: PropTypes.any,
  minZoom: PropTypes.any,
  maxZoom: PropTypes.any,
  currentPageNumber: PropTypes.any,
  totalPages: PropTypes.any,
  setPageNumberChange: PropTypes.func,
  documentOptions: PropTypes.any,
};
