import { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from 'common/componentMapper/FormControl';
import MultiSelectComponent from 'common/components/Selects/MultiSelect/MultiSelect';
// eslint-disable-next-line import/no-unresolved
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { FormElementWidgetWithOptions } from 'lib-node-amb-renderer';
import { validationError } from '../utils';

export class MultiSelectWidget extends FormElementWidgetWithOptions {
  options;
  isMulti;
  constructor(props) {
    super(props);
    this.options = props.options;
    this.isMulti = props.isMulti;
  }
}

export default function MultiSelect(props) {
  const {
    options,
    input,
    placeholder,
    fcStyle,
    isMulti,
    meta,
    validateOnMount,
    onChange,
    onBlur,
    onFocus,
  } = useFieldApi({
    ...props,
  });

  let isMultiSet = isMulti === 'true';

  const invalid = validationError(meta, validateOnMount);
  // eslint-disable-next-line react/prop-types
  let selectName = props.name;
  const selectLabel = props.label;
  const isLabelHidden = props.isLabelHidden;
  const errorMessage = props?.validate[0]?.message;
  const [values, setValues] = useState(getValuesLabel(input.value));

  // helpers
  function isNotApplicable(value) {
    return [
      'IncomeId:0,BorrowerRecordId:0,BorrowerLenderDatabaseId:0',
      'AddressId:0,AddressLdId:0,IsAddressSubjectProperty:False',
      'AssetId:0,AssetLdId:0',
    ].includes(value);
  }

  function isNotListedAddToSystem(value) {
    return ['AssetId:-100,AssetLdId:-100'].includes(value);
  }

  function getValuesLabel(value) {
    let theLabelValue = [];

    if (value) {
      theLabelValue = options.reduce((acc, item) => {
        if (value.includes(item.value)) {
          acc.push({
            label: item.label,
            value: item.value,
          });
        }
        return acc;
      }, []);
    }
    return theLabelValue;
  }
  function sendValuesNoLabels(options) {
    let theValues = [];

    if (options) {
      theValues = options.reduce((acc, item) => {
        acc.push(item.value);
        return acc;
      }, []);
    }
    return theValues;
  }

  const handleChange = (e, value) => {
    const selectedOptions = value;
    const currentSelection = selectedOptions[selectedOptions.length - 1];
    let selectDisplayArray = [];
    let selectSaveArray = [];

    if (!isMultiSet) {
      // handle change as a single select
      selectSaveArray.push(selectedOptions.value);
      selectDisplayArray.push(selectedOptions);
      setValues(selectDisplayArray);
      input.onChange(selectSaveArray);
      return;
    }

    // if NA selected, clear remaining selections
    if (isNotApplicable(currentSelection?.value)) {
      setValues([{ label: 'Not Applicable', value: currentSelection.value }]);
      input.onChange([currentSelection.value]);
    } else if (isNotListedAddToSystem(currentSelection?.value)) {
      setValues([
        {
          label: 'Not Listed - Add to system',
          value: currentSelection.value,
        },
      ]);
      input.onChange([currentSelection.value]);

      // if any option other than NA is selected, clear NA from list
    } else {
      const remaining = selectedOptions?.filter(
        (option) =>
          !isNotApplicable(option.value) &&
          !isNotListedAddToSystem(option.value),
      );

      const sV = remaining.length ? sendValuesNoLabels(remaining) : [];
      remaining.length ? setValues(remaining) : setValues([]);
      input.onChange(sV);
    }

    onChange?.(currentSelection);
  };

  return (
    <FormControl sx={{ ...fcStyle }} isInvalid={!!invalid}>
      <MultiSelectComponent
        multiple={isMultiSet}
        id={selectName}
        options={options}
        value={values}
        isLabelHidden={isLabelHidden}
        placeholder={placeholder}
        data-tracking={selectName}
        data-testid={selectName}
        label={selectLabel}
        onChange={(e, value) => {
          handleChange(e, value);
        }}
        onBlur={(e) => {
          input.onBlur(e);
          onBlur?.(e);
        }}
        onFocus={(e) => onFocus?.(e)}
        isOptionEqualToValue={(option, value) => {
          if (isMultiSet) {
            return option?.value === value?.value;
          } else {
            return (
              option?.value ===
              (Array.isArray(value) ? value[0]?.value : value?.value)
            );
          }
        }}
        errors={!!invalid}
        helperText={errorMessage}
        disableClearable
        filterSelectedOptions
      />
    </FormControl>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.string,
  label: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  validate: PropTypes.array,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
