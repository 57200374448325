import typography from './typography';
import palette from './palette';

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      'html, body, #root': {
        height: '100%',
        backgroundColor: palette.bgGray[200],
        color: palette.gray[900],
        fontFamily:
          '"Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      },
      a: {
        color: palette.blue[600],
      },
      h1: typography.h1,
      h2: typography.h2,
      h3: typography.h3,
      h4: typography.h4,
      h5: typography.h5,
      h6: typography.h6,
      legend: {
        fontSize: typography.h6.fontSize,
        fontWeight: typography.h6.fontWeight,
        paddingBottom: '0.5rem',
      },
      '*::-webkit-scrollbar': {
        backgroundColor: palette.white,
        width: '14px',
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: palette.white,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '4px solid #fff',
      },
      '*::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        borderRadius: '1px',
        borderColor: palette.gray[700],
        '&.Mui-checked': {
          color: palette.blue[600],
          backgroundColor: palette.blue[600],
          borderColor: palette.blue[600],
          borderRadius: '1px',
          '&.Mui-disabled': {
            backgroundColor: palette.blue[600],
            borderColor: palette.blue[600],
            color: palette.white,
            opacity: '0.3',
            borderRadius: '1px',
          },
        },
        '&.Mui-disabled': {
          opacity: '0.3',
          borderColor: palette.gray[700],
          borderRadius: '1px',
        },
      },
    },
    defaultProps: {
      size: 'medium',
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        borderColor: palette.gray[700],
        '&.Mui-checked': {
          '&.Mui-disabled': {
            backgroundColor: palette.blue[600],
            borderColor: palette.blue[600],
            color: 'white',
          },
        },
        '&.Mui-disabled': {
          borderColor: palette.gray[700],
          opacity: '0.3',
        },
      },
    },
    defaultProps: {
      size: 'medium',
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        borderRadius: '0.375rem',
        '&.Mui-error': {
          borderColor: palette.red[600],
        },
        '&:focus': {
          borderColor: palette.blue[600],
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '0.375rem',
        '&.Mui-error': {
          borderColor: palette.red[500],
        },
        '&:hover': {
          borderColor: palette.blue[600],
        },
        '&:focus': {
          borderColor: palette.blue[600],
        },
      },
    },
  },
  MuiTextarea: {
    styleOverrides: {
      root: {
        borderColor: palette.gray[300],
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: false,
      disableGutters: true,
    },
  },
  MuiTable: {
    variants: [
      {
        props: { variant: 'striped' },
        style: {
          '& tbody tr:nth-of-type(odd)': {
            backgroundColor: palette.gray[100],
          },
        },
      },
    ],
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
};

export default components;
