import React from 'react';
import { useTheme } from '@chakra-ui/react';
import { Input as ChakraInput } from '@chakra-ui/react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const Input = React.forwardRef(({ ariaLabel, ...props }, ref) => {
  const { colors } = useTheme();
  return (
    <ChakraInput
      aria-label={ariaLabel}
      ref={ref}
      {...props}
      css={css`
        &:read-only {
          background-color: ${colors?.gray[100]};
          cursor: not-allowed;
        }
      `}
    />
  );
});

Input.displayName = 'Input';

export default Input;

Input.propTypes = {
  props: PropTypes.object,
  ariaLabel: PropTypes.string,
};
