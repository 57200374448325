const genericKeys = {
  all: ['sdui'],
  withAlert: (selectedAlert) => [
    selectedAlert.ruleCategoryId,
    selectedAlert.ruleSubCategoryId,
    selectedAlert.ruleId,
    selectedAlert.gutterItemId,
  ],
  layouts: () => [...genericKeys.all, 'layout'],
  layout: (selectedAlert) => [
    ...genericKeys.layouts(),
    ...genericKeys.withAlert(selectedAlert),
  ],
  datas: () => [...genericKeys.all, 'data'],
  data: (selectedAlert) => [
    ...genericKeys.datas(),
    ...genericKeys.withAlert(selectedAlert),
  ],
};

export default genericKeys;
