﻿const FormTypes = {
  Form1040: 1,
  Form1065: 2,
  Form1099Div: 3,
  Form1099Misc: 4,
  Form1099R: 5,
  Form1099Ssa: 6,
  Form1120: 7,
  Form1120S: 8,
  Form2106: 9,
  MonthlyAmount: 10,
  PayStub: 11,
  ScheduleA: 12,
  ScheduleC: 13,
  ScheduleF: 15,
  ScheduleK1: 16,
  W2: 17,
  Wvoe: 18,
  PensionAwardLetter: 19,
  Form1040Output: 28,
  Form1099SSAOutput: 29,
  SocialSecurityAwardLetterOutput: 30,
  Form1099ROutput: 31,
  PensionAwardLetterOutput: 32,
  BankStatementOutput: 33,
  Form1120SOutput: 38,
  IraDistributions401KAwardLetter: 39,
  IraDistributions401KAwardLetterOutput: 40,
  ScheduleK1Form1065: 44,
  Form1065Output: 45,
  ScheduleG: 46,
  Form1120Output: 47,
  NoCalcForm: 62,
};
Object.freeze(FormTypes);

const ConditionTypes = {
  FullTwoYearBaseEmaploymentHistory: 17007,
  AllW2sFromPreviousYear: 17008,
  AllW2FromPreviousTwoYears: 17009,
  LPASelfEmploymentOneYear: 17010,
  DUSelfEmploymentOneYear: 17011,
  LPASelfEmploymentTwoYear: 17012,
  DUSelfEmploymentTwoYear: 17013,
  SelfEmploymentLessThanTwoYears: 17014,
  VariableIncomeLessThanTwoYears: 17015,
  EmployedByFamilyLPA: 17016,
  VerificationOfBusinessVOB: 17017,
  SelfEmploymentLossReportingOnScheduleE: 17018,
  SelfEmployedLessThan12Months: 17019,
  EmployedByFamilyDU: 17020,
  DUVerificationOfBusinessVOB: 17021,
  PreviousEmploymentVariablePayQuestion: 17022,
};
Object.freeze(ConditionTypes);

const FormIsSigned = [111, 112, 113];

export const Employment = 'Employment';

const CalculationTypes = {
  /** SSI gross up from Form 1040 */
  SocialSecurity1040: 1,
  /** SSI gross up from Form 1099 */
  SocialSecurity1099: 2,
  /** Social security award letter */
  SocialSecurityAwardLetter: 5,
  /** Form 1040 pension income */
  Pension1040: 6,
  /** Form 1099 pension income **/
  Pension1099: 7,
  /** Pension income from an award letter */
  PensionAwardLetter: 8,
  /** InterestDividends form 1040 */
  InterestDividends: 9,
  /** ChildSupportGrossUp from Child Support Papers */
  ChildSupportGrossUp: 12,
  /** Divorce Decree Gross Up from Divorce Decree */
  DivorceDecreeGrossUp: 37,
  /** ScheduleC */
  ScheduleC: 20,
  /** Schedule E Form 1065 */
  Partnership: 21,
  /** Schedule E Form 1120S*/
  SoleProprietorship: 22,
  /** Form 1120 */
  Corporation: 23,
  /** PayStub/W2 */
  PayStub: 24,
  /** Verification of employment */
  Wvoe: 25,
  /** BankStatement */
  SocialSecurityBankStatement: 29,
  /** BankStatement */
  PensionBankStatement: 30,
  /** Form Schedule E-1040 */
  Rental1040: 31,
  /** Lease Agreement */
  LeaseAgreement: 32,
  /** Form 1007/1025 */
  Rental1007_1025: 33,
  /** Rental Form 998 */
  Rental998: 42,
  /** IraDistributions form 1099 */
  IRADistributions1099: 13,
  /** Form 1040 */
  IRADistributions1040: 34,
  /** IRADistributions Bank Statement */
  IRADistributionsBankStatement: 35,
  /** IRADistributions Award Letter */
  IRADistributions401KAwardLetter: 36,
  /** Capital Gains 1040 */
  CapitalGains1040: 43,
  /** WVOE/VOI with Paystub */
  VoeWithPaystub: 45,
  NoCalcTypes: 46,
  QualifyingPaystub: 39,
  QualifyingVoe: 44,
  ChildSupportPapers: 52,
  YearEndPaystub: 50,
};
Object.freeze(CalculationTypes);

const CalculationOutcomes = {
  SeasonalIncome: 1,
  OvertimeIncome: 2,
  CommissionIncome: 3,
  BonusIncome: 4,
  TipIncome: 5,
  OtherIncome: 6,
  BaseIncome: 14,
};
Object.freeze(CalculationOutcomes);

const DeductionAction = {
  NoAction: 1,
  AddToLiabilities: 2,
  LoxCondition: 3,
};
Object.freeze(DeductionAction);

const ValidateMatchTable = {
  BorrowerName: 'BorrowerName',
  EmployerName: 'EmployerName',
  SSN: 'SSN',
  BorrowerAddress: 'BorrowerAddress',
};
Object.freeze(ValidateMatchTable);

const PaySchedule = {
  'Select salary type': 99,
  Hourly: 0,
  Weekly: 1,
  Biweekly: 2,
  'Semi-Monthly': 3,
  Monthly: 4,
  Annual: 5,
  Seasonal: 6,
  'Weekly Teacher 10 Months': 7,
  'Biweekly Teacher 10 Months': 8,
  'Semi-Monthly Teacher 10 Months': 9,
  'Monthly Teacher 10 Months': 10,
  'Variable Hourly': 11,
};
Object.freeze(PaySchedule);

const VariableIncomeTypes = {
  1: 'Base',
  2: 'Overtime',
  3: 'Bonus',
  4: 'Commission',
  5: 'Tip',
  6: 'Other',
  7: 'DividendsOrInterest',
};
Object.freeze(VariableIncomeTypes);

const INCOME_TYPES = [
  'Base',
  'Seasonal',
  'Overtime',
  'Bonus',
  'Commission',
  'Tip',
  'Other',
];
Object.freeze(INCOME_TYPES);

const TRENDS = [
  'Undefined',
  'Classic',
  'IncreasingTrend',
  'DecreasingTrend',
  'DecliningButStabilizingTrendAboveAverage',
  'DecliningButStabilizingTrendBelowAverage',
];
Object.freeze(TRENDS);

const FormulaType = {
  BiWeekly: 1,
  SemiMonthly: 2,
  Weekly: 3,
  Monthly: 4,
  MonthlyYTDAverage: 5,
  Hourly: 6,
  Annual: 7,
  WeeklyTeacher10Months: 11,
  BiWeeklyTeacher10Months: 12,
  SemiMonthlyTeacher10Months: 13,
  MonthlyTeacher10Months: 14,
  SSI1040MonthlyIncome: 16,
  Pension1040MonthlyIncome: 20,
  Pension1099MonthlyIncome: 21,
  IRADistributionsForm1040TwoYear: 53,
  IRADistributionsForm1040OneYear: 54,
  SSIAwardLetterMonthlyIncomeLineThree: 67,
  Pension1040MonthlyIncomeFHA: 80,
  SSI1040MonthlyIncomeFHA: 81,
  InterestForm1040OneYearFHA: 88,
  InterestForm1040YearOneFHA: 89,
  InterestForm1040YearTwoFHA: 90,
  DividendsInterestForm1040OneYearFHA: 91,
  DividendsInterestForm1040YearOneFHA: 92,
  DividendsInterestForm1040YearTwoFHA: 93,
  OneYearMonthly: 94,
  TwoYearMonthly: 95,
  SSIAwardLetterMonthlyIncomeLineThreeFHA: 96,
  SSI1099MonthlyIncomeLine3Conv: 99,
  SSI1099MonthlyIncomeLine3FHA: 100,
  IRADistributionsForm1040OneYearFHA: 102,
  IRADistributionsForm1040TwoYearFHA: 103,
  CapitalGainForm1040OneYear: 111,
  CapitalGainForm1040TwoYear: 112,
  CapitalGainForm1040ThreeYear: 113,
};
Object.freeze(FormulaType);

const RetirementIncomeTypes = {
  PensionAwardLetter: 0,
  PensionAward1099: 1,
  PensionAward1040: 2,
  PensionAwardBankStatement: 3,
  SocialSecurityAwardLetter: 4,
  SocialSecurity1099: 5,
  SocialSecurity1040: 6,
  SocialSecurityBankStatement: 7,
};
Object.freeze(RetirementIncomeTypes);

const DocumentTypes = {
  PayStub: '17865',
  W2: '17866',
  Wvoe: '17854',
  Voi: '18401',
  ScheduleC: '20720',
  Form1040: '18614',
  Form1099: '20732',
  SSI1099: '20723',
  PensionAwardLetter: '20744',
  IRAAwardLetter: '20747',
  Form1120S: '20722',
  Form1065: '20670',
  SocialSecurityAwardLetter: '18047',
  BankStatement: '18394',
  ScheduleK1Form1120s: '20711',
  ScheduleK1Form1065: '20677',
  ScheduleGForm1120: '20688',
  Form1120: '20691',
  ProfitAndLoss: '18854',
  BalanceSheet: '20018',
  DivorceDecree: '17905',
  DivorceChildSupport: '18888',
};
Object.freeze(DocumentTypes);

const IncomeValidationDocumentType = {
  Paystub: 1,
  IRSW2: 2,
  ScheduleC: 3,
  Form1040: 4,
  PensionAwardLetter: 5,
  IRS1099R: 6,
  BankStatement: 7,
  ScheduleK1Form1120S: 8,
  Form1120S: 9,
  Wvoe: 10,
  FormSSA1099: 11,
  SocialSecurityAwardLetter: 12,
  Form1120: 13,
  ScheduleGForm1120: 14,
  Form1065: 15,
  ScheduleK1Form1065: 16,
  IRAAwardLetter: 17,
  VOI: 18,
  ProfitAndLoss: 21,
  BalanceSheet: 22,
};
Object.freeze(IncomeValidationDocumentType);

const IncomeDocumentId = {
  FormSSA1099: 12,
  ScheduleK1Form1120S: 16,
  Form1040: 7,
  Form1120S: 14,
  Form1120: 18,
  ScheduleGForm1120: 20,
  Form1065: 22,
  ScheduleK1Form1065: 24,
  IRAAwardLetter: 26,
  Form1099: 29,
  WVOE: 4,
  VOI: 33,
  BankStatement: 13,
  PensionAwardLetter: 11,
  SocialSecurityAwardLetter: 12,
  BalanceSheet: 37,
  ProfitAndLoss: 36,
};
Object.freeze(IncomeDocumentId);

const DocumentNames = {
  20732: '1099-R',
  20723: '1099-SSA',
  17865: 'Paystub',
  17866: 'W2',
  18614: '1040',
  20720: 'Schedule C',
  20722: '1120S',
  20711: 'Schedule K-1',
  20670: '1065',
  20677: 'K1',
  20691: '1120',
  20688: 'Schedule G',
  18401: 'VOI',
  17854: 'WVOE',
  20018: 'Balance Sheet',
  18854: 'Profit & Loss',
  18047: 'Social Security Award Letter',
  20744: 'Pension Award Letter',
  20747: 'IRA/401K Award Letter',
  18394: 'Bank Statement',
};
Object.freeze(DocumentNames);

const QuestionId = {
  voeStartDate: 1000,
  voeStartDateProceed: 1001,
  voiStartDate: 1002,
  voiStardDateProceed: 1003,
  ssnMismatch: 17001,
  ssn: 17000,
  shouldSsnProceedWithEase: 9419,
};
Object.freeze(QuestionId);

const IncomeRuleIds = {
  IncomeSummary: 1,
  ValidateDocument: 2,
  CalculateIncome: 3,
  ReviewBreakdown: 4,
  IncomeResolution: 8,
  PreviousEmploymentIncomeResolution: 9,
  YmlReviewBreakdown: 10,
  NoDocumentationProvidedIncomeSummary: 11,
  SmartValidateDocument: 12,
  SmartCalculateIncome: 13,
};
Object.freeze(IncomeRuleIds);

const ValidateFields = {
  TaxYear: 1,
  TaxYearAnswer: 4,
  DateIncorporated: 6,
  DateIncorporatedAnswer: 7,
  EmployerIdentificationNumber: 20,
  EinIncompleteOrMissing: 114,
  EmployerAddress: 15,
  EmployerCity: 16,
  EmployerState: 18,
  EmployerZipCode: 19,
  PensionBankStaementDate: 66,
  BankStatementDate: 96,
  ScheduleGProvided: 98,
  ProfitAndLossDate: 101,
  ProfitAndLossCheckBox: 103,
  BalanceSheetDate: 102,
  Form1040IsSigned: 110,
  Form1065IsSigned: 111,
  Form1120IsSigned: 112,
  Form1120SIsSigned: 113,
};
Object.freeze(ValidateFields);

const ValidateFieldNames = {
  EMPLOYER_NAME: 'EMPLOYER_NAME',
  BORROWER_ADDRESS: 'BORROWER_ADDRESS',
  EMPLOYER_ADDRESS: 'EMPLOYER_ADDRESS',
  EMPLOYER_CITY: 'EMPLOYER_CITY',
  EMPLOYER_STATE: 'EMPLOYER_STATE',
  EMPLOYER_ZIP_CODE: 'EMPLOYER_ZIP_CODE',
  TAX_YEAR: 'TAX_YEAR',
  ADDRESS: 'ADDRESS',
  CITY: 'CITY',
  STATE: 'STATE',
  ZIP_CODE: 'ZIP_CODE',
  SOCIAL_SECURITY_NUMBER: 'SOCIAL_SECURITY_NUMBER',
  EMPLOYER_IDENTIFICATION_NUMBER: 'EMPLOYER_IDENTIFICATION_NUMBER',
  DATE_INCORPORATED: 'DATE_INCORPORATED',
  DATE_STARTED: 'DATE_BUSINESS_STARTED',
  RECIPIENTS_TIN: 'RECIPIENTS_TIN',
  YEAR_OF_FORM: 'YEAR_OF_FORM',
  YEAR: 'YEAR',
  PAYMENT_DATE: 'PAYMENT_DATE',
  BEGINING_DATE_OF_BENEFITS: 'BEGINING_DATE_OF_BENEFITS',
  WVOE: {
    START_DATE: 'dateOfEmployment',
    GROSS_EARNINGS_YTD_THROUGH_DATE: 'grossEarningsYtdThroughDate',
    CURRENT_GROSS_BASE_PAY: 'currentGrossBasePay',
  },
  CPA_FIRST_NAME: 'CPA_FIRST_NAME',
  MIDDLE_NAME: 'CPA_MIDDLE_NAME',
  LAST_NAME: 'CPA_LAST_NAME',
  COMPANY_NAME: 'FIRM_NAME',
  PREPARER_NAME: 'PREPARER_NAME',
  FIRM_NAME: 'FIRM_NAME',
};
Object.freeze(ValidateFieldNames);

const REQUEST_REFERENCE_TYPE = {
  employmentIncome: 1,
  rentalIncome: 2,
  calculation: 3,
};
Object.freeze(REQUEST_REFERENCE_TYPE);

const INCOME_RESOLUTION_QUESTION_TYPES = {
  RADIO: 2,
  REASON: 4,
};
Object.freeze(INCOME_RESOLUTION_QUESTION_TYPES);
const INCOME_RESOLUTION_REASON_TYPES = {
  RAISE: '1',
  SEASONAL: '2',
  LEAVE: '3',
  UNSURE: '4',
  OTHER: '5',
  RAISED_HOURS: '6',
};
Object.freeze(INCOME_RESOLUTION_REASON_TYPES);
const NetProceeds = 'Net Proceeds';

const RuleIdCpa = {
  Form1120S: 17033,
  Form1065: 17031,
  Form1120: 17032,
};
Object.freeze(RuleIdCpa);
export {
  FormTypes,
  CalculationTypes,
  CalculationOutcomes,
  PaySchedule,
  VariableIncomeTypes,
  INCOME_TYPES,
  TRENDS,
  FormulaType,
  RetirementIncomeTypes,
  DocumentTypes,
  IncomeValidationDocumentType,
  IncomeDocumentId,
  ValidateFields,
  ValidateFieldNames,
  DocumentNames,
  IncomeRuleIds,
  REQUEST_REFERENCE_TYPE,
  QuestionId,
  ConditionTypes,
  INCOME_RESOLUTION_QUESTION_TYPES,
  INCOME_RESOLUTION_REASON_TYPES,
  DeductionAction,
  RuleIdCpa,
  ValidateMatchTable,
  FormIsSigned,
  NetProceeds,
};
