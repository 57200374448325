import { typedApiClient } from 'common/util/api-client';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export const useGetLoanSummaryData = () => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );

  return useQuery({
    queryKey: ['getLoanSummaryData'],
    queryFn: async () => {
      const { data } = await typedApiClient.v2.loanGetLoanSummary(
        obfuscatedLoanIdentifier,
      );

      return data;
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export default useGetLoanSummaryData;
